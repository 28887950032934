.t-margin-top-25 {
	margin-top: 25px !important;
}
.t-margin-top-20 {
	margin-top: 20px !important;
}
.t-margin-top-10 {
	margin-top: 10px !important;
}
.t-margin-top-22-large {
	@media (--lg) {
		margin-top: 22px !important;
	}
}

.t-margin-right-40 {
	margin-right: 40px !important;
}
.t-margin-right-30 {
	margin-right: 30px !important;
}
.t-margin-right-25 {
	margin-right: 25px !important;
}
.t-margin-right-20 {
	margin-right: 20px !important;
}
.t-margin-right-15 {
	margin-right: 15px !important;
}
.t-margin-right-10 {
	margin-right: 10px !important;
}
.t-margin-right-2 {
	margin-right: 2px !important;
}
.t-margin-right-auto {
	margin-right: auto !important;
}

.t-margin-bottom-100 {
	margin-bottom: 100px !important;
}
.t-margin-bottom-80 {
	margin-bottom: 80px !important;
}
.t-margin-bottom-60 {
	margin-bottom: 60px !important;
}
.t-margin-bottom-50 {
	margin-bottom: 50px !important;
}
.t-margin-bottom-40 {
	margin-bottom: 40px !important;
}
.t-margin-bottom-30 {
	margin-bottom: 30px !important;
}
.t-margin-bottom-25 {
	margin-bottom: 25px !important;
}
.t-margin-bottom-20 {
	margin-bottom: 20px !important;
}
.t-margin-bottom-15 {
	margin-bottom: 15px !important;
}
.t-margin-bottom-10 {
	margin-bottom: 10px !important;
}

.t-margin-left-10 {
	margin-left: 10px !important;
}
.t-margin-left-0 {
	margin-left: 0px !important;
}
.t-margin-left-auto {
	margin-left: auto !important;
}

.t-margin-0 {
	margin: 0 !important;
}

.t-margin-top-vertical-space {
	margin: calc(var(--spacing--vertical-spacing-unit) * 0.5) 0;

	@media (--medium) {
		margin: calc(var(--spacing--vertical-spacing-unit-mobile) * 0.5) 0;
	}
}

.t-margin-bottom-vertical-space {
	margin: calc(var(--spacing--vertical-spacing-unit) * 0.5) 0;

	@media (--medium) {
		margin: calc(var(--spacing--vertical-spacing-unit-mobile) * 0.5) 0;
	}
}

@media screen and (min-width:700px) {
	.t-margin-bottom-0\@md {
		margin-bottom: 0px !important;
	}

	.t-margin-left-10\@md {
		margin-left: 10px !important;
	}
}

@media screen and (min-width:900px) {
	.t-margin-right-30\@lg {
		margin-right: 30px !important;
	}

	.t-margin-bottom-0\@lg {
		margin-bottom: 0px !important;
	}

	.t-margin-left-10\@lg {
		margin-left: 10px !important;
	}
	.t-margin-left-0\@lg {
		margin-left: 0px !important;
	}
}