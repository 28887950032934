.c-text-field {
	text-align: center;
	padding: 80px 0 45px;
	background: #fff;
}

.c-text-field__headline {
	@mixin text-style-epsilon;
	margin-bottom: 13px;
}

.c-text-field__headline--secondary {
	@mixin text-style-nu;
	color: var(--color-epsilon);
	margin-bottom: 7px;
	display: block;
}

.c-text-field__heading {
	@mixin text-style-eta;
	margin-bottom: 18px;
}

.c-text-field__paragraph {
	font-size: 16px;
}

@media print {
	.c-text-field {
		text-align:left;
		padding-top:1cm;
		padding-bottom:1cm;
	}
}
