/* =============================================================================
 * Message
 *
 * [1] Remove outline when programmatically focused.
============================================================================= */
.c-message {
	display:flex;
	align-items:flex-start;
	margin-bottom:20px;
}
.c-message:focus {
	outline:none; /* [1] */
}

/*
 * Reversed version
 */
.c-message--reversed {
	justify-content:flex-end;
}

/*
 * Animation used when removing a message
 */
.c-message--disappear {
	animation:c-message--disappear 1s ease-in-out;
}

@keyframes c-message--disappear {
	0% {
		margin-bottom:20px;
		opacity:1;
	}
	50% {
		opacity:0;
	}
	100% {
		height:0;
		margin-bottom:0;
		opacity:0;
	}
}

/*
 * Animation used when inserting a message
 */
.c-message--appear {
	animation:c-message--appear 1s ease-in-out;
}

@keyframes c-message--appear {
	0% {
		margin-bottom:0;
		opacity:0;
	}
	100% {
		margin-bottom:20px;
		opacity:1;
	}
}


/* Portrait
----------------------------------------------------------------------------- */
.c-message__portrait {
	position:relative;
}
.c-message__portrait-image {
	width:50px;
	border-radius:50%;
}
.c-message__portrait-badge {
	position:absolute;
	bottom:-5px;
	right:-5px;
	box-shadow:0 0 0 2px #fff;
}

@media(--small) {
	.c-message__portrait {
		display:none;
	}
}

/*
 * Compact version
 */
.c-message--compact .c-message__portrait {
	display:none;
}


/* Message header
----------------------------------------------------------------------------- */
.c-message__header {
	display:flex;
	align-items:flex-start;
	margin-bottom:10px;
}
.c-message__meta {
	margin-right:20px;
}
.c-message__author-link,
.c-message__author-link:visited {
	color:#000;
	border-bottom:1px solid rgba(0, 0, 0, 0.3);
	line-height:1.2;
	transition:border 0.2s ease;
}
.c-message__author-link:hover,
.c-message__author-link:focus {
	border-bottom-color:#000;
}
.c-message__date {
	margin-left:0.4em;
}
.c-message__date::before {
	display:inline-block;
	width:1px;
	height:1em;
	margin-right:0.5em;
	background:currentColor;
	content:"";
	vertical-align:middle;
}
.c-message__action {
	margin-left:auto;
	color:#707070;
	border-bottom:1px solid rgba(112, 112, 112, 0.3);
	line-height:1;
	margin-top:4px;
}
.c-message__action:hover,
.c-message__action:focus {
	color:#000;
	border-bottom-color:#000;
}


/* Message content
----------------------------------------------------------------------------- */
.c-message__content {
	position:relative;
	max-width:calc(100% - 200px);
	margin-left:30px;
	padding:20px;
	background:#f4f4f4;
}

@media only screen and (max-width:1010px) {
	.c-message__content {
		max-width:none;
	}
}

/*
 * Reversed version
 */
.c-message--reversed .c-message__content {
	margin-right:30px;
	margin-left:0;
}

@media(--small) {
	.c-message__content {
		padding:10px;
		margin-left:0;
		margin-right:20px;
	}
	.c-message--reversed .c-message__content {
		margin-right:0;
		margin-left:20px;
	}
}

/*
 * Compact version
 */
.c-message--compact .c-message__content {
	max-width:none;
}
.c-message--compact .c-message__content {
	padding:10px;
	margin-left:0;
	margin-right:20px;
}
.c-message--compact.c-message--reversed .c-message__content {
	margin-right:0;
	margin-left:20px;
}


/* Speech bubble arrow for the message content.
 * Normally displayed on the left side.
---------------------------------------------------------------------------- */
.c-message__content::before {
	display:block;
	position:absolute;
	top:19px;
	left:-19px;
	width:0;
	border-width:20px 0 0 20px;
	border-style:solid;
	border-color:#f4f4f4 transparent;
	content:"";
}

@media(--small) {
	.c-message__content::before {
		display:none;
	}
}

/*
 * Compact version
 */
.c-message--compact .c-message__content::before {
	display:none;
}


/*
 * Move the arrow right in the reversed version
 */
.c-message--reversed .c-message__content::before {
	right:-19px;
	left:auto;
	border-width:20px 20px 0 0;
}


/* Message body
 * The actual comment text.
----------------------------------------------------------------------------- */
.c-message__body > *:last-child {
	margin-bottom:0;
}
