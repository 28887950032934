/* =============================================================================
 * Footer
============================================================================= */
.c-footer {
	background-color:var(--color-gamma);
	color:#fff;
	padding:20px 0;
}
.c-footer--margin {
	margin-top:60px;
}
.c-footer__link,
.c-footer__link:visited {
	color:inherit;
}

@media(--small) {
	.c-footer .c-logo {
		align-items: flex-start;
	}
	.c-footer .c-logo__link {
		max-height: 65px;
	}
}

@media print {
	.c-footer {
		display:none;
	}
}
