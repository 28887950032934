/* =============================================================================
 * Variables and mixins - vanilla TODO: FIX VANILLA DEFAULTS
============================================================================= */
:root {
	/*
	 * Color related variables
	 */
	--color-alpha:#1F1F1F; /* offblack - body text */
	--color-beta:#000000; /* black - headlines */
	--color-gamma:#0068A6; /* blue - links */
	--color-delta: #FFCD00; /* yellow - accent */
	--color-epsilon: #E55E13; /* yellow - accent second */
	--color-zeta: #E3E3E3; /* gray - borders */
	--color-eta: #EEEEEE; /* gray - backgrounds */
	--color-theta: #C8C8C8; /* darker gray */
	--color-iota: #0D5083; /* dark blue */
	--color-kappa: #003B5D;
	--color-lambda: #000A32;

	--color-error: #ED6565; /* red:ish; */
	--color-error2: #FFF6F6; /* red:ish background; */

	--color-link: var(--color-gamma);
	--color-gray: var(--color-zeta);
	--color-accent: var(--color-delta);
	--color-gnit-examples: var(--color-eta);

	--color-approved: #1AAB13;
	--color-rejected: #D10000;
	--color-ongoing: var(--color-gamma);
	--color-underreview: #FF9A00;
	--color-overdue: var(--color-rejected);
	--color-waiting: var(--color-epsilon);

	/*
	 * New colors for vanilla theme. These are used in the progress doughnut at the moment but will later be applied to the whole theme.
	 */

	--new-color-forrest-green:#00745B;
	--new-color-blue:#0065B0;
	--new-color-grey:#D2D0CE;
	
	/*
	 * Type related variables
	 */
	--type-family-default: 'Open Sans', 'sans-serif';
	--type-size-default: 14px;
	--type-line-height-default: 29px;
	--type-defaults: 400 var(--type-size-default)/var(--type-line-height-default) var(--type-family-default);

	/*
	 * Spacing variables
	 */
	--spacing--horizontal-gutter: 30px;
	--spacing--vertical-spacing-unit: 50px;
	--spacing--vertical-spacing-unit-mobile: 30px;

	/*
	 * Width limits
	 */
	--width-limit-alpha: 1200px; /* site wrapper */
	--width-limit-beta: 1080px; /* text-fields */
	--width-limit-gamma: 929px;
	--width-limit-delta: 582px; /* main navigation */
	--width-limit-epsilon: 770px; /* body text on sub pages */
	--width-limit-zeta: 1380px; /* site wrapper large */
	--width-limit-eta: 998px; /* flexslider */
}

 /* Breakpoints
 ---------------------------------------------------------------------------- */
/* THESE ARE OLD GNIT VARIABLES USED IN OBJECTS, THEY SHOULD BE UPDATED TO THE ONES BELOW */
@custom-media --md (width >= 601px);
@custom-media --lg (width >= 1001px);
@custom-media --xl (width >= 1401px);

/* THESE ARE THE ONES I USE IN THIS PROJECT BECAUSE I'M GOING DESKTOP-FIRST */
@custom-media --large (width <= 1400px);
@custom-media --medium-plus (width <= 1280px);
@custom-media --medium (width <= 1000px);
@custom-media --small (width <= 600px);

/* Text styles
 ---------------------------------------------------------------------------- */
@define-mixin text-style-reset {
	/*background:transparent;*/
	font:var(--type-defaults);
	text-transform:none;
	line-height:var(--type-line-height-default);
	color:inherit;
	text-shadow:none;
	letter-spacing:normal;
	word-spacing:normal;
}

@define-mixin text-style-alpha {
	@mixin text-style-reset;
	/* preamble */
	font-family: 'Open Sans', 'sans-serif';
	font-size: 16px;
	line-height: 1.8; /* 29px */
}

@define-mixin text-style-beta {
	@mixin text-style-reset;
	/* nav links */
	font-family: 'Open Sans', 'sans-serif';
	font-weight: 600;
	font-size: 16px;
	line-height: 1.2; /* 26px */
}

@define-mixin text-style-gamma {
	@mixin text-style-reset;
	/* sub nav links */
	font-family: 'Open Sans', 'sans-serif';
	font-weight: 400;
	font-size: 14px;
	line-height: 1.16; /* 21px */
}

@define-mixin text-style-delta {
	@mixin text-style-reset;
	/* section heading */
	font-family: 'Open Sans', 'sans-serif';
	font-weight: 600;
	font-size: 50px;
	line-height: 1.1;
	text-transform: uppercase;

	@media(--medium) {
		font-size: 30px;
	}
}

@define-mixin text-style-epsilon {
	@mixin text-style-reset;
	/* rubriker nivå 1 */
	font-family: 'Open Sans', 'sans-serif';
	font-weight: 600;
	font-size: 30px;
	line-height: 1.3; /* 47px */
	text-transform: uppercase;

	@media(--medium) {
		font-size: 30px;
	}
}

@define-mixin text-style-zeta {
	@mixin text-style-reset;
	/* rubriker nivå 2 */
	font-family: 'Open Sans', 'sans-serif';
	font-weight: 600;
	font-size: 22px;
	line-height: 1.3;
	text-transform: uppercase;

	@media(--medium) {
		font-size: 26px;
	}
}

@define-mixin text-style-eta {
	@mixin text-style-reset;
	/* rubriker nivå 3 */
	font-family: 'Open Sans', 'sans-serif';
	font-weight: 600;
	font-size: 20px;
	line-height: 1.3;
	text-transform: uppercase;

	@media(--medium) {
		font-size: 22px;
	}
}

@define-mixin text-style-theta {
	@mixin text-style-reset;
	/* rubriker nivå 4 */
	font-family: 'Open Sans', 'sans-serif';
	font-weight: 600;
	font-size: 20px;
	line-height: 1.3;

	@media(--medium) {
		font-size: 22px;
	}
}

@define-mixin text-style-iota {
	@mixin text-style-reset;
	/* rubriker nivå 5 */
	font-family: 'Open Sans', 'sans-serif';
	font-size: 16px;
	line-height: 1.8;
}

@define-mixin text-style-kappa {
	@mixin text-style-reset;
	/* quotes */
	font-family: 'Open Sans', 'sans-serif';
	font-weight: 400;
	font-size: 22px;
	line-height: 1.4;
	text-transform: uppercase;
	font-style: italic;

	@media(--medium) {
		font-size: 18px;
	}
}

@define-mixin text-style-lambda {
	@mixin text-style-reset;
	/* labels */
	font-family: 'Open Sans', 'sans-serif';
	font-weight: 600;
	font-size: 14px;
	line-height: 1.1; /* 18px */
}

@define-mixin text-style-mu {
	@mixin text-style-reset;
	/* (oblogatoriskt): */
	font-family: 'Open Sans', 'sans-serif';
	font-weight: 300;
	font-style: italic;
	font-size: 14px;
	line-height: 1.3; /* 19px */
}

@define-mixin text-style-nu {
	@mixin text-style-reset;
	/* underrubrik */
	font-family: 'Open Sans', 'sans-serif';
	font-weight: 400;
	font-size: 13px;
	line-height: 1.4; /* 18px */
	text-transform: uppercase;
}

@define-mixin text-style-xi {
	/* buttons */
	@mixin text-style-reset;
	font-family: 'Open Sans', 'sans-serif';
	font-weight: 400;
	font-size: 16px;
	line-height: normal;
}

@define-mixin text-style-omicron {
	/* standard links */
	@mixin text-style-reset;
	font-family: 'Open Sans', 'sans-serif';
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3; /* 19px */
}

@define-mixin text-style-pi {
	/* global navigation */
	@mixin text-style-reset;
	font-family: 'Open Sans', 'sans-serif';
	font-weight: 600;
	font-size: 12px;
	line-height: 1; /* 12px */
	color: #1C3645;
}

/* Utility mixins
 ---------------------------------------------------------------------------- */
@define-mixin clearfix {
	content:"";
	display:table;
	clear:both;
}
