.c-island {
	background: #fff;
	border: 1px solid #dbdbdb;
	border-radius: 4px;
	padding: 20px 50px 70px;
}

.c-island--tight {
	padding-right:20px;
	padding-bottom:20px;
	padding-left:20px;
}

.c-island--loose {
	padding: 50px;
}

.c-island--baggy {
	padding: 30px;
}

.c-island--tight\@lg {
	padding-right: 20px;
	padding-bottom: 20px;
	padding-left: 20px;
}

@media(--medium) {
	.c-island--tight\@lg {
		padding: 20px 50px 70px;
	}
}

@media (--small) {
	.c-island {
		padding: 20px 10px;
	}
}

.c-island__header {
	margin-bottom: 30px;
	border-bottom: 1px solid #e3e3e3;
}

.c-island__heading {
	@mixin text-style-zeta;
}

.c-island__image {
	display: block;
	margin-bottom: 10px;
}

@media print {
	.c-island {
		border:none;
		padding:0;
	}
}


/* Status
 *
 * Only compatible with regular c-island, not --loose, --baggy or --tight yet
---------------------------------------------------------------------------- */
.c-island__status {
	@mixin text-style-lambda;
	margin:-21px -51px 0;
	border-radius:4px 4px 0 0;
	padding:7px 51px 8px;
	color:#fff;
}
.c-island--ongoing .c-island__status {
	background:var(--color-ongoing);
	text-shadow:1px 1px 0 rgba(0, 0, 0, 0.4);
}
.c-island--approved .c-island__status {
	background:var(--color-approved);
	text-align:center;
	text-shadow:1px 1px 0 rgba(0, 0, 0, 0.4);
}
.c-island--rejected .c-island__status {
	background:var(--color-rejected);
	text-align:center;
}
.c-island--underreview .c-island__status {
	background:var(--color-underreview);
	color:#613C00;
	text-align:center;
}
.c-island--overdue .c-island__status {
	background:var(--color-overdue);
}
.c-island--notstarted .c-island__status {
	background:#dbdbdb;
	color:#444;
	text-align:center;
}
@media (--small) {
	.c-island__status {
		margin-left:-11px;
		margin-right:-11px;
		padding-left:11px;
		padding-right:11px;
	}
}


/* Loaf
----------------------------------------------------------------------------- */
.c-island__loaf {
	margin:50px -50px;
	padding:30px 40px 40px;
	background:#f8f8f8;
}
.c-island__loaf-heading {
	@mixin text-style-nu;
	margin-bottom:15px;
	font-weight:400;
	text-align:center;
}
.c-island__loaf--no-overflow {
	margin-right:0;
	margin-bottom:0;
	margin-left:0;
}

@media only screen and (max-width:600px) {
	.c-island__loaf {
		margin:30px -10px;
	}
	.c-island__loaf--no-overflow {
		margin-right:0;
		margin-bottom:0;
		margin-left:0;
	}
}
