/* =============================================================================
 * Task description
============================================================================= */
.c-description__heading {
	@mixin text-style-epsilon;
	padding:40px 0 30px;
	text-align:center;
}
.c-description__heading-image {
	position:relative;
	margin-bottom:20px;
}
.c-description__heading-image-media {
	border-radius:50%;
	width:150px;
}
.c-description__badge {
	position:absolute;
	bottom:-12px;
	left:calc(50% - 12px);
	box-shadow:0 0 0 2px #fff;
}
.c-description__pre-heading {
	@mixin text-style-nu;
	display:block;
	margin-bottom:10px;
}
@media(--small) {
	.c-description__heading {
		padding:20px 0 0;
	}
}

/* Deadline
---------------------------------------------------------------------------- */
.c-description__attributes {
	@mixin text-style-nu;
	margin-bottom:30px;
	text-align:center;
}
.c-description__attribute {
	display:inline-block;
	margin:0 10px;
	color:#000;
	padding:5px;
}
.c-description__attribute-key {
	color:#767676;
	margin-right:2px;
}
.c-description__attribute-link,
.c-description__attribute-link:visited {
	color:var(--color-gamma);
	transition:color 0.2s ease;
}
.c-description__attribute-link:hover,
.c-description__attribute-link:focus {
	color:var(--color-iota);
}

@media(--small) {
	.c-description__attributes {
		margin-top:20px;
	}
}

/* Video
---------------------------------------------------------------------------- */
.c-description__video {
	max-width:450px;
	margin-left:auto;
	margin-right:auto;
}
