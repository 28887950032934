.c-horisontal-links__item {
	margin-left: 29px;
}

.c-horisontal-links__item:first-child {
	margin-left: 0;
}

.c-horisontal-links__link {
	font-weight: 400;
	display: flex;
	align-items: center;
	color: var(--color-link);
}

.c-horisontal-links__icon {
	font-size: 20px;
	margin-right: 10px;
	color: var(--color-link);
}

.ie-9 .c-horistontal-links {
	float: right;
}

@media print {
	.c-horistontal-links {
		display:none;
	}
}
