/* =============================================================================
 * Forms
============================================================================= */
/*
 * 1. Enable line-wrapping in IE8+
 * 2. Enable line-wrapping in other browsers
 */
legend {
	display:table; /* [1] */
	white-space:normal; /* [2] */
	max-width:100%;
}
label {
	@mixin text-style-lambda;
	display:block;
	margin-bottom: 8px;
}
label em {
	@mixin text-style-mu;
	float:right;
	color:#767676;
}
button,
input,
select,
textarea {
	color:#000;
	font:inherit;
	font-size:var(--type-size-default);
}
textarea {
	height:72px;
}
select {
	width:100%;
}
input:not([type]),
input[type="text"],
input[type="url"],
input[type="email"],
input[type="tel"],
input[type="title"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="date"],
textarea {
	width:100%;
	padding:12px 20px;
	outline:none;
	border:1px solid var(--color-gray);
	border-radius:3px;
	background:#fff;
	line-height:normal;
	-webkit-appearance:none;
}
select:disabled,
input:disabled,
textarea:disabled,
button:disabled {
	cursor:not-allowed;
}
input:not([type]):disabled,
input[type="text"]:disabled,
input[type="url"]:disabled,
input[type="email"]:disabled,
input[type="tel"]:disabled,
input[type="title"]:disabled,
input[type="number"]:disabled,
input[type="password"]:disabled,
input[type="search"]:disabled,
textarea:disabled {
	opacity:0.5;
	filter:brightness(75%);
}
input:not([type]):focus,
input[type="text"]:focus,
input[type="url"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus {
	border-color:#a6a6a6;
}
/*
 * 1. Reset button look in webkit
 */
input[type="reset"],
input[type="button"],
input[type="submit"],
button {
	-webkit-appearance:none; /* [1] */
}
/*
 * 1. Reset search input look in webkit, making it possible to
 *    style it as a normal text input
 */
input[type="search"] {
	-webkit-appearance:none; /* [1] */
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
	display:none; /* [1] */
}
/*
 * 1. Placeholder styling for Gecko 18- (first two rows of selector)
 *    and Gecko 19+ (second two rows of selector)
 */
input:-moz-placeholder,
textarea:-moz-placeholder,
input::-moz-placeholder,
textarea::-moz-placeholder {
	/* [1] */
	color:rgb(128, 128, 128);
	opacity:1;
}
/*
 * 1. Placeholder styling for webkit
 */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color:rgb(128, 128, 128); /* [1] */
}
/*
 * 1. Placeholder styling for IE10
 */
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color:rgb(128, 128, 128); /* [1] */
}
/*
 * 1. Reset extra padding on gecko buttons
 */
button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
	/* [1] */
	padding:0;
	border:0;
}
fieldset {
	border:none;
}
legend {
	margin-bottom:10px;
	font-weight:400;
	line-height:20px;
	text-transform:uppercase;
}
/*
 * 1. Reset button styles completely
 */
button {
	border:none;
	background:none;
	cursor:pointer;
}

/*
  * Our global reset remove firefox default browser styles for selects. So we need to add them here.
  */
optgroup {
	font-weight:bold;
}
option {
	margin-left:20px;
	font-weight:normal;
}

.c-vertify-form label {
	margin-left: 1px;
}