/* =============================================================================
 * Visually hidden
 *
 * Hidden visually, but screen readers will read it.
============================================================================= */
.t-visually-hidden,
.js .t-visually-hidden--js {
	position:absolute;
	width:1px;
	height:1px;
	clip:rect(1px,1px,1px,1px);
	padding:0;
	border:0;
	overflow:hidden;
}
.t-visually-hidden--focusable:active,
.t-visually-hidden--focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}
