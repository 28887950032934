/* =============================================================================
 * Mobile navigation
 *
 * Hide for desktop
============================================================================= */
.c-mobile-navigation {
	background: #fff;
	display: none;
	position: absolute;
	width: 100%;
	z-index: 9999;
	border-bottom: 1px solid #E9E9E9;
}

.c-mobile-navigation__list {
	border-top: 1px solid #E9E9E9;
}

.c-mobile-navigation__item {
	border-bottom: 1px solid #E9E9E9;
}

.c-mobile-navigation__item:last-child {
	border-bottom: 0;
}

.c-mobile-navigation__item--expanded {
	background: #F8F8F8;
}

.c-mobile-navigation__link-holder {
	position: relative;
}

.c-mobile-navigation__link {
	display: block;
	width: 100%;
	padding: 20px 20px 20px 30px;
	color: var(--color-link);
}

.c-mobile-navigation__link--active {
	font-weight: 600;
}

/* Togglers
 ---------------------------------------------------------------------------- */
.c-mobile-navigation__toggler,
.c-mobile-search__toggler {
	display: none;
	outline: none;
}

.hamburger-only .c-mobile-navigation__toggler {
	display: block;
}

@media(--medium) {
	.c-mobile-navigation__toggler,
	.c-mobile-search__toggler {
		display: block;
	}
}

.c-mobile-search__toggler {
	line-height: normal;
	order: 2;
	margin-right: 30px;
}

@media(--small) {
	.c-mobile-search__toggler {
		position: absolute;
		left: 0;
		margin-right: 0;
	}
}

.c-mobile-search__toggler::after {
	content: '\e90c';
	font-family: 'icomoon';
	font-size: 30px;
	color: var(--color-alpha);
}

.c-mobile-navigation__toggler {
	text-align: center;
	order: 3;
	position: relative;
	padding-bottom: 14px; /* To compensate for absolute positioned label */
}

@media(--small) {
	.c-mobile-navigation__toggler {
		position: absolute;
		right: 0;
	}
}

.c-mobile-navigation__toggler-icon {
	font-size: 30px;
}

.c-mobile-navigation__toggler-label {
	display: block;
	text-transform: uppercase;
	font-size: 12px;
	line-height: normal;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.c-mobile-navigation__link-toggler {
	position: absolute;
	top: 0;
	right: 0;
	width: 17.74%;
	height: 100%;
	color: var(--color-alpha);
	outline: none;
}

.c-mobile-navigation__link-toggler::before {
	content: '';
	display: block;
	width: 1px;
	height: 30px;
	background: #E9E9E9;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.c-mobile-navigation__link-toggler-icon {
	display: block;
	font-size: 10px;
}

.c-mobile-navigation__link-toggler-icon--large {
	font-size: 20px;
	color: var(--color-link);
}

/* Sub navs
 ---------------------------------------------------------------------------- */
.c-mobile-navigation__sub-navigation {
	display: none;
}

.c-mobile-navigation__sub-navigation-third .c-mobile-navigation__link {
	padding-left: 42px;
}

/* Active states
 ---------------------------------------------------------------------------- */
.nav-expanded {
	display: block;
	animation: fadeInFromNone 0.2s ease-in-out;
}

@keyframes fadeInFromNone {
	0% {
		display: none;
		opacity: 0;
		transform: translateY(-20px);
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
		transform: translateY(0);
	}
}

.c-mobile-navigation__link-toggler--active .c-mobile-navigation__link-toggler-icon {
	transform: rotate(180deg);
}

/* Global navigation variant
 ---------------------------------------------------------------------------- */
.c-mobile-navigation--global {
	box-shadow: 0 5px 15px 0 rgba(0,0,0,0.10);
}

.c-mobile-navigation--global .c-mobile-navigation__link {
	@mixin text-style-pi;

	@media (--small) {
		font-size: 11px;
		padding-left: 17px;
	}
}

.c-mobile-navigation--global .c-mobile-navigation__link-toggler {
	width: 15%;
}

/* Print
 ---------------------------------------------------------------------------- */
@media print {
	.c-mobile-navigation,
	.c-mobile-navigation__toggler {
		display:none;
	}
}

/* Logo in mobile
----------------------------------------------------------------------------- */
.c-header__flex-container .c-logo {
	order: 1;
	margin-right: auto;
}

@media(--small) {
	.c-header__flex-container .c-logo {
		margin-right: 0;
	}
}
