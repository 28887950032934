/* =============================================================================
 * Write comment
============================================================================= */
.c-write-comment {
	margin-bottom:40px;
}
.c-write-comment__portrait {
	position:relative;
	display:block;
}
.c-write-comment__portrait-image {
	width:100px;
	border-radius:50%;
}
.c-write-comment__portrait-badge {
	position:absolute;
	bottom:0;
	right:0;
	box-shadow:0 0 0 2px #f4f4f4;
}
.c-write-comment__buttons {
	margin-top:15px;
	text-align:right;
}
.c-write-comment__buttons > * + * {
	margin-left:20px;
}

@media(--small) {
	.c-write-comment__portrait {
		display:none;
	}
}

/*
 * Compact version
 */
.c-write-comment--compact .c-write-comment__portrait {
	display:none;
}


/* Speech bubble arrow for the WYSIWYG editor
 * Normally displayed on the left side.
---------------------------------------------------------------------------- */
.c-write-comment__editor {
	position:relative;
}
.c-write-comment__editor::before,
.c-write-comment__editor::after {
	display:block;
	position:absolute;
	top:39px;
	left:-19px;
	width:0;
	border-width:20px 0 0 20px;
	border-style:solid;
	border-color:#cacaca transparent;
	content:"";
}
.c-write-comment__editor::after {
	top:40px;
	left:-17px;
	border-width:18px 0 0 18px;
	border-color:#fff transparent;
}

@media(--small) {
	.c-write-comment__editor::before,
	.c-write-comment__editor::after {
		display:none;
	}
}

/*
 * Compact version
 */
.c-write-comment--compact .c-write-comment__editor::before,
.c-write-comment--compact .c-write-comment__editor::after {
	display:none;
}


/* Reversed
 * Move the arrow right in the reversed version, also nudge the portrait
---------------------------------------------------------------------------- */
.c-write-comment--reversed .c-write-comment__portrait {
	margin-top:20px;
}
.c-write-comment--reversed .c-write-comment__editor::before {
	right:-19px;
	left:auto;
	border-width:20px 20px 0 0;
}
.c-write-comment--reversed .c-write-comment__editor::after {
	right:-17px;
	left:auto;
	border-width:18px 18px 0 0;
}
