/* =============================================================================
 * Media
 *
 * Remember, any change must be made in all variants of the object.
============================================================================= */
.o-media {
	display:table;
	width:100%;
}
.o-media .o-media__secondary,
.o-media .o-media__primary {
	display:table-cell;
	vertical-align:top;
}
.o-media.o-media--middle > .o-media__secondary,
.o-media.o-media--middle > .o-media__primary {
	vertical-align:middle;
}
.o-media .o-media__secondary {
	padding-right:30px;
}
.o-media .o-media__secondary--tight {
	padding-right:15px;
}
.o-media.o-media--reversed > .o-media__secondary {
	padding-left:30px;
	padding-right:0;
}
.o-media.o-media--reversed > .o-media__secondary--tight {
	padding-left:15px;
}
.o-media .o-media__secondary img {
	max-width:none;
}
.o-media .o-media__primary {
	width:100%;
}

/*
 * @md
 */
@media(--md) {
	.o-media\@md {
		display:table;
		width:100%;
	}
	.o-media\@md .o-media__secondary,
	.o-media\@md .o-media__primary {
		display:table-cell;
		vertical-align:top;
	}
	.o-media\@md.o-media--middle > .o-media__secondary,
	.o-media\@md.o-media--middle > .o-media__primary {
		vertical-align:middle;
	}
	.o-media\@md .o-media__secondary {
		padding-right:30px;
	}
	.o-media\@md .o-media__secondary--tight {
		padding-right:15px;
	}
	.o-media\@md.o-media--reversed > .o-media__secondary {
		padding-left:30px;
		padding-right:0;
	}
	.o-media\@md.o-media--reversed > .o-media__secondary--tight {
		padding-left:15px;
	}
	.o-media\@md .o-media__secondary img {
		max-width:none;
	}
	.o-media\@md .o-media__primary {
		width:100%;
	}
}

/*
 * @lg
 */
@media(--lg) {
	.o-media\@lg {
		display:table;
		width:100%;
	}
	.o-media\@lg .o-media__secondary,
	.o-media\@lg .o-media__primary {
		display:table-cell;
		vertical-align:top;
	}
	.o-media\@lg.o-media--middle > .o-media__secondary,
	.o-media\@lg.o-media--middle > .o-media__primary {
		vertical-align:middle;
	}
	.o-media\@lg .o-media__secondary {
		padding-right:30px;
	}
	.o-media\@lg .o-media__secondary--tight {
		padding-right:15px;
	}
	.o-media\@lg.o-media--reversed > .o-media__secondary {
		padding-left:30px;
		padding-right:0;
	}
	.o-media\@lg.o-media--reversed > .o-media__secondary--tight {
		padding-left:15px;
	}
	.o-media\@lg .o-media__secondary img {
		max-width:none;
	}
	.o-media\@lg .o-media__primary {
		width:100%;
	}
}

/*
 * @xl
 */
@media(--xl) {
	.o-media\@xl {
		display:table;
		width:100%;
	}
	.o-media\@xl .o-media__secondary,
	.o-media\@xl .o-media__primary {
		display:table-cell;
		vertical-align:top;
	}
	.o-media\@xl.o-media--middle > .o-media__secondary,
	.o-media\@xl.o-media--middle > .o-media__primary {
		vertical-align:middle;
	}
	.o-media\@xl .o-media__secondary {
		padding-right:30px;
	}
	.o-media\@xl .o-media__secondary--tight {
		padding-right:15px;
	}
	.o-media\@xl.o-media--reversed > .o-media__secondary {
		padding-left:30px;
		padding-right:0;
	}
	.o-media\@xl.o-media--reversed > .o-media__secondary--tight {
		padding-left:15px;
	}
	.o-media\@xl .o-media__secondary img {
		max-width:none;
	}
	.o-media\@xl .o-media__primary {
		width:100%;
	}
}