/* =============================================================================
 * Header
============================================================================= */
.c-header {
	background-color:#fff;
}

.c-header__flex-container {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: inherit;
	padding: 26px 0;
}

@media(--small) {
	.c-header__flex-container {
		align-items: flex-start;
		justify-content: center;
		text-align: center;
	}
}

@media print {
	.c-header {
		border-bottom:1px solid black;
		margin-bottom:0.5cm;
	}
}
