.c-admin-badge {
	display:inline-block;
	width:25px;
	height:25px;
	background:var(--color-delta);
	color:#000;
	text-align:center;
	border-radius:20px;
	font-size:16px;
	line-height:28px;
}
