/* =============================================================================
 * User information
============================================================================= */
.c-user-information {
	text-align: center;
	margin-top: 50px;
	margin-bottom: 60px;

	@media (--medium) {
		margin-top: 20px;
	}
}

.c-user-information__avatar {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	margin-bottom: 10px;
}

.c-user-information__name {
	@mixin text-style-lambda;
	color: #0068A6;
	margin-bottom: 3px;
}

.c-user-information__email {
	font-size: 12px;
	font-weight: 600;
	line-height: 1.5;
	color: #1C3645;
	display: block;
	margin-bottom: 25px;
}

.c-user-information__button {
	padding: 7px 20px;
	font-size: 12px;
	font-weight: 600;
	border-width: 1px;
}

/* This one is used when switching a anchor to a span element, currenty used on the global user page */
.c-user-information__button--disabled {
	opacity:0.6;
	filter:grayscale(100%);
	box-shadow: none;
	cursor: default;
}

.c-user-information__button--disabled:hover,
.c-user-information__button--disabled:focus {
	transform: none;
	color: var(--color-gamma);
	background: none;
}