.c-quotes {
	padding: 60px 0;
	text-align: center;
}

.c-quotes__inner {
	position: relative;
	padding: 30px 0;
}

.c-quotes__inner::before,
.c-quotes__inner::after {
	content: '\e913';
	font-family: 'icomoon';
	font-size: 26px;
	line-height: inherit;
	display: block;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	color: var(--color-link);
}

.c-quotes__inner::before {
	top: -35px;
}

.c-quotes__inner::after {
	bottom: -35px;
}

.c-quotes__headline {
	@mixin text-style-kappa;
}

.c-quotes__name {
	@mixin text-style-beta;
	display: block;
	margin-top: 30px;
	font-size: 20px;
	color: var(--color-epsilon);
}

@media print {
	.c-quotes {
		page-break-inside:avoid;
	}
}
