.c-button-play,
.c-button-play--small {
	line-height: normal;
	outline: none;
	z-index: 2;
	font-family: 'icomoon';
	color: #fff;
	overflow: visible;
}

.c-button-play:hover,
.c-button-play:visited,
.c-button-play:focus,
.c-button-play--small:hover,
.c-button-play--small:visited,
.c-button-play--small:focus {
	color: #fff !important;
}

.c-button-play {
	font-size: 90px;
}

.c-button-play--small {
	font-size: 70px;
}

.c-button-play::after,
.c-button-play--small::after {
	content: '\e90a';
}

@media(--small) {
	.c-button-play  {
		font-size: 70px;
	}
}