/* =============================================================================
 * Link list
============================================================================= */
.c-link-list {
	
}

.c-link-list__title {
	font-weight: 600;
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 25px;
}

.c-link-list__section {
	display: flex;
	flex-direction: column;
	padding-left: 15px;
	margin-bottom: 40px;
}

.c-link-list__section h4 {
	font-weight: 700;
	font-size: 13px;
	line-height: 1.5;
	margin-bottom: 5px;
}

.c-link-list__link {
	padding: 2px 0;
	color: #0068A6;
}