/* =============================================================================
 * Media
 *
 * 1. Fluid images for responsive purposes.
 * 2. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 * 3. Keep correct aspect ratio when img is scaled down based on parent width.
 * 4. Remove border on <img>'s inside <a>'s
 *
============================================================================= */
img {
	max-width: 100%; /* [1] */
	vertical-align: middle; /* [2] */
	height: auto;
	border:none; /* [4] */
}
img[height] {
	height:auto; /* [3] */
}
