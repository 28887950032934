/* =============================================================================
 * File input
 *
 * Styled file input
============================================================================= */
.c-file-input {
	position:relative;
}
.js .c-file-input input[type="file"] {
	position:absolute;
	opacity:0;
	left:0;
	width:100%;
}

.c-fileforum-input .file-block{
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 20px;
    background: #fff;
    border: 1px solid #dbdbdb;
    transition: all .2s ease
}

.c-fileforum-input .name {
    font: 400 14px/1.7 'Stag Sans','serif';
    text-transform: none;
    line-height: 1.7;
    color: inherit;
    text-shadow: none;
    letter-spacing: normal;
    word-spacing: normal;
    font-family: Stag Sans,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    margin: 7px 0 4px;
}

.c-fileforum-input .file-delete{
    position: relative;
    font: 400 14px/1.7 'Stag Sans','serif';
    text-transform: none;
    line-height: 1.7;
    color: inherit;
    text-shadow: none;
    letter-spacing: normal;
    word-spacing: normal;
    font-family: Stag Sans,sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: .025em;
    color: #005293;
    z-index: 1;
}

.c-fileforum-input .c-attachments__item {
    max-width: 500px;
}

.c-attachments__icon--file {
    background-image: url('../../images/icons/file.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 34px;
    height: 40px;
}