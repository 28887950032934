/* =============================================================================
 * Mini calendar
 *
 * [1] The calendar's top border can't be a border since the edges wouldn't be
 *     straight.
 * [2] Shadow beneath the top border.
 * [3] We scale the calendar down with transform, but that doesn't change how
 *     much space it occupies, so to compensate we add some negative margins
 *     with values based on the original size (~50x60). Used both as a modifier
 *     and on smaller screens.
 * [4] Default text color if inside a link
============================================================================= */
.c-mini-calendar {
	position: relative;
	box-shadow:
		0 5px 20px rgba(0, 0, 0, 0.12),
		0 12px 0 0 var(--color-epsilon) inset; /* [1] */
	background:#fff;
	border:solid #dedede;
	border-width:0 1px 1px 1px;
	padding:12px 5px 0;
	width:50px;
	text-align:center;
	line-height:1;
	color:var(--color-alpha); /* [4] */
	transition:all 0.2s ease;
}
.c-mini-calendar--tinier {
	/* [3] */
	transform:scale(0.7);
	transform-origin:top left;
	margin-right:calc(-50px * 0.3);
	margin-bottom:calc(-60px * 0.3)
}
@media(--small) {
	.c-mini-calendar {
		/* [3] */
		transform:scale(0.7);
		transform-origin:top left;
		margin-right:calc(-50px * 0.3);
		margin-bottom:calc(-60px * 0.3)
	}
}
.c-mini-calendar::before {
	/* [2] */
	content:"";
	position:absolute;
	top:12px;
	right:0;
	left:0;
	height:3px;
	background:rgba(0, 0, 0, 0.1);
}
a:hover .c-mini-calendar,
a:focus .c-mini-calendar {
	transform:translateY(-3px);
	border-color:var(--color-epsilon);
}
.c-mini-calendar__day {
	display:block;
	@mixin text-style-zeta;
	font-weight:700;
}
.c-mini-calendar__month {
	display:block;
	font-size:11px;
	text-transform:uppercase;
	margin:3px 0 6px;
}
