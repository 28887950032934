.c-custom-radio {
	position: relative;
}

.c-custom-radio__label {
	@mixin text-style-iota;
	padding-left: 30px;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	white-space: normal;
}

.c-custom-radio__label--large {
	@mixin text-style-theta;
	margin: 0;
}

.c-custom-radio__label:hover::after {
	background: #FAFAFA;
	border-color: #A6A6A6;
}

.c-custom-radio__input {
	visibility: hidden;
	opacity: 0;
	position: absolute;
}

.c-custom-radio__label::after {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	width: 18px;
	height: 18px;
	line-height: 18px;
	background: #FFFFFF;
	border: 1px solid #C8C8C8;
	border-radius: 50%;
	font-family: 'icomoon';
	font-size: 10px;
	content: '';
	text-align: center;
}

.c-custom-radio__input:checked + .c-custom-radio__label::before {
	content: '';
	width: 10px;
	height: 10px;
	display: block;
	border-radius: 50%;
	background: #000;
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translate(-50%,-50%);
	z-index: 1;
}
