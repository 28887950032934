.c-progress-indicator {
	display:flex;
	flex-wrap:wrap;
	margin:0 20px 20px;
}

@media(--medium) {
	.c-progress-indicator {
		flex-wrap:nowrap;
	}
}

@media only screen and (min-width:640px) {
	.c-progress-indicator {
		justify-content:center;
		margin:70px 50px 50px;
	}
}

@media print {
	.c-progress-indicator {
		display:none;
	}
}

.c-progress-indicator [class^="icon-"] {
	display:block;
	color:#579ac5;
	font-size:30px;
}
.c-progress-indicator a:hover [class^="icon-"],
.c-progress-indicator a:focus [class^="icon-"] {
	color:var(--color-gamma);
}
.c-progress-indicator .icon-check {
	position:absolute;
	top:11px;
	left:50%;
	margin-left:-5px;
	color:#579ac5;
	font-size:8px;
}
.c-progress-indicator a {
	flex:none;
	color:#000;
	font-size:14px;
	text-align:center;
	white-space:nowrap;
}
.c-progress-indicator__text {
	display:block;
	margin-top:5px;
}
.c-progress-indicator__hole::before {
	display:block;
	box-sizing:border-box;
	width:30px;
	height:30px;
	border-radius:50%;
	background:#579ac5;
	content:"";
}
.c-progress-indicator__hole--done {
	position:relative;
}
.c-progress-indicator__hole--available::before {
	border:5px solid #579ac5;
	background:#fff;
}
.c-progress-indicator__hole:hover::before,
.c-progress-indicator__hole:focus::before {
	background:var(--color-gamma);
}
.c-progress-indicator__hole--available:hover::before,
.c-progress-indicator__hole--available:focus::before {
	border-color:var(--color-gamma);
	background:#fff;
}
.c-progress-indicator__separator {
	display:inline-block;
	flex:none;
	width:30px;
	height:0;
	margin-top:14px;
	border-top:2px solid #f5f5f5;
	content:"";
}
.c-progress-indicator__separator--dashed {
	width:20px;
	border-top-style:dashed;
}

.c-progress-indicator__report {
	margin-left:30px;
	margin-top:-10px;
	padding:10px 0 10px 30px;
	border-left:1px solid #ccc;
}

a.c-progress-indicator__report-link {
	display:flex;
	flex-direction:column;
	align-items:center;
	text-align:center;
}

.c-progress-indicator__report-link svg {
	fill:#579ac5;
	width:30px;
	height:30px;
}

/* Scrollable
 * When the combined width of the children is larger than the flex container,
 * make the container scrollable.
----------------------------------------------------------------------------- */
.c-progress-indicator--is-scrollable {
	overflow-x:scroll;
	justify-content:flex-start;
	margin:0;
}
.c-progress-indicator__scroll-shadow {
	position:relative;
	overflow:hidden;
	margin:0 20px 10px;
}
.c-progress-indicator__scroll-shadow--shadow-left::before {
	position:absolute;
	top:15%;
	right:100%;
	width:50px;
	height:70%;
	border-radius:0 15px 15px 0 / 0 80% 80% 0;
	box-shadow:6px 0 5px rgba(0, 0, 0, 0.32);
	content:"";
}
.c-progress-indicator__scroll-shadow--shadow-right::after {
	position:absolute;
	top:15%;
	left:100%;
	width:50px;
	height:70%;
	border-radius:15px 0 0 15px / 80% 0 0 80%;
	box-shadow:-6px 0 5px rgba(0, 0, 0, 0.32);
	content:"";
}

@media only screen and (min-width:640px) {
	.c-progress-indicator__scroll-shadow {
		margin:50px 50px 30px;
	}
}
