/* =============================================================================
 * Submission of a task
 *
 * 1: Context for a spinner
============================================================================= */
.c-submission__form {
	position:relative; /* [1] */
}
.c-submission__heading {
	display:inline-block;
	margin-right:15px;
}

/* Attachments
----------------------------------------------------------------------------- */
.c-submission__attachments {
	margin:20px 0 40px;
}
.c-submission__attachments h3 {
	margin-bottom:10px;
	font-weight:400;
}
.c-submission__attachment-rules {
	margin-top:5px;
	color:#444;
}
@media(--medium) {
	.c-submission__attachments {
		margin-bottom:10px;
	}
	.c-submission__attachment-rules {
		margin-top:20px;
		text-align:center;
	}
}

/* Exercises
 * Styling for the select exercises dialog
----------------------------------------------------------------------------- */
.c-submission__select-exercises {
	background:#f1f1f1;
	padding:50px 70px;
}


/* Tools
----------------------------------------------------------------------------- */
.c-submission__tools {
	display:flex;
	align-items:flex-start;
}

@media(--medium) {
	.c-submission__tools {
		display:block;
	}
	.c-submission__attach {
		text-align:center;
	}
}

/* Attachment tools
----------------------------------------------------------------------------- */
.c-submission__attach > * {
	display:inline-block;
}
.c-submission__attach > * + * {
	margin-left:10px;
	margin-right:10px;
}


/* Save actions
----------------------------------------------------------------------------- */
.c-submission__save {
	margin-left:auto;
}
.c-submission__save > *:first-child:not(:last-child) {
	margin:0 5px 10px;
}
.c-submission__save-status {
	position:relative;
	float:right;
	margin-top:-25px;
	padding-left:15px;
	color:#707070;
	line-height:1.4;
}
.c-submission__save-status::before {
	position:absolute;
	left:0;
	top:9px;
	width:5px;
	height:5px;
	background-color:transparent;
	border-radius:50%;
	transition:background-color 0.2s ease;
	content:"";
}
.c-submission__save-status.saved::before {
	background-color:var(--color-approved);
}
.c-submission__save-status.saving::before {
	background-color:var(--color-underreview);
}
.c-submission__save-status.not-saved::before {
	background-color:var(--color-underreview);
}

@media(--medium) {
	.c-submission__save {
		display:flex;
		flex-direction:column;
		align-items:center;
		margin-top:40px;
	}
}
