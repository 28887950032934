.c-hero {
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color:#0068a6;
}

.c-hero--overlay:before {
	content: " ";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,0.5);
	width: 100%;
	height: 100%;
	z-index: 1;
}


.c-hero--overlay .c-hero__content {
	position: relative;
	z-index: 2;
}

.c-hero__content {
	text-align: center;
	padding: 100px 0;
}

.c-hero--teasers .c-hero__content {
	padding-bottom:30px;
}

@media(--small) {
	.c-hero__content {
		/*height: 230px;*/
		padding: 60px 0;
	}
}

@media print {
	.c-hero__additional {
		display:none;
	}
}

.c-hero__headline {
	@mixin text-style-delta;
	color: #fff;
	display: block;
	text-shadow: 1px 1px rgba(0,0,0,0.2);
}

.c-hero__headline-pre,
.c-hero__headline-sub {
	@mixin text-style-theta;
	position:relative;
	display:block;
}

.c-hero__headline-pre {
	padding-bottom:30px;
	margin-bottom:30px;
}

.c-hero__headline-sub {
	padding-top:30px;
	margin-top:35px;
}

.c-hero__headline-pre::before,
.c-hero__headline-sub::before {
	content:"";
	width:170px;
	height:6px;
	position:absolute;
	top:100%;
	left:50%;
	margin-left:-85px;
	background:#fff;
}

.c-hero__headline-pre--dark::before,
.c-hero__headline-sub--dark::before {
	background:#333;
}

.c-hero__headline-sub::before {
	top:auto;
	bottom:100%;
}

@media print {
	.c-hero__headline-sub,
	.c-hero__headline-pre {
		margin:0;
		padding:0;
	}
	.c-hero__headline-sub::before,
	.c-hero__headline-pre::before {
		display:none;
	}
}


@media(--small) {
	.c-hero__headline {
		font-size: 30px;
		line-height: normal;
	}
}

.c-hero__headline--small {
	@mixin text-style-beta;
	color: #fff;
}

.c-hero__headline--dark {
	color: #333;
}

.c-hero__login {
	margin-top: 45px;
}

@media(--small) {
	.c-hero__login {
		margin-top: 28px;
	}
}

@media(--small) {
	.c-hero__login .c-button--delta {
		width: 39.2%;
		padding: 9px;
		font-size: 16px;
	}
}

.c-hero__login .c-button:first-of-type {
	margin-right: 37px;
}

@media(--small) {
	.c-hero__login .c-button:first-of-type {
		margin-right: 2.796610%;
	}
}

.c-hero__link,
.c-hero__link {
	color: #fff;
	text-decoration: underline;
}

.c-hero__text-wrapper {
	margin: 5px 10% 25px;
	color: #fff;
	font-size: 16px;
	font-weight: 400;
}
.c-hero__text-wrapper a,
.c-hero__text-wrapper a:visited {
	color:#fff;
	border-bottom:1px solid rgb(255, 255, 255);
	transition:border 0.2s ease;
}

.c-hero__text-wrapper a:hover,
.c-hero__text-wrapper a:focus {
	border-bottom-color:transparent;
}

@media (--medium) {
	.c-hero__text-wrapper {
		font-size: 14px;
	}
}

@media (--small) {
	.c-hero__text-wrapper {
		margin-left:0;
		margin-right:0;
	}
}

.c-hero__text-wrapper--dark {
	color: #333;
}

.c-hero__text-wrapper--dark a,
.c-hero__text-wrapper--dark a:visited {
	color:#333;
	border-bottom:1px solid rgb(0, 0, 0);
}

/* Small
 ---------------------------------------------------------------------------- */
.c-hero--small .c-hero__content {
	padding: 66px 0;
}

@media(--small) {
	.c-hero--small .c-hero__content{
		padding: 34px 0;
	}
}

/* With layout
 ---------------------------------------------------------------------------- */
.c-hero__inner {
	display:flex;
}

.c-hero--w-layout .c-hero__content {
	text-align:left;
}
.c-hero--w-layout .c-hero__text-wrapper {
	margin:5px 0 15px;
}
.c-hero--w-layout .c-hero__left {
	flex:1 1 50%;
	padding-right:40px;
}
.c-hero--w-layout .c-hero__right {
	flex:0 1 auto;
}

@media(--medium) {
	.c-hero__inner {
		display:block;
	}
	.c-hero--w-layout .c-hero__content {
		text-align:center;
	}
	.c-hero--w-layout .c-hero__left {
		padding-right:0;
		padding-bottom:30px;
	}
}

@media(--small) {
	.c-hero--w-layout .c-hero__left {
		padding-bottom:0;
	}
	.c-hero--w-layout .c-hero__right {
		display:none;
	}
}

/* More links
 ---------------------------------------------------------------------------- */
.c-hero__more-link {
	margin:0 10px 10px 0;
}
@media(--medium) {
	.c-hero__more-link {
		margin:0 5px 10px 5px;
	}
}

/* People
 ---------------------------------------------------------------------------- */
.c-hero__people-item {
	display:inline-block;
	margin-bottom:20px;
	width:110px;
	text-align:center;
	vertical-align:top;
}
.c-hero__people-link {
	@mixin text-style-beta;
	display:block;
	width:100%;
	color:#fff;
}
.c-hero__people-link--more {
	display:inline-block;
	width:60px;
	height:60px;
	line-height:60px;
	border-radius:50%;
	box-shadow:0 0 0 2px #fff;
	transition:box-shadow 0.2s ease, color 0.2s ease;
}
.c-hero__people-portrait {
	position:relative;
	display:inline-block;
}
.c-hero__people-portrait-image {
	box-shadow:0 0 0 2px #fff;
	border-radius:50%;
	width:60px;
	transition:box-shadow 0.2s ease;
}
.c-hero__people-badge {
	position:absolute;
	bottom:0;
	right:-10px;
	box-shadow:0 0 0 2px #fff;
	transition:box-shadow 0.2s ease;
}
.c-hero__people-text {
	margin-top:10px;
	overflow:hidden;
	text-overflow:ellipsis;
}
.c-hero__people-title {
	@mixin text-style-lambda;
	display:block;
	margin-top:5px;
	color:var(--color-delta);
}

.c-hero__people-link--more:hover,
.c-hero__people-link--more:focus,
.c-hero__people-link:hover .c-hero__people-badge,
.c-hero__people-link:focus .c-hero__people-badge,
.c-hero__people-link:hover .c-hero__people-portrait-image,
.c-hero__people-link:focus .c-hero__people-portrait-image {
	box-shadow:0 0 0 2px var(--color-delta);
}
.c-hero__people-link--more:hover,
.c-hero__people-link--more:focus {
	color:var(--color-delta);
}

/* =============================================================================
 * Teasers
============================================================================= */
.c-hero__teasers {
	margin-top: 50px;
}

.c-hero__teaser {
	display:block; /* IE10 */
	background: rgba(0,0,0,0.40);
	padding: 30px;
	margin: calc(var(--spacing--vertical-spacing-unit) * 0.5) 0;
	text-align: center;
	word-wrap: break-word;
	flex-grow: 1;
	transition: background-color 0.15s ease;
}

.c-hero__teaser:hover {
	background: rgba(0,0,0,0.60);
}

@media(--small) {
	.c-hero__teaser {
		margin: calc(var(--spacing--vertical-spacing-unit-mobile) * 0.5) 0;
	}
}

/* =============================================================================
 * Buttons
============================================================================= */
.c-hero__buttons {
	margin-top: 15px;
}

.c-hero__button {
	margin-right: 10px;
}

@media (--small) {
	.c-hero__button {
		margin-bottom: 10px;
	}
}

.c-hero__button:last-child {
	margin-right: 0;
}

.c-hero__button--dark[class] {
	color:#333;
	border-color:#333;
}

.c-hero__button--dark[class]:hover,
.c-hero__button--dark[class]:focus {
	color:#fff;
	background-color:#333;
}

/* Print
 ---------------------------------------------------------------------------- */
@media print {
	.c-hero__content {
		padding-top:1cm;
		padding-bottom:1cm;
		text-align:left;
	}
	.c-hero__teasers,
	.c-hero__buttons {
		display:none;
	}
	.c-hero__login {
		margin:0;
	}
	.c-hero .c-hero__headline,
	.c-hero .c-hero__headline--accent {
		color:black;
	}
	.c-hero .c-hero__text-wrapper {
		color:black;
		margin-bottom:0;
	}
}
