.c-self-test {
	margin-bottom: 50px;
}

.c-self-test__item {
	margin-bottom: calc(var(--spacing--vertical-spacing-unit) / 2);
	padding-bottom: calc(var(--spacing--vertical-spacing-unit) / 2);
	border-bottom: 1px solid var(--color-gray);
}

.c-self-test__item:last-child {
	border-bottom-width: 0;
}

@media(--small) {
	.c-self-test__item {
		margin-bottom: calc(var(--spacing--vertical-spacing-unit-mobile) / 2);
		padding-bottom: calc(var(--spacing--vertical-spacing-unit-mobile) / 2);
	}
}

.c-self-test__image-link {
	display: block;
	margin-bottom: 15px;
}

.c-self-test__image {
	margin-bottom: 5px;
}

.c-self-test__image-text {
	font-size: 0.8em;
	line-height: 1.3em;
	display: none;
	color: var(--color-alpha);

	@media (--medium) {
		display: block;
	}
}

.c-self-test__item .c-custom-checkbox {
	margin-bottom: 10px;
	padding-left: 25px;
}

.c-self-test__item .c-custom-checkbox:last-child {
	margin-bottom: 0;
}

.c-self-test__item .c-custom-radio {
	padding-left: 25px;
}

.c-self-test__headline--secondary {
	@mixin text-style-beta;
}

.c-self-test__item--correct {
	margin-top: 25px;
	padding: 20px;
	border: 3px dotted #00a63e;
	position: relative;
	padding-top: 25px;
	border-radius: 4px;
}

.c-self-test__item--wrong {
	margin-top: 25px;
	padding: 20px;
	border: 3px dotted #f3001f;
	position: relative;
	padding-top: 25px;
	border-radius: 4px;
}

.c-self-test__item--correct:last-child,
.c-self-test__item--wrong:last-child {
	border-bottom-width: 3px;
}

.c-self-test__section-feedback {
	margin-top: 25px;
	margin-bottom: 25px;
	padding: 20px;
	border: 3px dotted #C8C8C8;
	border-radius: 4px;
}

.c-self-test__section-feedback--correct {
	border-color: #00a63e;
}
.c-self-test__section-feedback--wrong {
	border-color: #f3001f;
}

.c-self-test__section-feedback .c-self-test__headline--secondary {
	margin-bottom: 10px;
}

.c-self-test__correct-label {
	font-size: 16px;
	font-weight: bold;
	color: #00a63e;
	position: absolute;
	right: 15px;
	bottom: 15px;
	line-height: 1;
	transform: rotate(-45deg);
}

.c-self-test__wrong-label {
	font-size: 16px;
	font-weight: bold;
	color: #f3001f;
	position: absolute;
	right: 15px;
	bottom: 15px;
	line-height: 1;
	transform: rotate(-45deg);
}

.c-self-test__answer {
	margin-top: 20px;
}

.self-test-footer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.self-test-footer .c-button {
	height: 50px; /* To force button to be the exact height even if they contain an icon */
}

@media screen and (min-width:900px) {
	.self-test-footer {
		flex-direction: row;
	}
}