.c-tabs {
	width:100%;
	margin-bottom:40px;
}

.c-tabs--compact {
	margin-bottom: 30px;
}

.no-js .c-tabs {
	margin:56px 0 20px;
}
.c-tabs__list {
	display:flex;
	justify-content:flex-start;
	flex-wrap:wrap;
	@media(--md) {
		justify-content:center;
	}
}
.c-tabs__item[class] {
	display:flex;
	align-self:stretch;
	max-width:280px;
	flex-grow:1;
}

@media only screen and (max-width:600px) {
	.c-tabs__item {
		flex-grow:1;
		flex-shrink:1;
		width: 100%;
	}
}

.c-tabs__link,
.c-tabs__link:visited {
	@mixin text-style-theta;
	position:relative;
	display:block;
	width:100%;
	padding:14px 20px;
	background-color:#fff;
	box-shadow:0 0 0 1px #eee;
	color:var(--color-link);
	font-size:14px;
	text-align:center;
	transition:background-color 0.15s ease;
}

.c-tabs__link--compact {
	@mixin text-style-omicron;
	border-bottom: 2px solid var(--color-link);
	background-color: #F7F7F7;
	box-shadow: none;
	margin-right: 2px;

	@media (--small) {
		flex-grow: 1;
		border-bottom: 0;
		margin-bottom: 2px;
		margin-right: 0;
	}
}

.c-tabs__link .c-count {
	position:absolute;
	top:-0.3em;
	right:-1em;
}

@media only screen and (max-width:600px) {
	.c-tabs__link,
	.c-tabs__link:visited {
		width:auto;
		line-height:1.3;
	}
}

@media only screen and (max-width:1000px) {
	.c-tabs__link,
	.c-tabs__link:visited {
		font-size:14px;
	}
}

.c-tabs__link:hover,
.c-tabs__link:focus {
	background-color:var(--color-link);
	color:#fff;
}

.c-tabs__link--compact.c-tabs__link--active {
	font-weight: 600;
}

.c-tabs__link--active,
.c-tabs__link--active:hover,
.c-tabs__link--active:focus,
.c-tabs__link--active:visited {
	box-shadow:inset 0 10px 10px 0 rgba(0, 0, 0, 0.1);
	background-color:var(--color-link);
	color:#fff;
}