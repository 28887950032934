/* =============================================================================
 * Site Vanilla theme
 *
 * Remember to change the setting 'site namespace class' in index.php if you
 * rename this class.
============================================================================= */
/* site specific styles */
.site {
	background: #fff;
	color:var(--color-alpha);
	font:var(--type-defaults);
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:greyscale;
}

.portal {
	background: var(--color-eta);
}

/* gnit styles */
html {
	background: #fff;
	color:var(--color-alpha);
	font:var(--type-defaults);
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:greyscale;
}

.gnit-examples__preview {
	background: var(--color-gnit-examples);
}
