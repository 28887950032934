/* =============================================================================
 * Share form
============================================================================= */


/* User input
----------------------------------------------------------------------------- */
.c-share-form__user-input {
	display:flex;
	align-items:flex-end;
	margin-bottom:40px;
}
.c-share-form__field {
	margin-right:20px;
	flex-grow:1;
}
.c-share-form__button {
	flex-shrink:0;
}

/* List of recipients
 *
 * 1: Shouldn't be needed, but IE 10 wants it dearly
----------------------------------------------------------------------------- */
.c-share-form__recipients-heading {
	padding-bottom:5px;
	border-bottom:1px solid #d7d7d7;
}
.c-share-form__recipients,
.c-share-form__earlier-recipients {
	margin-bottom:40px;
}
.c-share-form__recipient-list-item {
	display:flex;
	margin:15px 0;
}
.c-share-form__recipient-symbol {
	color:red;
	font-size:20px;
}
.c-share-form__recipient-email {
	margin-right:20px;
	margin-left:20px;
	flex-grow:1;
}
.c-share-form__already-received {
	color:#767676;
	font-style:italic;
}
.c-share-form__recipient-remove {
	width:20px;
	height:20px;
	background:#d7d7d7;
	color:#fff;
	font-size:10px;
	border-radius:50%;
	line-height:22px;
	text-align:center;
	transition:background 0.2s ease;
	margin-left:auto; /* 1 */
}
.c-share-form__recipient-remove:hover,
.c-share-form__recipient-remove:focus {
	background:#000;
}

/* Submit
----------------------------------------------------------------------------- */
.c-share-form__submit {
	display:block;
	margin:0 auto;
}
