/* =============================================================================
 * Autosave form
============================================================================= */

.c-autosave-form__bar {
	position:fixed;
	right:0;
	bottom:15px;
	left:0;
	z-index:10;
	transform:translateY(200px);
	transition:transform 0.8s ease;

}
.c-autosave-form__bar--visible {
	transform:translateY(0);
}
.c-autosave-form__bar-inner {
	display:flex;
	align-items:center;
	background:rgba(255, 255, 255, 0.95);
	padding:15px;
	border-radius:50px;
	box-shadow:0 0 5px rgba(0, 0, 0, 0.25);
}

/* Message
---------------------------------------------------------------------------- */
.c-autosave-form__bar-text {
	margin:0 20px;
	transition:opacity 0.2s ease;
}
.c-autosave-form__bar-text--hidden {
	opacity:0;
}
.c-autosave-form__save-button {
	margin-left:auto;
}
