/* =============================================================================
 * Highlight
============================================================================= */
.c-highlight {
	margin-bottom: 25px;
	padding: 20px;
	border: 5px solid var(--color-delta);
}
.c-highlight > *:last-child {
	margin-bottom: 0;
}
.c-highlight--calm {
	border: 5px solid var(--color-iota);
}