.c-custom-checkbox {
	position:relative;
}
.c-custom-checkbox__input {
	position:absolute;
	opacity:0;
}
.c-custom-checkbox__label {
	@mixin text-style-iota;
	display:inline-block;
	position:relative;
	padding-left:30px;
	font-size:14px;
	vertical-align:middle;
	white-space:normal;
}
.c-custom-checkbox__label::after {
	position:absolute;
	top:50%;
	left:0;
	width:18px;
	height:18px;
	border:1px solid #c8c8c8;
	border-radius:2px;
	background:#fff;
	font-size:14px;
	font-family:"icomoon";
	line-height:18px;
	content:"";
	text-align:center;
	transform:translateY(-50%);
}
.c-custom-checkbox__label:hover::after,
.c-custom-checkbox__input:focus + .c-custom-checkbox__label::after {
	border-color:#999;
	background:#fafafa;
}
.c-custom-checkbox__input:checked + .c-custom-checkbox__label::after {
	content:"\e905";
}
.c-custom-checkbox__input:disabled + .c-custom-checkbox__label::after {
	background:#ccc;
}
.c-custom-checkbox__input:disabled + .c-custom-checkbox__label {
	cursor:not-allowed;
}
.c-custom-checkbox--airy {
	padding:20px 40px 35px;
	border:1px solid #e4e4e4;
	border-radius:4px;
	background:#fff;
}