/* =============================================================================
 * People list
============================================================================= */
.c-people-list {
	margin-bottom:20px;
}

/* Item
----------------------------------------------------------------------------- */
.c-people-list__item {
	display:flex;
	position:relative;
	margin-bottom:14px;
	border:1px solid #dbdbdb;
	background:#fff;
	transition:box-shadow 0.2s ease;
}
.c-people-list__item:not(.c-people-list__item--disabled):hover {
	box-shadow:0 0 4px rgba(0, 0, 0, 0.1);
}
.c-people-list__item--disabled {
	background:#f4f4f4;
}

/*
 * Boxless
 */
.c-people-list__item--boxless {
	margin-bottom:20px;
	border:none;
	box-shadow:none !important;
	background:transparent;
}

/* Portrait
----------------------------------------------------------------------------- */
.c-people-list__portrait {
	position:relative;
	align-self:center;
	flex:0 0 auto;
	max-width:none;
	margin-right:15px;
	margin-left:20px;
	padding:10px 0;
}
.c-people-list__portrait-image {
	border-radius:50%;
	width:40px;
}
.c-people-list__portrait-badge {
	position:absolute;
	bottom:5px;
	right:-5px;
	box-shadow:0 0 0 2px #fff;
	transform:scale(0.85);
	transform-origin:bottom right;
}
.c-people-list__item--disabled .c-people-list__portrait-badge {
	box-shadow:0 0 0 2px #f4f4f4;
}

/*
 * Boxless
 */
.c-people-list__item--boxless .c-people-list__portrait {
	margin-left:0;
	padding:0;
}
.c-people-list__item--boxless .c-people-list__portrait-badge {
	bottom:-5px;
}

/* Item info
----------------------------------------------------------------------------- */
.c-people-list__item-info {
	align-self:center;
	flex:1;
	overflow:hidden;
	margin-bottom:0;
	padding:10px 0;
	font-weight:300;
	line-height:1.3;
	text-overflow:ellipsis;
	white-space:nowrap;
	text-align:left;
	outline:none;
}
.c-people-list__item-info::before {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:1;
	content:"";
}

/*
 * Boxless
 */
.c-people-list__item--boxless .c-people-list__item-info {
	padding:0;
}


/* Item name
----------------------------------------------------------------------------- */
.c-people-list__item-name {
	display:block;
	overflow:hidden;
	color:#000833;
	font-weight:400;
	text-overflow:ellipsis;
	transition:color 0.2s ease, border 0.2s ease;
}
.c-people-list--name-only .c-people-list__item-name {
	font-size:16px;
}
.c-people-list__item--disabled .c-people-list__item-name {
	color:#767676;
}

/*
 * Boxless
 */
.c-people-list__item--boxless .c-people-list__item-name {
	display:inline-block;
	border-bottom:1px solid rgba(0, 8, 51, 0.3);
}

/* Item status
----------------------------------------------------------------------------- */
.c-people-list__item-status {
	@mixin text-style-nu;
	margin-right:5px;
	color:#767676;
}


/* Profile link
 * Used when checkboxes are present
----------------------------------------------------------------------------- */
.c-people-list__profile-link,
.c-people-list__profile-link:visited {
	@mixin text-style-nu;
	position:relative;
	z-index:10;
	color:#767676;
	border-bottom:1px solid rgba(118, 118, 118, 0.3);
}
.c-people-list__profile-link:hover,
.c-people-list__profile-link:focus {
	color:var(--color-iota);
	border-bottom:1px solid var(--color-iota);
}

/* Checkbox
----------------------------------------------------------------------------- */
.c-people-list__checkbox {
	display:flex;
	align-items:center;
	padding:0 20px;
	border-left:1px solid #dbdbdb;
}


/* Count
----------------------------------------------------------------------------- */
.c-people-list__item .c-count {
	position:absolute;
	top:5px;
	left:45px;
}
