/* =============================================================================
 * Fieldset
============================================================================= */
.c-fieldset {
	margin-bottom:40px;
}
.c-fieldset--nested {
	margin-bottom:0;
	margin-left:30px;
}
.c-fieldset__content {
	position:relative;
	padding:30px;
	background:var(--color-eta);
}
.c-fieldset__title {
	@mixin text-style-theta;
	margin-bottom:10px;
	font-size:24px;
}
.c-fieldset--collapsed {
	margin-bottom:0;
}
@media(--small) {
	.c-fieldset {
		margin-bottom:20px;
	}
	.c-fieldset__content {
		padding:15px;
	}
}
