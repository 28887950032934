/* =============================================================================
 * Login
============================================================================= */

.c-login {
	margin-bottom: 70px;
}

/* login header
 ---------------------------------------------------------------------------- */
.c-login__header {
	text-align: center;
	margin-bottom: 40px;
}

.c-login__pre-headline {
	@mixin text-style-nu;
	font-size: 14px;
	display: block;
	margin-bottom: 19px;
}

.c-login__headline {
	@mixin text-style-zeta;
	color: #000;
}

/* login button
 ---------------------------------------------------------------------------- */
.c-login__button {
	@mixin text-style-beta;
	display:block; /* IE10 */
	width: 100%;
	border: 1px solid #c8c8c8;
	padding: 20px 25px;
	text-align: left;
	position: relative;
	border-radius: 3px;
	color: #000000;
	background: #FFFFFF;
	outline: none;
}

@media(--small) {
	.c-login__button {
		font-size: 18px;
	}
}

@media(--small) {
	.c-login__button--register {
		border-bottom: 0;
	}
}

.c-login__button-type-icon {
	display: inline-block;
	font-size: 30px;
	margin-right: 11px;
	vertical-align: middle;
	color: var(--color-delta);
}

.c-login__button-toggle-icon {
	position: absolute;
	top: 0;
	right: 0;
	border: 0;
	border-left: 1px solid #c8c8c8;
	width: 13.3630%; /*60px/449px*/
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--color-beta);
	transition: background-color 0.15s ease;
}

.c-login__button--active .c-login__button-toggle-icon::after {
	content: '\e912';
}

.c-login__button--active .c-login__button-toggle-icon {
	color: #fff;
	background: var(--color-link);
}

.c-login__start {
	margin-bottom: 20px;
	background: var(--color-eta);
}

.c-login__forms-form {
	position: relative;
	padding: 51px 0;
}

.c-login__fieldset {
	margin-bottom: 40px;
}

@media(--small) {
	.c-login__fieldset {
		margin-bottom: 30px;
	}
}

/* login details
 ---------------------------------------------------------------------------- */
.c-login__details {
	width: 100%;
	@mixin clearfix;
	position: relative;
}

@media(--small) {
	.c-login__details {
		margin-bottom: 30px;
	}

	.c-login__details .c-custom-checkbox {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
}

.c-login__details-link {
	@mixin text-style-omicron;
	color: var(--color-link);
	margin-right: 40px;
}

@media(--small) {
	.c-login__details-link {
		display: block;
		margin-bottom: 5px;
	}

	.c-login__details-read-more .c-login__details-link {
		display: inline-block;
		margin-bottom: 0;
	}
}

.c-login__details-checkbox {
	float: left;
}

.c-login__details-checkbox label {
	font-size: 14px;
}

.c-login__details .c-button {
	float: right;
}

.c-login__arrow-up {
	position: absolute;
	top: -10px;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid #fff;
}

.c-login__forms-login .c-login__arrow-up {
	right: 20px;
}

.c-login__forms-register .c-login__arrow-up {
	left: 20px;
}

@media(--small) {
	.c-login__arrow-up {
		display: none;
	}
}

/* login footer
 ---------------------------------------------------------------------------- */
.c-login__footer {
	text-align: center;
}

.c-login__submit {
	display: block;
	margin: 0 auto;
}

.c-login__help-link {
	display: inline-block;
	margin-top: 20px;
	color: var(--color-link);
}
