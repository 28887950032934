/* =============================================================================
 * Time/status card
 *
 * [1]: Boundaries for .c-time-status-card__link::before
 * [2] Based on the computed height of the progress doughnut
============================================================================= */
.c-module-card {
	display:flex;
	flex:1;
	-ms-flex-preferred-size:auto;
	flex-direction:column;
	justify-content:space-between;
	position:relative; /* [1] */
	margin-bottom:25px;
	text-align:center;
	border-radius:4px;
	background:#fff;
}

.c-module-card > div {
	padding-right:30px;
	padding-bottom:30px;
	padding-left:30px;
}

.c-module-card--simple {
	flex-direction:row-reverse;
	text-align:left;
	padding-bottom:0;
}

.c-module-card--disabled {
	background:rgba(255, 255, 255, 0.6);
}

.c-module-card__top {
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	flex:0 0 auto;
	margin-bottom:15px;
	border-radius:4px 4px 0 0;
	padding-top:30px;
	border-bottom: 1px solid var(--new-color-grey);
}

.c-module-card--simple .c-module-card__top {
	flex:0 0 auto;
	align-self:stretch;
	margin:0;
	padding:15px;
	border-radius:0 4px 4px 0;
	border-bottom: 0;
	border-left: 1px solid var(--new-color-grey);
}

.c-module-card__bottom {
	flex: 1 1 auto;
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	padding:15px 30px 30px;
	border-radius:0 0 4px 4px;
}

.c-module-card--simple .c-module-card__bottom {
	border-radius: 4px 0 0 4px;
}

.c-module-card__bottom__text-wrapper {
	margin-top: auto;
}

.c-module-card__heading {
	@mixin text-style-eta;
	margin-bottom: 20px;
}

.c-module-card--simple .c-module-card__heading {
	margin-bottom: 10px;
}

.c-module-card--disabled .c-module-card__heading,
.c-module-card--disabled .c-module-card__description {
	opacity: 0.6;
}

.c-module-card--disabled .c-module-card__heading > button {
	font: inherit;
	text-transform: inherit;
	text-align: inherit;
	cursor: default;
}

.c-module-card--disabled .c-module-card__heading > button:focus {
	outline: none;
}

.c-module-card__description {
	margin-bottom: 25px;
}

.c-module-card__text-with-icon {
	color:#737373;
	line-height: 1;
}

.c-module-card__text-with-icon-icon {
	color:var(--color-gamma);
}

.c-module-card__text-with-icon-icon.icon-success {
	color:#61b47e;
}

.c-module-card__text-with-icon-icon.icon-lock {
	color:var(--color-rejected);
}

.c-module-card__icon-wrapper {
	border: 8px solid var(--new-color-grey);
	border-radius: 50%;
	height: 100px;
	width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.c-module-card--simple .c-module-card__icon-wrapper { 
	border-width: 7px;
	width: 80px;
	height: 80px;
}

.c-module-card__icon {
	font-size: 50px;
	color:var(--color-rejected);
}

.c-module-card--simple .c-module-card__icon { 
	font-size: 40px;
}

@media(--small) {
	.c-module-card--simple {
		flex-direction:column;
	}

	.c-module-card--simple .c-module-card__top {
		border-radius: 4px 4px 0 0;
	}
}

/* Link
 *
 * Covers the whole card area, but only the heading is linked
---------------------------------------------------------------------------- */
.c-module-card__link {
	color:#000;
	transition:color 0.2s ease;
}
.c-module-card__link:not(.c-module-card--disabled .c-module-card__link):hover,
.c-module-card__link:not(.c-module-card--disabled .c-module-card__link):focus {
	color:var(--color-gamma);
}
.c-module-card__link::before {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	content:"";
	z-index:1;
	transition:box-shadow 0.2s ease;
}
.c-module-card__link:not(.c-module-card--disabled .c-module-card__link):hover::before,
.c-module-card__link:not(.c-module-card--disabled .c-module-card__link):focus::before {
	box-shadow:0 0 4px rgba(0, 0, 0, 0.1);
}