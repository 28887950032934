/* =============================================================================
 * Datepicker
============================================================================= */
.ui-datepicker {
	border:none;
	box-shadow:0 0 4px rgba(0, 0, 0, 0.3);
	margin-top:5px;
	padding:5px;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
	top:7px;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-prev-hover {
	left:5px;
}
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-next-hover {
	right:5px;
}
.ui-datepicker .ui-state-default {
	border:none;
	background:transparent;
	text-align:center;
}
.ui-datepicker td a:hover {
	outline:1px solid #ccc;
}
.ui-datepicker .ui-datepicker-header {
	border-top:none;
	border-left:none;
	border-right:none;
	border-radius:3px 3px 0 0;
	margin:-5px -5px 0;
	padding:8px 0;
}
.ui-datepicker .ui-state-highlight {
	color:inherit;
	font-weight:bold;
}

.ui-datepicker .ui-state-active,
.ui-datepicker .ui-state-active:hover {
	color:inherit;
	outline:1px solid #000;
}
