.c-chart-legend__legend {
	padding:10px;
	background:#fff;
}
.c-chart-legend__item {
	display:inline-flex;
	margin:0 8px 8px 8px;
}
.c-chart-legend__val[class] {
	margin:0 0 0 8px;
}
