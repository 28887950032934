/* =============================================================================
 * Task results
============================================================================= */
.c-task-results__heading {
	border-bottom:1px solid #eaeaea;
}
.c-task-results__list {
	margin-bottom:20px;
	border-bottom:1px solid #eaeaea;
}
.c-task-results__list > li {
	display:flex;
	align-items:flex-start;
	padding:15px 0;
}
.c-task-results__result {
	line-height:20px;
	flex-grow:1;
}
.c-task-results__calendar {
	display:inline-block;
	min-width:30px;
	min-height:30px;
	margin-right:24px;
	border-top:6px solid #d00000;
	background:#fff;
	box-shadow:0 4px 10px rgba(0, 0, 0, 0.2);
	font-weight:bold;
	font-size:18px;
	line-height:24px;
	text-align:center;
}
@media(--small) {
	.c-task-results__calendar {
		display:none
	}
}
.c-task-results__result-date {
	display:block;
	font-weight:bold;
	font-size:12px;
	text-transform:uppercase;
}
.c-task-results__result-text {
	display:block;
}
.c-task-results__edit-result-form {
	background:#f2f2f2;
	border-radius:10px;
	padding:15px;
	margin-top:15px;
}
.c-task-results__add-result-button {
	margin-bottom:20px;
}
.c-task-results__result-actions li {
	display:inline-block;
}
.c-task-results__result-action {
	border-bottom:1px solid currentColor;
	color:var(--color-link);
	line-height:1;
	margin-bottom:5px;
	font-weight:bold;
	font-size:12px;
	text-transform:uppercase;
}
.c-task-results__result-action:first-child {
	margin-right:10px;
}
