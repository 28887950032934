/* =============================================================================
 * Tables
============================================================================= */
table {
	width:100%;
	border-spacing:0;
	border-collapse:collapse;
}
caption {
	text-align:left;
}
td,
th {
	vertical-align:top;
}
th {
	text-align:left;
}