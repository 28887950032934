/* =============================================================================
 * Workout/task collection
============================================================================= */
.c-collection:not(.c-collection--no-top-space) {
	padding:40px 0 0;
}
.c-collection__icon {
	width:40px;
	margin-bottom:20px;
}
.c-collection__name {
	@mixin text-style-epsilon;
	margin-bottom:20px;
}
.c-collection__status {
	display:flex;
	justify-content:center;
	margin-bottom:20px;
}
.c-collection__status > * + * {
	margin-left:20px;
}
.c-collection__short-desc {
	@mixin text-style-beta;
	margin:-5px 0 15px;
}
.c-collection__long-desc {
	max-width:780px;
	margin-left:auto;
	margin-right:auto;
}
.c-collection__levels {
	margin-bottom:30px;
}
.c-collection__sub-heading {
	@mixin text-style-zeta;
	margin-bottom:40px;
	text-align:center;
}
.c-collection--minimal .c-collection__sub-heading {
	margin-bottom:20px;
	font-size:20px;
	text-align:left;
}

@media(--small) {
	.c-collection {
		padding:10px 0 0;
	}
	.c-collection__sub-heading {
		margin-bottom:20px;
	}
}


/* Header
----------------------------------------------------------------------------- */
.c-collection__header .c-collection__name {
	font-size:24px;
	margin-bottom:0;
}
.c-collection__header .c-collection__status {
	justify-content:flex-start;
	margin-bottom:0;
}

@media screen and (min-width:601px) {
	.c-collection__header {
		display:flex;
		align-items:center;
	}
	.c-collection__header .c-collection__status {
		margin-left:auto;
	}
}


/* Wrapper
---------------------------------------------------------------------------- */
.c-collection__wrapper {
	background:var(--color-eta);
	padding:40px 40px 30px;
	margin-left:-20px;
	margin-right:-20px;
	margin-top:30px;
}
.c-collection__wrapper:first-child {
	margin-top:0;
}
.c-collection--editable .c-collection__wrapper {
	padding-right:60px;
}
.c-collection__wrapper--no-background {
	margin-right:0;
	margin-left:0;
	padding:0;
	background:transparent;
}

@media(--small) {
	.c-collection__wrapper:not(.c-collection__wrapper--no-background),
	.c-collection--editable .c-collection__wrapper {
		margin-left:0;
		margin-right:0;
		padding:20px 15px 10px;
	}
}


/* Stripes
---------------------------------------------------------------------------- */
.c-collection__item {
	display:flex;
	align-items:center;
}
.c-collection__stripe {
	flex-grow:1;
}


/* Remove links
---------------------------------------------------------------------------- */
.c-collection__remove,
.c-collection__remove:visited {
	margin:0 -37px 0 20px;
	font-size:19px;
	color:#000;
}
@media(--small) {
	.c-collection__remove,
	.c-collection__remove:visited {
		margin:0 -5px 0 10px;
	}
}


/* Summary
---------------------------------------------------------------------------- */
.c-collection__summary {
	margin-top:20px;
}


/* Numbered list
---------------------------------------------------------------------------- */
.c-collection__list {
	counter-reset:list;
	margin-bottom:15px;
}
.c-collection__item {
	position:relative;
}
.c-collection__item--animating {
	transition:transform 0.2s ease;
}
.c-collection__item::before {
	position:absolute;
	left:-30px;
	top:50%;
	width:20px;
	margin-top:-10px;
	counter-increment:list;
	content:counter(list)".";
	color:#666;
	text-align:right;
}
.c-collection__list--numberless .c-collection__item::before {
	display:none;
}
@media(--small) {
	.c-collection__list {
		margin-left:0;
	}
	.c-collection__item::before {
		display:none;
	}
}


/* Print
---------------------------------------------------------------------------- */
@media print {
	.c-collection__remove {
		display: none;
	}
}
