/* video
 ---------------------------------------------------------------------------- */
/* We might wanna have a video component in the future */

/* video dialog variant
 ---------------------------------------------------------------------------- */
.c-video__header {
    text-align: center;
    margin-bottom: 23px;
}

.c-video__headline {
    @mixin text-style-zeta;
}

.c-video__description {
    text-align: center;
    margin-top: 40px;
}

@media(--small) {
    .c-video__description {
        margin-top: 20px;
    }
}

.c-video__holder {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.c-video__player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

iframe.c-video__player + .c-video__description {
    position: absolute;
    top: 100%;
}

/*
	Subtitles
*/

.flowplayer .fp-captions {
    font-size: 1.6rem !important;
    line-height: 120%;
    width: 90% !important;
    left: 5% !important;
    padding: 0.3rem 0.1rem !important;
    font-family: "Open Sans" !important;
    text-align: center !important;
    font-weight: bold !important;
}

.flowplayer.is-fullscreen .fp-captions {
    font-size: 3rem !important;
}

.flowplayer .fp-captions pre {
    background-color: rgba(1, 0, 0, 0.6) !important;
    padding: 0.4rem !important;
}

@media screen and (min-width: 500px) {
    .flowplayer .fp-captions {
        font-size: 1.3rem !important;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1020px) and (orientation: landscape) {
    .flowplayer .fp-captions pre {
        font-size: 1rem !important;
        line-height: 120% !important;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) and (max-width: 1020px) and (orientation: landscape) {
    .flowplayer .fp-captions pre {
        font-size: 1rem !important;
        line-height: 120% !important;
    }
}


@media screen and (max-width: 820px) {
    .flowplayer .fp-captions {
        bottom: 0.2rem !important;
    }

    .flowplayer .fp-captions pre {
        font-size: 0.8rem !important;
        line-height: 120% !important;
    }

    .flowplayer.is-fullscreen .fp-captions pre, .flowplayer.is-fullscreen .fp-captions {
        font-size: 0.8rem !important;
        line-height: 120% !important;
    }
}

@media screen and (min-width: 630px) {
    .flowplayer .fp-captions {
        font-size: 1.4rem !important;
    }
}

@media screen and (min-width: 1000px) {
    .flowplayer .fp-captions {
        font-size: 1.6rem !important;
    }
}

@media screen and (min-width: 1300px) {
    .flowplayer.is-fullscreen .fp-captions {
        font-size: 3rem !important;
    }
}

@media screen and (min-width: 1600px) {
    .flowplayer.is-fullscreen .fp-captions {
        font-size: 3.8rem !important;
    }
}