.c-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	animation: c-spinner--spin 1.5s linear infinite;
}

.c-spinner img {
	width: 40px;
}

@keyframes c-spinner--spin {
	0% {
		transform:translateX(-50%) translateY(-50%) rotate(-360deg);
	}
	100% {
		transform:translateX(-50%) translateY(-50%) rotate(360deg);
	}
}