/* =============================================================================
 * Inline typography
 *
 * Typographic tweaks to inline elements.
============================================================================= */
abbr {
	border-bottom:1px dotted rgb(136, 136, 136);
	cursor:help;
}
code,
kbd,
var {
	font-family:Monaco, Courier, "Courier New", Tahoma;
	font-size:0.9em;
}
/*
 * 1. Avoid line-height issues caused by default rendering of sub and sup elements
 */
sub,
sup {
	/* [1] */
	position:relative;
	height:0;
	line-height:1;
	vertical-align:baseline;
}
sup {
	/* [1] */
	bottom:1ex;
	font-size:0.7rem;
}
sub {
	/* [1] */
	top:0.5ex;
}