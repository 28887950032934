.c-article {
	margin-bottom: var(--spacing--vertical-spacing-unit);
}

@media(--medium) {
	.c-article {
		margin-bottom: calc(var(--spacing--vertical-spacing-unit-mobile));
	}
}

.c-article__headline {
	@mixin text-style-epsilon;
	display: block;
	margin-bottom: 20px;
}

.c-article__sub-headline {
	@mixin text-style-zeta;
	margin-bottom: 20px;
}

.c-article__ingress {
	font-size:1.15rem;
}

.c-article__ingress em {
	font-style:italic;
}

.c-article__ingress strong {
	font-weight:700;
}
