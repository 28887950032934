.c-breadcrumbs {
	padding: 10px 0;
	border-bottom: 1px solid var(--color-gray);
	margin-bottom: 20px;
}

.c-breadcrumbs--inline-block {
	display:inline-block;
}

.c-breadcrumbs__item {
	margin-right: 10px;
}

.c-breadcrumbs__item:last-child {
	margin-right: 0;
}

.c-breadcrumbs__separator {
	font-size: 7px;
	display: inline-block;
	margin: 0 10px;
	vertical-align: middle;
	color: var(--color-accent);
}

.c-breadcrumbs__link,
.c-breadcrumbs__link:hover {
	font-size: 13px;
	color: #000;
}

.c-breadcrumbs__link--selected,
.c-breadcrumbs__link--selected:hover {
	color: #505050;
}

@media print {
	.c-breadcrumbs {
		display:none;
	}
}
