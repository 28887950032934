/* =============================================================================
 * Like
============================================================================= */
.c-like__icon {
	color:#979797;
	font-size:18px;
	vertical-align:-2px;
	margin-right:8px;
	transition:color 0.2s ease;
}
.c-like--liked .c-like__icon,
.c-like:hover .c-like__icon,
.c-like:focus .c-like__icon {
	color:#000;
}
.c-like__text {
	color:#000;
	border-bottom:1px solid rgba(0, 0, 0, 0.3);
	transition:border 0.2s ease;
}
.c-like:hover .c-like__text,
.c-like:focus .c-like__text {
	border-bottom-color:inherit;
}
