.c-card-wrapper {
	margin-bottom: 30px;
}

.c-card-wrapper::after {
	@mixin clearfix;
}

.c-card-wrapper__inner {
	clear: both;
}

.c-card-wrapper__btn {
	float: right;
}

.c-card-wrapper__header {
	margin-bottom:25px;
}

@media(--small) {
	.c-card-wrapper__btn {
		margin-top: 10px;
	}
}

.c-card-wrapper__heading {
	@mixin text-style-eta;
	display: inline-block;
	font-size: 20px;
}
