/* =============================================================================
 * Map
============================================================================= */
.c-map {
	margin-bottom:50px;
}
.c-map__map-area {
	height:400px;
	line-height:1.5;
}
.c-map__data {
	display: none;
}
