/* =============================================================================
 * Search form
============================================================================= */
.c-search-form {
	margin: var(--spacing--vertical-spacing-unit) 0;
}

@media(--small) {
	.c-search-form {
		margin: var(--spacing--vertical-spacing-unit-mobile) 0;
	}
}

.c-search-form__field {
	width: 100%;
	position: relative;
}

.c-search-form__submit {
	height: 100%;
	padding: 0 20px;
	position: absolute;
	top: 0;
	right: 0;
	background: none;
	border: none;
	outline: none;
	opacity: 0;
	font-size: 14px;
	font-weight: 400;
	color: var(--color-link);
	cursor: pointer;
	transition: color .162s ease,opacity .162s ease;
}

.c-search-form__submit:hover {
	opacity: 1;
}

.has-text .c-search-form__submit {
	opacity: 1;
}

.c-search-form__toggler {
	line-height: normal;
	position: absolute;
	right: -83px;
	top: 50%;
	transform: translateY(-50%);
	transition: right .3s ease;
}

@media (max-width: 1400px) {
	.c-search-form__toggler {
		right: 10px;
	}
}

.c-search-form__toggler::after {
	content: '\e90c';
	font-family: 'icomoon';
	font-size: 20px;
	color: var(--color-link);
}



