/* =============================================================================
 * Exercise manager
 *
 * 1. Hide everything in <768px
============================================================================= */
.c-exercise-manager {
	margin:40px auto 80px;
}
@media only screen and (max-width:767px) {
	.c-exercise-manager > div {
		display:none; /* [1] */
	}
}


/* Mobile message
 *
 * 1. Keep this visible in <768px
----------------------------------------------------------------------------- */
.c-exercise-manager__mobile-message[class] {
	display:block; /* [1] */
}
@media only screen and (min-width:768px) {
	.c-exercise-manager__mobile-message[class] {
		display:none;
	}
}


/* Header
----------------------------------------------------------------------------- */
.c-exercise-manager__header {
	margin-bottom:20px;
}
.c-exercise-manager__heading {
	margin-top:12px;
}


/* Columns wrapper
----------------------------------------------------------------------------- */
.c-exercise-manager__columns {
	margin-bottom:30px;
}
@media only screen and (min-width:1000px) {
	.c-exercise-manager__columns {
		display:flex;
	}
}


/* Canvas
----------------------------------------------------------------------------- */
.c-exercise-manager__canvas-wrapper {
	margin-left:auto;
	margin-right:auto;
	width:748px;
}
@media only screen and (min-width:1000px) {
	.c-exercise-manager__canvas-wrapper {
		margin-left:auto;
		margin-right:0;
	}
}
.c-exercise-manager__canvas {
	cursor:default;
	display:block;
}
@media only screen and (max-width:767px) {
	.c-exercise-manager__canvas {
		display:none;
	}
}

/*
 * Cursors
 */
.c-exercise-manager__canvas[data-cursor="default"] {
	cursor:default;
}
.c-exercise-manager__canvas[data-cursor="pointer"] {
	cursor:pointer;
}
.c-exercise-manager__canvas[data-cursor="grab"] {
	cursor:grab;
}
.c-exercise-manager__canvas[data-cursor="grabbing"] {
	cursor:grabbing;
}
.c-exercise-manager__canvas[data-cursor="move"] {
	cursor:move;
}
.c-exercise-manager__canvas[data-cursor="crosshair"] {
	cursor:crosshair;
}
.c-exercise-manager__canvas[data-cursor="cell"] {
	cursor:cell;
}
.c-exercise-manager__canvas[data-cursor="text"] {
	cursor:text;
}


/* Tools
 *
 * 1. Positioning context for the symbols legend link
----------------------------------------------------------------------------- */
.c-exercise-manager__toolbox {
	position:relative; /* [1] */
	margin-bottom:20px;
}
@media only screen and (min-width:1000px) {
	.c-exercise-manager__toolbox {
		margin-bottom:0;
		width:calc(100% - 770px);
	}
}
.c-exercise-manager__tools {
	display:none;
}
.c-exercise-manager__tool {
	display:inline-block;
	margin:5px;
	vertical-align:top;
	text-align:center;
	width:70px;
	z-index:1;
	outline:none;
}
.c-exercise-manager__tool--selected .c-exercise-manager__tool-text {
	font-weight:400;
}
.c-exercise-manager__tool-graphic {
	display:block;
	margin-left:auto;
	margin-right:auto;
	border:4px solid #fff;
	outline:1px solid #dfdfdf;
	width:50px;
	height:50px;
	margin-bottom:5px;
	margin-top:1px;
	background-color:#666;
	background-repeat:no-repeat;
	background-position:center center;
}
.c-exercise-manager__tool:hover .c-exercise-manager__tool-graphic,
.c-exercise-manager__tool:focus .c-exercise-manager__tool-graphic {
	outline:1px solid var(--color-theta);
}
.c-exercise-manager__tool--selected .c-exercise-manager__tool-graphic {
	background-color:#333;
}

/*
 * Football
 */
.c-exercise-manager__tool-graphic--bg1 {
	background-color:#74c886;
}
.c-exercise-manager__tool--selected .c-exercise-manager__tool-graphic--bg1,
.c-exercise-manager__tool--selected:hover .c-exercise-manager__tool-graphic--bg1,
.c-exercise-manager__tool--selected:focus .c-exercise-manager__tool-graphic--bg1 {
	outline:1px solid #ffcd00;
	background-color:#62a971;
}

/*
 * Riding
 */
.c-exercise-manager__tool-graphic--bg2 {
	background-color:#d3c9b8;
}
.c-exercise-manager__tool--selected .c-exercise-manager__tool-graphic--bg2,
.c-exercise-manager__tool--selected:hover .c-exercise-manager__tool-graphic--bg2,
.c-exercise-manager__tool--selected:focus .c-exercise-manager__tool-graphic--bg2 {
	outline:1px solid #a8a093;
	background-color:#c9bfaf;
}

/*
 * Volleyball
 */
.c-exercise-manager__tool-graphic--bg3 {
	background-color:#f6a28a;
}
.c-exercise-manager__tool--selected .c-exercise-manager__tool-graphic--bg3,
.c-exercise-manager__tool--selected:hover .c-exercise-manager__tool-graphic--bg3,
.c-exercise-manager__tool--selected:focus .c-exercise-manager__tool-graphic--bg3 {
	outline:1px solid #3450ad;
	background-color:#f6a28a;
}

/*
 * Beach volleyball
 */
.c-exercise-manager__tool-graphic--bg4 {
	background-color:#ddc6a6;
}
.c-exercise-manager__tool--selected .c-exercise-manager__tool-graphic--bg4,
.c-exercise-manager__tool--selected:hover .c-exercise-manager__tool-graphic--bg4,
.c-exercise-manager__tool--selected:focus .c-exercise-manager__tool-graphic--bg4 {
	outline:1px solid #3450ad;
	background-color:#ddc6a6;
}

/*
 * Cross country riding
 */
.c-exercise-manager__tool-graphic--bg5 {
	background-color:#539a5d;
}
.c-exercise-manager__tool--selected .c-exercise-manager__tool-graphic--bg5,
.c-exercise-manager__tool--selected:hover .c-exercise-manager__tool-graphic--bg5,
.c-exercise-manager__tool--selected:focus .c-exercise-manager__tool-graphic--bg5 {
	outline:2px solid #a8a093;
	outline-offset:-1px;
	background-color:#4a8a53;
}

/*
 * Handball
 */
.c-exercise-manager__tool-graphic--bg6 {
	background-color:#0068a6;
}
.c-exercise-manager__tool--selected .c-exercise-manager__tool-graphic--bg6,
.c-exercise-manager__tool--selected:hover .c-exercise-manager__tool-graphic--bg6,
.c-exercise-manager__tool--selected:focus .c-exercise-manager__tool-graphic--bg6 {
	outline:1px solid #3450ad;
	background-color:#0068a6;
}

/*
 * Floorball
 */
.c-exercise-manager__tool-graphic--bg7 {
	background-color:#2083a4;
}
.c-exercise-manager__tool--selected .c-exercise-manager__tool-graphic--bg7,
.c-exercise-manager__tool--selected:hover .c-exercise-manager__tool-graphic--bg7,
.c-exercise-manager__tool--selected:focus .c-exercise-manager__tool-graphic--bg7 {
	outline:1px solid #3450ad;
	background-color:#2083a4;
}


/* Tool selector icons
----------------------------------------------------------------------------- */

/*
 * General
 */
.c-exercise-manager__tool-graphic--rectangle                                                    { background-image: url(./assets/icons/tools/area-rectangle-dashed.svg); }
.c-exercise-manager__tool-graphic--triangle                                                     { background-image: url(./assets/icons/tools/area-triangle.svg); }
.c-exercise-manager__tool-graphic--ellipse                                                      { background-image: url(./assets/icons/tools/area-ellipse.svg); }
.c-exercise-manager__tool-graphic--text                                                         { background-image: url(./assets/icons/tools/text-white.svg); }
.c-exercise-manager__tool-graphic--cone                                                         { background-image: url(./assets/icons/tools/cone.svg); }
.c-exercise-manager__tool-graphic--curve                                                        { background-image: url(./assets/icons/tools/curve.svg); }

/*
 * Football
 */
.c-exercise-manager__tool-graphic--defender                                                     { background-image: url(./assets/icons/tools/football-defender.svg); }
.c-exercise-manager__tool-graphic--ballholder                                                   { background-image: url(./assets/icons/tools/football-ballholder.svg); }
.c-exercise-manager__tool-graphic--attacker                                                     { background-image: url(./assets/icons/tools/football-attacker.svg); }
.c-exercise-manager__tool-graphic--third                                                        { background-image: url(./assets/icons/tools/football-third.svg); }
.c-exercise-manager__tool-graphic--goalkeeper                                                   { background-image: url(./assets/icons/tools/football-goalkeeper.svg); }
.c-exercise-manager__tool-graphic--pathwithoutball                                              { background-image: url(./assets/icons/tools/football-path-without-ball.svg); }
.c-exercise-manager__tool-graphic--pathwithball                                                 { background-image: url(./assets/icons/tools/football-path-with-ball.svg); }
.c-exercise-manager__tool-graphic--ballpath                                                     { background-image: url(./assets/icons/tools/football-ballpath.svg); }
.c-exercise-manager__tool-graphic--goal                                                         { background-image: url(./assets/icons/tools/football-goal.svg); }
.c-exercise-manager__tool-graphic--line                                                         { background-image: url(./assets/icons/tools/football-line.svg); }
.c-exercise-manager__tool-graphic--ball                                                         { background-image: url(./assets/icons/tools/football-ball.svg); }
.c-exercise-manager__tool-graphic--vest                                                         { background-image: url(./assets/icons/tools/football-vest.svg); }

/*
 * Riding
 */
.c-exercise-manager__tool-graphic--pole                                                         { background-image: url(./assets/icons/tools/riding-pole.svg); }
.c-exercise-manager__tool-graphic--cavaletti                                                    { background-image: url(./assets/icons/tools/riding-cavaletti.svg); }
.c-exercise-manager__tool-graphic--vertical                                                     { background-image: url(./assets/icons/tools/riding-vertical.svg); }
.c-exercise-manager__tool-graphic--oxer                                                         { background-image: url(./assets/icons/tools/riding-oxer.svg); }
.c-exercise-manager__tool-graphic--triple-bar                                                   { background-image: url(./assets/icons/tools/riding-triple-bar.svg); }
.c-exercise-manager__tool-graphic--water                                                        { background-image: url(./assets/icons/tools/riding-water.svg); }
.c-exercise-manager__tool-graphic--halt                                                         { background-image: url(./assets/icons/tools/riding-halt.svg); }
.c-exercise-manager__tool-graphic--cone-top-view                                                { background-image: url(./assets/icons/tools/riding-cone-top-view.svg); }
.c-exercise-manager__tool-graphic--serpentine                                                   { background-image: url(./assets/icons/tools/riding-serpentine.svg); }
.c-exercise-manager__tool-graphic--volte                                                        { background-image: url(./assets/icons/tools/riding-volte.svg); }
.c-exercise-manager__tool-graphic--through-a-circle                                             { background-image: url(./assets/icons/tools/riding-through-a-circle.svg); }
.c-exercise-manager__tool-graphic--teardrop                                                     { background-image: url(./assets/icons/tools/riding-teardrop.svg); }
.c-exercise-manager[data-sport="riding"] .c-exercise-manager__tool-graphic--ellipse             { background-image: url(./assets/icons/tools/area-ellipse-transparent.svg); }
.c-exercise-manager__tool-graphic--corner-turn                                                  { background-image: url(./assets/icons/tools/riding-corner-turn.svg); }
.c-exercise-manager__tool-graphic--straight-line                                                { background-image: url(./assets/icons/tools/riding-straight-line.svg); }
.c-exercise-manager__tool-graphic--semicircle                                                   { background-image: url(./assets/icons/tools/riding-semicircle.svg); }
.c-exercise-manager__tool-graphic--leg-yield                                                    { background-image: url(./assets/icons/tools/riding-leg-yield.svg); }
.c-exercise-manager__tool-graphic--shoulder-in                                                  { background-image: url(./assets/icons/tools/riding-shoulder-in.svg); }
.c-exercise-manager__tool-graphic--renvers                                                      { background-image: url(./assets/icons/tools/riding-renvers.svg); }
.c-exercise-manager[data-sport="riding"] .c-exercise-manager__tool-graphic--text                { background-image: url(./assets/icons/tools/text-black.svg); }

/*
 * Volleyball
 */
.c-exercise-manager__tool-graphic--volleyball-player                                            { background-image: url(./assets/icons/tools/volleyball-player.svg); }
.c-exercise-manager__tool-graphic--volleyball-attacker                                          { background-image: url(./assets/icons/tools/volleyball-attacker.svg); }
.c-exercise-manager__tool-graphic--volleyball-bagger                                            { background-image: url(./assets/icons/tools/volleyball-bagger.svg); }
.c-exercise-manager__tool-graphic--volleyball-passer                                            { background-image: url(./assets/icons/tools/volleyball-passer.svg); }
.c-exercise-manager__tool-graphic--volleyball-blocker                                           { background-image: url(./assets/icons/tools/volleyball-blocker.svg); }
.c-exercise-manager__tool-graphic--volleyball-libero                                            { background-image: url(./assets/icons/tools/volleyball-libero.svg); }
.c-exercise-manager__tool-graphic--volleyball-ball                                              { background-image: url(./assets/icons/tools/volleyball-ball.svg); }
.c-exercise-manager[data-sport="volleyball"] .c-exercise-manager__tool-graphic--ballpath        { background-image: url(./assets/icons/tools/volleyball-ballpath.svg); }
.c-exercise-manager__tool-graphic--plinth                                                       { background-image: url(./assets/icons/tools/volleyball-plinth.svg); }
.c-exercise-manager__tool-graphic--hula-hoop                                                    { background-image: url(./assets/icons/tools/volleyball-hula-hoop.svg); }
.c-exercise-manager__tool-graphic--hurdle                                                       { background-image: url(./assets/icons/tools/volleyball-hurdle.svg); }
.c-exercise-manager__tool-graphic--mat                                                          { background-image: url(./assets/icons/tools/volleyball-mat.svg); }
.c-exercise-manager[data-sport="volleyball"] .c-exercise-manager__tool-graphic--pathwithoutball { background-image: url(./assets/icons/tools/volleyball-path-without-ball.svg); }

/*
 * Cross country riding
 */
.c-exercise-manager__tool-graphic--gallop                                                       { background-image: url(./assets/icons/tools/cross-country-riding-gallop.svg); }
.c-exercise-manager__tool-graphic--log                                                          { background-image: url(./assets/icons/tools/cross-country-riding-log.svg); }
.c-exercise-manager__tool-graphic--rail                                                         { background-image: url(./assets/icons/tools/cross-country-riding-rail.svg); }
.c-exercise-manager__tool-graphic--cross-country-oxer                                           { background-image: url(./assets/icons/tools/cross-country-riding-oxer.svg); }
.c-exercise-manager__tool-graphic--ditch                                                        { background-image: url(./assets/icons/tools/cross-country-riding-ditch.svg); }
.c-exercise-manager__tool-graphic--bounce                                                       { background-image: url(./assets/icons/tools/cross-country-riding-bounce.svg); }
.c-exercise-manager__tool-graphic--brush-fence                                                  { background-image: url(./assets/icons/tools/cross-country-riding-brush-fence.svg); }
.c-exercise-manager__tool-graphic--bush                                                         { background-image: url(./assets/icons/tools/cross-country-riding-bush.svg); }
.c-exercise-manager__tool-graphic--house                                                        { background-image: url(./assets/icons/tools/cross-country-riding-house.svg); }
.c-exercise-manager__tool-graphic--roll-top                                                     { background-image: url(./assets/icons/tools/cross-country-riding-roll-top.svg); }
.c-exercise-manager__tool-graphic--table                                                        { background-image: url(./assets/icons/tools/cross-country-riding-table.svg); }
.c-exercise-manager__tool-graphic--corner                                                       { background-image: url(./assets/icons/tools/cross-country-riding-corner.svg); }
.c-exercise-manager__tool-graphic--cross-country-triple-bar                                     { background-image: url(./assets/icons/tools/cross-country-riding-triple-bar.svg); }
.c-exercise-manager__tool-graphic--trakehner                                                    { background-image: url(./assets/icons/tools/cross-country-riding-trakehner.svg); }

/*
 * Handball
 */
.c-exercise-manager__tool-graphic--handball-attacker                                            { background-image: url(./assets/icons/tools/handball-attacker.svg); }
.c-exercise-manager__tool-graphic--handball-defender                                            { background-image: url(./assets/icons/tools/handball-defender.svg); }
.c-exercise-manager__tool-graphic--handball-ball                                                { background-image: url(./assets/icons/tools/handball-ball.svg); }
.c-exercise-manager__tool-graphic--handball-goalkeeper                                          { background-image: url(./assets/icons/tools/handball-goalkeeper.svg); }
.c-exercise-manager__tool-graphic--cone-defense                                                 { background-image: url(./assets/icons/tools/handball-cone-defense.svg); }
.c-exercise-manager__tool-graphic--handball-cone                                                { background-image: url(./assets/icons/tools/handball-cone.svg); }
.c-exercise-manager__tool-graphic--dribble                                                      { background-image: url(./assets/icons/tools/handball-dribble.svg); }
.c-exercise-manager__tool-graphic--handball-pathwithball                                        { background-image: url(./assets/icons/tools/handball-path-with-ball.svg); }
.c-exercise-manager__tool-graphic--handball-shot                                                { background-image: url(./assets/icons/tools/handball-shot.svg); }
.c-exercise-manager__tool-graphic--handball-block                                               { background-image: url(./assets/icons/tools/handball-block.svg); }
.c-exercise-manager__tool-graphic--shooting-threat                                              { background-image: url(./assets/icons/tools/handball-shooting-threat.svg); }
.c-exercise-manager__tool-graphic--footstep                                                     { background-image: url(./assets/icons/tools/handball-footstep.svg); }
.c-exercise-manager__tool-graphic--handball-pathwithoutball                                     { background-image: url(./assets/icons/tools/handball-path-without-ball.svg); }
.c-exercise-manager__tool-graphic--handball-ballpath                                            { background-image: url(./assets/icons/tools/handball-ballpath.svg); }

/*
 * Floorball
 */
.c-exercise-manager__tool-graphic--floorball-attacker                                           { background-image: url(./assets/icons/tools/floorball-attacker.svg); }
.c-exercise-manager__tool-graphic--floorball-defender                                           { background-image: url(./assets/icons/tools/floorball-defender.svg); }
.c-exercise-manager__tool-graphic--floorball-goalkeeper                                         { background-image: url(./assets/icons/tools/floorball-goalkeeper.svg); }
.c-exercise-manager__tool-graphic--floorball-ball                                               { background-image: url(./assets/icons/tools/floorball-ball.svg); }
.c-exercise-manager__tool-graphic--floorball-straight-line                                      { background-image: url(./assets/icons/tools/floorball-straight-line.svg); }
.c-exercise-manager__tool-graphic--floorball-backward-dribble                                   { background-image: url(./assets/icons/tools/floorball-backward-dribble.svg); }
.c-exercise-manager__tool-graphic--floorball-forward-dribble                                    { background-image: url(./assets/icons/tools/floorball-forward-dribble.svg); }
.c-exercise-manager__tool-graphic--floorball-forward-movement                                   { background-image: url(./assets/icons/tools/floorball-forward-movement.svg); }
.c-exercise-manager__tool-graphic--floorball-backward-movement                                  { background-image: url(./assets/icons/tools/floorball-backward-movement.svg); }
.c-exercise-manager__tool-graphic--floorball-shot                                               { background-image: url(./assets/icons/tools/floorball-shot.svg); }
.c-exercise-manager__tool-graphic--floorball-pass                                               { background-image: url(./assets/icons/tools/floorball-pass.svg); }
.c-exercise-manager__tool-graphic--floorball-throw-out                                          { background-image: url(./assets/icons/tools/floorball-throw-out.svg); }
.c-exercise-manager__tool-graphic--floorball-direction-change                                   { background-image: url(./assets/icons/tools/floorball-direction-change.svg); }
.c-exercise-manager__tool-graphic--floorball-shot-block                                         { background-image: url(./assets/icons/tools/floorball-shot-block.svg); }
.c-exercise-manager__tool-graphic--floorball-brake                                              { background-image: url(./assets/icons/tools/floorball-brake.svg); }
.c-exercise-manager__tool-graphic--floorball-tempo-change                                       { background-image: url(./assets/icons/tools/floorball-tempo-change.svg); }
.c-exercise-manager__tool-graphic--floorball-goal-large                                         { background-image: url(./assets/icons/tools/floorball-goal-large.svg); }
.c-exercise-manager__tool-graphic--floorball-goal-small                                         { background-image: url(./assets/icons/tools/floorball-goal-small.svg); }
.c-exercise-manager__tool-graphic--floorball-referee                                            { background-image: url(./assets/icons/tools/floorball-referee.svg); }
.c-exercise-manager__tool-graphic--floorball-third                                              { background-image: url(./assets/icons/tools/floorball-third.svg); }

.c-exercise-manager__help-link {
	position:absolute;
	bottom:20px;
	right:20px;
}

@media only screen and (min-width:1000px) {
	.c-exercise-manager__tools {
		padding-bottom:15px;
		margin-bottom:15px;
		border-bottom:1px solid var(--color-epsilon);
	}
	.c-exercise-manager__tool {
		margin-bottom:13px;
		display:flex;
		width:auto;
		text-align:left;
	}
	.c-exercise-manager__tool-graphic {
		margin:1px 15px 1px 1px;
		flex-shrink:0;
	}
	.c-exercise-manager__tool-text {
		display:inline-block; /* IE10 */
		flex-grow:1;
		align-self:center;
	}
	.c-exercise-manager__help-link {
		position:static;
	}
}

/*
 * Text tool
 *
 * A fully transparent input placed above the canvas, for the user to write in
 */
.c-exercise-manager__text-input[class] {
	position:absolute;
	display:block;
	padding:0;
	font:normal 24px/24px Helvetica, Arial, sans-serif;
	background-color:rgba(255, 255, 255, 0);
	border:none;
	color:rgb(0, 0, 0);
}

/* A span to determine the width of the text in the input */
.c-exercise-manager__text-input-companion {
	font:normal 24px/24px Helvetica, Arial, sans-serif;
	opacity:0;
	position:absolute;
	left:-1000px;
}

/* Larger and smaller text */
.c-exercise-manager__text-input--smaller[class],
.c-exercise-manager__text-input-companion--smaller {
	font-size:16px;
	line-height:16px;
}
.c-exercise-manager__text-input--larger[class],
.c-exercise-manager__text-input-companion--larger {
	font-size:32px;
	line-height:32px;
}

/* Tool bubble
 *
 * 1:Remove whitespace in the form and allow the label to fill the whole height
 * 2:Temp, remove when icons are added
----------------------------------------------------------------------------- */
.c-exercise-manager__bubble {
	position:absolute;
	margin-top:-15px;
	font:normal 18px/1 Helvetica, Arial, sans-serif; /* [2] */
	background:var(--color-iota);
	border-radius:3px;
	padding:5px;
	color:#fff;
}
.c-exercise-manager__bubble::before {
	position:absolute;
	top:100%;
	left:calc(50% - 7px);
	width:0;
	height:0;
	border-left:7px solid transparent;
	border-right:7px solid transparent;
	border-top:7px solid var(--color-iota);
	content:"";
}
.c-exercise-manager__bubble-form {
	display:inline-block;
	line-height:0; /* [1] */
	vertical-align:top;
}
.c-exercise-manager__bubble-radio {
	position:absolute;
	left:-9999px;
}

/*
 * Tool group
 */
.c-exercise-manager__bubble-tool-group {
	display:inline-block;
	margin:0 7px;
}
.c-exercise-manager__bubble-tool-group:first-child {
	margin-left:0;
}
.c-exercise-manager__bubble-tool-group:last-child {
	margin-right:0;
}

/*
 * Slider
 *
 * 1. Padding instead of margin for larger click area
 * 2. Makes height and vertical positioning consistent in Safari/Chrome/Firefox.
 *    Unfortunately it cuts off part of the thumb in Edge, but at least
 *    positioning is better.
 */

/* Input */
.c-exercise-manager__rotation-slider {
	-webkit-appearance:none;
	height:33px; /* 2 */
	padding:6px 0; /* 1 */
	margin:0 0 0 5px;
	background:transparent;
	cursor:ew-resize;
	vertical-align:top;
}
.c-exercise-manager__rotation-slider:focus {
	outline:none;
}

/* Thumb */
.c-exercise-manager__rotation-slider::-webkit-slider-thumb {
	position:relative;
	z-index:2;
	height:24px;
	width:24px;
	border-radius:100px;
	background:#3D739C url(/project/components/slider/arrows.svg) 50% 50% no-repeat;
	-webkit-appearance:none;
	margin-top:-10px;
}
.c-exercise-manager__rotation-slider::-moz-range-thumb {
	position:relative;
	z-index:2;
	height:24px;
	width:24px;
	border-radius:100px;
	background:#3D739C url(/project/components/slider/arrows.svg) 50% 50% no-repeat;
	border:none;
}
.c-exercise-manager__rotation-slider::-ms-thumb {
	position:relative;
	z-index:2;
	height:24px;
	width:24px;
	border-radius:100px;
	background:#3D739C url(/project/components/slider/arrows.svg) 50% 50% no-repeat;
	border:none;
}

/* Track */
.c-exercise-manager__rotation-slider::-webkit-slider-runnable-track {
	width:100%;
	height:4px;
	background:var(--color-kappa);
	border-radius:2px;
}
.c-exercise-manager__rotation-slider:focus::-webkit-slider-runnable-track {
	background:#002a42;
}
.c-exercise-manager__rotation-slider::-moz-range-track {
	width:100%;
	height:4px;
	background:var(--color-kappa);
	border-radius:2px;
}
.c-exercise-manager__rotation-slider:focus::-moz-range-track {
	background:#002a42;
}
.c-exercise-manager__rotation-slider::-ms-track {
	width:100%;
	height:4px;
	background:var(--color-kappa);
	border-color:transparent;
	border-width:16px 0;
	border-radius:2px;
	color:transparent;
}
.c-exercise-manager__rotation-slider:focus::-ms-track {
	background:#002a42;
}

/*
 * Tool button
 *
 * 1. Vertically center the icons
 */
.c-exercise-manager__bubble-tool {
	position:relative;
	display:inline-block;
	cursor:pointer;
	margin:0 0 0 5px;
	vertical-align:top;
	width:33px;
	height:33px;
	border-radius:3px;
	background:rgba(255, 255, 255, 0.2);
	outline:none;
	transition:background-color 0.2s ease;
	color:inherit;
	font-size:19px;
	text-align:center;
}
.c-exercise-manager__bubble-tool:disabled,
.c-exercise-manager__bubble-tool:disabled:hover {
	background:rgba(255, 255, 255, 0.1);
	color:rgba(255, 255, 255, 0.4);
}
.c-exercise-manager__bubble-tool--large {
	font-size:24px;
}
.c-exercise-manager__bubble-radio:first-child + .c-exercise-manager__bubble-tool,
.c-exercise-manager__bubble-tool:first-child {
	margin-left:0;
}
.c-exercise-manager__bubble-tool:hover,
.c-exercise-manager__bubble-tool:focus {
	background:rgba(255, 255, 255, 0.3);
}
:checked + .c-exercise-manager__bubble-tool {
	color:var(--color-delta);
}
.c-exercise-manager__bubble-tool [class*="icon-"]:not(.c-exercise-manager__bubble-tool-icon)::before {
	line-height:33px; /* 1 */
}

/*
 * Delete tool
 */
.c-exercise-manager__bubble-tool--delete:hover,
.c-exercise-manager__bubble-tool--delete:focus {
	background:var(--color-error);
}


/* Popup toolbar icons (SVG)
 *
 * 1. Center the icons horizontally and vertically
----------------------------------------------------------------------------- */
.c-exercise-manager__bubble-tool-icon {
	height:33px;
	display:flex; /* 1 */
}
.c-exercise-manager__bubble-tool-icon svg {
	width:auto;
	height:20px;
	margin:auto; /* 1 */
}
.c-exercise-manager__bubble-tool-icon path:not([fill]) {
	fill:#fff;
}
.c-exercise-manager__bubble-tool--large svg {
	height:24px;
}
:checked + .c-exercise-manager__bubble-tool .c-exercise-manager__bubble-tool-icon path:not([fill]) {
	fill:var(--color-delta);
}
.c-exercise-manager__bubble-tool-icon.is-mirrored-v {
	transform:scaleY(-1);
}
.c-exercise-manager__bubble-tool-icon.is-mirrored-h {
	transform:scaleX(-1);
}
.c-exercise-manager__bubble-tool-icon.is-mirrored-h.is-mirrored-v {
	transform:scale(-1);
}

/*
 * Size and/or colour overrides for some icons
 */
.c-exercise-manager__bubble-tool-icon--bush-size-1 svg {
	height:22px;
}
.c-exercise-manager__bubble-tool-icon--bush-size-2 svg {
	height:14px;
}
.c-exercise-manager__bubble-tool-icon--arrow-right svg {
	height:15px;
}
.c-exercise-manager__bubble-tool-icon--pole-obstacle-small svg {
	width:18px;
}
.c-exercise-manager__bubble-tool-icon--pole-obstacle-medium svg {
	width:30px;
}


/* Popup toolbar icons (CSS)
----------------------------------------------------------------------------- */

/*
 * Log diameter icons
 */
[class^="c-exercise-manager__log-size-icon"] {
	display:inline-block;
	width:22px;
	background:#fff;
	vertical-align:middle;
}
.c-exercise-manager__log-size-icon--size-1 {
	height:4px;
	vertical-align:-1px;
}
.c-exercise-manager__log-size-icon--size-2 {
	height:6px;
	vertical-align:-2px;
}
.c-exercise-manager__log-size-icon--size-3 {
	height:8px;
	vertical-align:-3px;
}
:checked + .c-exercise-manager__bubble-tool [class^="c-exercise-manager__log-size-icon"] {
	box-shadow:0 0 2px 2px rgba(0, 0, 0, 0.8);
}

/*
 * Ditch icons
 */
[class^="c-exercise-manager__ditch-icon"] {
	display:inline-block;
	width:22px;
	height:22px;
	vertical-align:middle;
}
.c-exercise-manager__ditch-icon.c-exercise-manager__ditch-icon-grass {
	background:#4d995d;
	box-shadow:0 2px 5px rgba(0, 0, 0, 0.3) inset, 0 -2px 5px rgba(255, 255, 255, 0.3) inset;
}
.c-exercise-manager__ditch-icon.c-exercise-manager__ditch-icon-mud {
	background:#5d4225;
	box-shadow:0 3px 5px #000 inset;
}
:checked + .c-exercise-manager__bubble-tool [class^="c-exercise-manager__ditch-icon"] {
	outline:2px solid var(--color-delta);
	outline-offset:-1px;
}

/*
 * Text tool
 */
.c-exercise-manager__bubble-text-tool::before {
	position:absolute;
	right:0;
	bottom:6px;
	left:0;
	text-align:center;
	font-family:Helvetica, Arial, sans-serif;
	font-weight:bold;
	font-size:14px;
	line-height:1;
	content:"A";
}
.c-exercise-manager__bubble-text-tool--medium::before {
	font-size:20px;
	bottom:3px;
}
.c-exercise-manager__bubble-text-tool--large::before {
	font-size:26px;
	bottom:3px;
}

/*
 * Player team tools, colour swatches
 */
[class^="c-exercise-manager__swatch-icon"] {
	display:inline-block;
	width:19px;
	height:19px;
	border:1px solid;
	box-shadow:0 0 rgba(0, 0, 0, 0.5) inset;
	vertical-align:-5px;
}
.c-exercise-manager__swatch-icon--black {
	border-color:#fff;
	background:#000;
}
.c-exercise-manager__swatch-icon--white {
	border-color:#000;
	background:#fff;
}
:checked + .c-exercise-manager__bubble-tool [class^="c-exercise-manager__swatch-icon"] {
	outline:2px solid var(--color-delta);
	outline-offset:-1px;
}

/*
 * Vests
 */
:checked + .c-exercise-manager__bubble-tool [class^="c-exercise-manager__vest-icon"] circle {
	fill:var(--color-delta);
}
