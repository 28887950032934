/* =============================================================================
 * Statistics box
============================================================================= */
.c-statistics-box {
	display: flex;
	flex-wrap: wrap;

	@media (--small) {
		border-bottom: 1px solid #DEDEDE;
	}
}

.c-statistics-box__item {
	flex-grow: 1;
	flex-basis: 25%;
	min-width: 160px;
	padding: 30px 10px;
	text-align: center;
	background-color: #fff;
	border: 1px solid #DEDEDE;
	margin-right: -1px;

	@media (--small) {
		flex-basis: 50%;
		border-bottom: 0;
	}
}

.c-statistics-box__heading {
	@mixin text-style-lambda;
	color: #000;
}

.c-statistics-box__number {
	@mixin text-style-epsilon;
	color: var(--color-epsilon);
	margin-top:10px;
}