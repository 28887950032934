/* =============================================================================
 * Approval buttons
============================================================================= */
.c-approval-actions {
	display:flex;
	justify-content:space-between;
	margin:30px 0 20px 0;
}
.c-approval-actions__action {
	position:relative;
	flex:1;
	margin:0 0 20px 0;
}
.c-approval-actions__action:first-child {
	margin-right:30px;
}
@media(--small) {
	.c-approval-actions {
		margin-top:15px;
	}
	.c-approval-actions__action:first-child {
		margin-right:10px;
	}
	.c-approval-actions__action span:not([class]) {
		text-indent:200%;
		overflow:hidden;
		white-space:nowrap;
		display:inline-block;
		width:0;
	}
}


/* Radio buttons
----------------------------------------------------------------------------- */
.c-approval-actions__action input {
	position:absolute;
	width:100%;
	height:100%;
	overflow:hidden;
	margin:0;
	padding:0;
	outline:0 !important;
	border:0;
	opacity:0;
}


/* Labels
 * Styled to look like buttons
----------------------------------------------------------------------------- */
.c-approval-actions__action-label {
	display:block;
	position:relative;
	padding:14px 16px 16px;
	border:1px solid;
	border-radius:5px;
	background:#fff;
	color:#000;
	font-weight:400;
	text-align:center;
	white-space:nowrap;
}
.c-approval-actions__action--approve .c-approval-actions__action-label {
	border-color:rgb(102, 188, 16);
}
.c-approval-actions__action--approve input:hover + .c-approval-actions__action-label,
.c-approval-actions__action--approve input:focus + .c-approval-actions__action-label {
	background:rgba(102, 188, 16, 0.15);
}
.c-approval-actions__action--disapprove .c-approval-actions__action-label {
	border-color:rgb(204, 75, 76);
}
.c-approval-actions__action--disapprove input:hover + .c-approval-actions__action-label,
.c-approval-actions__action--disapprove input:focus + .c-approval-actions__action-label {
	background:rgba(204, 75, 76, 0.15);
}

/*
 * Icons
 */
.c-approval-actions__action [class^="icon"] {
	margin-right:10px;
	font-size:19px;
	vertical-align:-2px;
}
.c-approval-actions__action--approve [class^="icon"] {
	color:rgb(102, 188, 16);
}
.c-approval-actions__action--disapprove [class^="icon"] {
	color:rgb(204, 75, 76);
}

/*
 * Checked state
 */
.c-approval-actions__action--approve input:checked + .c-approval-actions__action-label {
	background:rgb(102, 188, 16);
	color:#fff;
}
.c-approval-actions__action--approve input:checked:hover + .c-approval-actions__action-label,
.c-approval-actions__action--approve input:checked:focus + .c-approval-actions__action-label {
	background:rgb(82, 150, 14);
}
.c-approval-actions__action--disapprove input:checked + .c-approval-actions__action-label {
	background:rgb(204, 75, 76);
	color:#fff;
}
.c-approval-actions__action--disapprove input:checked:hover + .c-approval-actions__action-label,
.c-approval-actions__action--disapprove input:checked:focus + .c-approval-actions__action-label {
	background:rgb(166, 61, 61);
}
.c-approval-actions__action input:checked + .c-approval-actions__action-label [class^="icon"] {
	color:#fff;
}

/*
 * Arrows
 */
.c-approval-actions__action input:checked + .c-approval-actions__action-label::before,
.c-approval-actions__action input:checked + .c-approval-actions__action-label::after {
	position:absolute;
	bottom:-10px;
	left:calc(50% - 10px);
	width:20px;
	height:20px;
	background:rgb(204, 75, 76);
	content:"";
	transform:rotate(45deg);
}
.c-approval-actions__action input:checked + .c-approval-actions__action-label::after {
	bottom:-7px;
}
.c-approval-actions__action--approve input:checked + .c-approval-actions__action-label::before,
.c-approval-actions__action--approve input:checked + .c-approval-actions__action-label::after {
	background:rgb(102, 188, 16);
}
.c-approval-actions__action--approve input:checked:hover + .c-approval-actions__action-label::before,
.c-approval-actions__action--approve input:checked:focus + .c-approval-actions__action-label::before,
.c-approval-actions__action--approve input:checked:hover + .c-approval-actions__action-label::after,
.c-approval-actions__action--approve input:checked:focus + .c-approval-actions__action-label::after {
	background:rgb(82, 150, 14);
}
.c-approval-actions__action--disapprove input:checked + .c-approval-actions__action-label::before,
.c-approval-actions__action--disapprove input:checked + .c-approval-actions__action-label::after {
	background:rgb(204, 75, 76);
}
.c-approval-actions__action--disapprove input:checked:hover + .c-approval-actions__action-label::before,
.c-approval-actions__action--disapprove input:checked:focus + .c-approval-actions__action-label::before,
.c-approval-actions__action--disapprove input:checked:hover + .c-approval-actions__action-label::after,
.c-approval-actions__action--disapprove input:checked:focus + .c-approval-actions__action-label::after {
	background:rgb(166, 61, 61);
}
