/* =============================================================================
 * Unread marker
============================================================================= */
.c-unread-marker {
	display:inline-block;
	width:10px;
	height:10px;
	border-radius:50%;
	background:var(--color-epsilon);
	opacity:1;
	transition:transform 0.5s ease-in-out;
}
.c-unread-marker--disappear {
	transform:scale(0);
}
@media(--small) {
	.c-unread-marker {
		width:6px;
		height:6px;
	}
}

/* Small version
----------------------------------------------------------------------------- */
.c-unread-marker--small {
	width:6px;
	height:6px;
}


/* In legend
----------------------------------------------------------------------------- */
.c-unread-marker--legend {
	vertical-align:1px;
	margin-right:3px;
}


/* Mark as read
----------------------------------------------------------------------------- */
.c-unread-marker__mark-all-as-read {
	color:#666;
}
.c-unread-marker__mark-all-as-read-button {
	border-bottom:1px solid rgba(0, 0, 0, 0.2);
	margin-left:5px;
	line-height:1.2;
	transition:border 0.2s ease;
}
.c-unread-marker__mark-all-as-read-button:hover,
.c-unread-marker__mark-all-as-read-button:focus {
	border-bottom-color:#000;
}
