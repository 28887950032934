/* =============================================================================
 * Confirm dialog
 *
 * [1] Higher than custom select (10)
============================================================================= */

/* The modal
 ---------------------------------------------------------------------------- */
.c-confirm {
	position:fixed;
	top:0;
	right:0;
	bottom:0;
	left:0;
	display:flex;
	align-items:center;
	justify-content:center;
	background:rgba(0, 0, 0, 0);
	transition:background 0.25s ease;
	z-index: 20; /* [1] */
}
.c-confirm--appear {
	background:rgba(0, 0, 0, 0.5);
}


/* The message
 ---------------------------------------------------------------------------- */
.c-confirm__message {
	transform:scale(0.4);
	width:580px;
	opacity:0;
	transition:opacity 0.25s ease, transform 0.25s ease;
}
.c-confirm__message--appear {
	transform:scale(1);
	opacity:1;
}
@media(--small) {
	.c-confirm__message {
		min-width:0;
		width:300px;
	}
}


/* The buttons
 ---------------------------------------------------------------------------- */
.c-confirm__button {
	margin:0 10px 0 5px;
}
