/* =============================================================================
 * Discussions
============================================================================= */
.c-discussions__header {
	margin-bottom:15px;
	display:flex;
	align-items:center;
}
.c-discussions__footer {
	text-align:right;
}
.c-discussions__new-button {
	margin-right:auto;
}
.c-discussions__info {
	color:#666;
	margin:-15px 0 25px;
}
.c-discussions__info-icon {
	color:var(--color-gamma);
	font-size:17px;
	vertical-align:-2px;
	margin-right:3px;
}
@media(--small) {
	.c-discussions__header {
		display:block;
	}
	.c-discussions__new-button {
		margin-bottom:10px;
	}
}


/* Item
 *
 * [1] Enable positioning a pseudo-element of the title link.
----------------------------------------------------------------------------- */
.c-discussions__item {
	margin-bottom:20px;
	padding:10px 70px 25px;
	border:1px solid #dbdbdb;
	background:#fff;
}
.c-discussions--small .c-discussions__item {
	padding:10px 30px 15px;
}
.c-discussions--messages .c-discussions__item {
	position:relative; /* [1] */
}
@media(--small) {
	.c-discussions__item {
		padding:10px 30px 15px;
	}
}


/* Item header
 *
 * [1] We want the entire .c-discussions__item to be the positioning context.
----------------------------------------------------------------------------- */
.c-discussions__item-header {
	display:flex;
	position:relative;
	align-items:center;
	margin-bottom:30px;
	padding-bottom:10px;
	border-bottom:1px solid #dedede;
}
.c-discussions--small .c-discussions__item-header {
	margin-bottom:15px;
}
.c-discussions--messages .c-discussions__item-header {
	position:static; /* [1] */
}
@media(--small) {
	.c-discussions__item-header {
		margin-bottom:15px;
	}
}


/* Item title
----------------------------------------------------------------------------- */
.c-discussions__item-title {
	flex:1;
	margin-right:auto;
	margin-left:15px;
}
.c-discussions--messages .c-discussions__item-title {
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}
.c-discussions__item-title-link {
	font-weight:400;
	color:var(--color-gamma);
	transition:color 0.2s ease;
}
.c-discussions__item-title-link::before {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:1;
	content:"";
}
@media(--small) {
	.c-discussions__item-title {
		margin-left:0;
	}
}


/* New item
----------------------------------------------------------------------------- */
.c-discussions__new {
	display:inline-block;
	padding:4px 6px;
	border-radius:2px;
	background:var(--color-delta);
	font-weight:normal;
	font-size:12px;
	line-height:1;
	text-transform:uppercase;
}
.c-discussions--messages .c-discussions__new {
	margin-left:15px;
}
@media(--small) {
	.c-discussions__new:first-child {
		margin-right:10px;
	}
}


/* Comment counter
----------------------------------------------------------------------------- */
.c-discussions__comment-counter [class^="icon-"] {
	margin-right:5px;
	font-size:12px;
	vertical-align:-1px;
}


/* Excerpt
 *
 * [1] There might be links in the excerpts, style them like regular text since
 *     the poster-link will overlay it wont be followed anyway (unless you tab
 *     here, we'll have to live with that)
----------------------------------------------------------------------------- */
.c-discussions__excerpt {
	display:flex;
	position:relative;
	align-items:flex-start;
}
.c-discussions__excerpt-body {
	flex:1;
}
.c-discussions__excerpt-body a:link,
.c-discussions__excerpt-body a:visited {
	color:inherit; /* [1] */
}
.c-discussions__poster-link {
	font-weight:600;
	transition:color 0.2s ease;
}
.c-discussions__poster-link::before {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	content:"";
}


/* Portrait & admin
----------------------------------------------------------------------------- */
.c-discussions__portrait-wrap {
	position:relative;
}
.c-discussions__portrait {
	position:relative;
}
.c-discussions__excerpt .c-discussions__portrait {
	flex:none;
	margin-right:35px;
}
.c-discussions--small .c-discussions__excerpt .c-discussions__portrait {
	margin-right:20px;
}
.c-discussions__portrait-image {
	border-radius:50%;
	width:100px;
}
.c-discussions__item-header .c-discussions__portrait-image {
	width:30px;
}
.c-discussions__portrait-badge {
	position:absolute;
	bottom:0;
	right:0;
	box-shadow:0 0 0 2px #fff;
}
.c-discussions__portrait-mini-badge {
	position:absolute;
	bottom:-3px;
	right:-3px;
	transform:scale(0.5);
	transform-origin:bottom right;
	box-shadow:0 0 0 2px #fff;

}
.c-discussions--small .c-discussions__portrait-badge {
	bottom:-5px;
	right:-5px;
	transform:scale(0.85);
	transform-origin:bottom right;
}
.c-discussions--small .c-discussions__portrait-image {
	width:25px;
}
.c-discussions--small .c-discussions__excerpt .c-discussions__portrait-image {
	width:50px;
}
@media(--small) {
	.c-discussions__excerpt .c-discussions__portrait,
	.c-discussions--small .c-discussions__excerpt .c-discussions__portrait{
		margin-right:10px;
	}
	.c-discussions__item-header .c-discussions__portrait-image {
		display:none;
	}
	.c-discussions__excerpt .c-discussions__portrait-image,
	.c-discussions--small .c-discussions__excerpt .c-discussions__portrait-image {
		width:25px;
	}
	.c-discussions__portrait-badge,
	.c-discussions--small .c-discussions__portrait-badge {
		bottom:-5px;
		right:-5px;
		transform:scale(0.6);
		transform-origin:bottom right;
	}
}


/* Portraits
----------------------------------------------------------------------------- */
.c-discussions__portraits > * {
	position:relative;
	margin-left:-8px;
	box-shadow:0 0 0 2px #fff;
}
.c-discussions__portraits > *:first-child {
	margin-left:0;
}
.c-discussions__portrait--more-indicator {
	display:inline-block;
	min-width:25px;
	min-height:25px;
	line-height:25px;
	background:#dbdcdb;
	color:#616161;
	font-weight:400;
	vertical-align:middle;
	text-align:center;
}
@media(--small) {
	.c-discussions__portraits {
		display:none;
	}
}

/* Unread marker
----------------------------------------------------------------------------- */
.c-discussions__item .c-discussions__unread-marker {
	position:absolute;
	top:45px;
	left:-35px;
}
.c-discussions--small .c-discussions__unread-marker {
	top:22px;
	left:-18px;
}
.c-discussions--messages .c-discussions__unread-marker {
	top:8px;
}
@media(--small) {
	.c-discussions__item .c-discussions__unread-marker {
		left:-18px;
		top:10px;
	}
	.c-discussions--small .c-discussions__unread-marker {
		top:10px;
	}
}


/* Meta
----------------------------------------------------------------------------- */
.c-discussions__item-meta {
	margin-top:20px;
}
.c-discussions--small .c-discussions__item-meta {
	margin-top:10px;
}
.c-discussions__item-date {
	@mixin text-style-nu;
	color:#666;
}
@media(--small) {
	.c-discussions__item-meta {
		margin-top:10px;
	}
}
