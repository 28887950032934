/* =============================================================================
 * Levels
============================================================================= */
.c-levels {
	margin-bottom: 5px;
}

.c-levels__level {
	margin-right: 5px;
	width: 10px;
	height: 10px;
	background: #f2f2f2;
}

.c-levels__level:last-child {
    margin-right: 0;
}

.c-levels__level--active.c-levels__level--1 {
	background: #00ADEE;
}

.c-levels__level--active.c-levels__level--2 {
	background: #0071BB;
}

.c-levels__level--active.c-levels__level--3 {
	background: #00A99D;
}

.c-levels__level--active.c-levels__level--4 {
	background: #00707E;
}

.c-levels__level--active.c-levels__level--5 {
	background: #00707E;
}

.c-levels__level--active.c-levels__level--6 {
	background: #a9a9a9;
}

.c-levels__level--active.c-levels__level--7 {
	background: #a9a9a9;
}

.c-levels__level--active.c-levels__level--8 {
	background: #a9a9a9;
}

.c-levels__level--active.c-levels__level--9 {
	background: #a9a9a9;
}

.c-levels__level--active.c-levels__level--10 {
	background: #a9a9a9;
}

.c-levels__level--active.c-levels__level--11 {
	background: #a9a9a9;
}

.c-levels__level--active.c-levels__level--12 {
	background: #a9a9a9;
}

.c-levels__level--active.c-levels__level--13 {
	background: #a9a9a9;
}

.c-levels__level--active.c-levels__level--14 {
	background: #a9a9a9;
}

.c-levels__level--active.c-levels__level--15 {
	background: #a9a9a9;
}

.c-levels__level--active.c-levels__level--16 {
	background: #a9a9a9;
}

.c-levels__level--active.c-levels__level--17 {
	background: #a9a9a9;
}

.c-levels__level--active.c-levels__level--18 {
	background: #a9a9a9;
}
.c-levels__level--active.c-levels__level--19 {
	background: #a9a9a9;
}
.c-levels__level--active.c-levels__level--20 {
	background: #a9a9a9;
}
.c-levels__level--active.c-levels__level--21 {
	background: #a9a9a9;
}
.c-levels__level--active.c-levels__level--22 {
	background: #a9a9a9;
}
.c-levels__level--active.c-levels__level--23 {
	background: #a9a9a9;
}
.c-levels__level--active.c-levels__level--24 {
	background: #a9a9a9;
}
.c-levels__level--active.c-levels__level--25 {
	background: #a9a9a9;
}
.c-levels__level--active.c-levels__level--26 {
	background: #a9a9a9;
}
.c-levels__level--active.c-levels__level--27 {
	background: #a9a9a9;
}
.c-levels__level--active.c-levels__level--28 {
	background: #a9a9a9;
}
.c-levels__level--active.c-levels__level--29 {
	background: #a9a9a9;
}
.c-levels__level--active.c-levels__level--30 {
	background: #a9a9a9;
}
/* Large
---------------------------------------------------------------------------- */
.c-levels--large .c-levels__level {
	width:20px;
	height:20px;
	margin-right:1px;
}

/* Print
---------------------------------------------------------------------------- */
@media print {
	.c-levels__level {
		border:1px dotted grey;
	}
	.c-levels__level--active {
		border:1px solid black;
	}
	.c-levels--large .c-levels__level {
		margin:3px;
	}
}
