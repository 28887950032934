/* =============================================================================
 * Status icon
============================================================================= */
.c-status-icon {
	font-size:20px;
	color:#aeaeae;
}
.c-status-icon--ongoing {
	color:var(--color-ongoing);
}
.c-status-icon--approved {
	color:var(--color-approved);
}
.c-status-icon--rejected {
	color:var(--color-rejected);
}
.c-status-icon--underreview {
	color:var(--color-underreview);
}
.c-status-icon--overdue {
	color:var(--color-overdue);
}
