/* =============================================================================
 * Distribution percentage
============================================================================= */
.c-distribution-percentage input[type="number"] {
	min-width:5em;
}
.c-distribution-percentage thead tr:nth-child(2) th:nth-child(2),
.c-distribution-percentage thead tr:nth-child(2) th:nth-child(3) {
	width:25%;
}
.js .c-distribution-percentage thead tr:nth-child(2) th:nth-child(2),
.js .c-distribution-percentage thead tr:nth-child(2) th:nth-child(3) {
	width:17%;
}
.js .c-distribution-percentage thead tr:nth-child(2) th:nth-child(4),
.js .c-distribution-percentage thead tr:nth-child(2) th:nth-child(5) {
	width:12%;
}
.c-distribution-percentage__percentage {
	@mixin text-style-theta;
	vertical-align:middle;
}
