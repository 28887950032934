/* =============================================================================
* WYSIWYG textarea
============================================================================= */
.c-wysiwyg-textarea--high {
	height:200px;
}


/* TinyMCE skin overrides
----------------------------------------------------------------------------- */
.mce-flow-layout-item[class] {
	margin:0;
}
.mce-panel[class] {
	background:#fff;
}
.mce-toolbar-grp[class] {
	padding:5px;
}
.mce-edit-area[class] {
	border-top-color:#fff;
}
.mce-path-item[class],
.mce-wordcount[class] {
	color:#767676;
}
.mce-statusbar[class] {
	margin:0 10px;
	border-top-color:#eee;
}
.mce-btn-group[class]:not(:first-child) {
	border-left-color:#eee;
}
.mce-path[class] {
	padding-left:0;
}
.mce-container-body .mce-resizehandle[class] {
	right:-10px;
}
.mce-toolbar .mce-btn {
	background-color:#fff;
}
.mce-ico[class] {
	color:#999;
}
.mce-toolbar-grp:hover .mce-ico[class] {
	color:#555;
}
.mce-btn.mce-disabled[class] button, .mce-btn.mce-disabled[class]:hover button {
	opacity:0.25;
}

/* Make TinyMCE lightboxes a bit nicer
----------------------------------------------------------------------------- */
.mce-window-head {
	background: #fff !important;
}

.mce-window-body {
	background: #fff !important;
}

.mce-foot > .mce-container-body {
	padding: 10px !important;
}

.mce-panel {
	max-width: 100% !important;
}

.mce-container {
	max-width: 100% !important;
	height: auto !important;
}

.mce-container-body {
	max-width: 100% !important;
	height: auto !important;
}

.mce-form {
	padding: 10px !important;
}

.mce-tabs {
	max-width: 100% !important;
}

.mce-formitem {
	margin: 10px 0 !important;
}

.mce-abs-layout-item {
	position: static !important;
	width: auto !important;
}

.mce-abs-layout-item.mce-label {
	display: block !important;
}

.mce-abs-layout-item.mce-textbox,
.mce-abs-layout-item .mce-textbox {
	-webkit-box-sizing: border-box !important;
	-moz-box-sizing: border-box !important;
	box-sizing: border-box !important;
	display: block !important;
	width: 100% !important;
	padding:4px 10px !important;
}

.mce-abs-layout-item.mce-combobox {
	display: flex !important;
}

.mce-abs-layout-item.mce-combobox > .mce-textbox {
	-ms-flex: 1 1 auto;
	-webkit-flex: 1 1 auto;
	flex: 1 1 auto;
	height: 29px !important;
}

@media(--small) {
	.mce-window {
		width: auto !important;
		top: 0 !important;
		left: 0 !important;
		right: 0 !important;
		bottom: 0 !important;
		background: none !important;
	}
}
