/* Custom select
-------------------------------------------------------------- */
/* Wrapper element to enable positioning */
.c-custom-select {
	display:block;
	position:relative;
	vertical-align:middle;
}
.c-custom-select--block {
	display:block;
}
.c-custom-select--inline-block {
	display:inline-block;
}

/*
 * Set the height of the select element to match the replacing span element
 * and make it transparent.
 */
.c-custom-select__original-select {
	display:block;
	position:absolute;
	z-index:10;
	width:100%;
	height:40px;
	border:0;
	line-height:40px;
	opacity:0;
	filter:alpha(opacity=0);
}

/* The span elements that are inserted underneath the real select */
.c-custom-select__fake-select {
	display:block;
	position:relative;
	z-index:1;
}
.c-custom-select__fake-select-inner {
	display:block;
	position:relative;
	padding:0 40px 0 20px;
	border:1px solid var(--color-gray);
	border-radius:3px;
	background:#fff;
	transition-property:border;
}
.c-custom-select__fake-select-inner::after {
	position:absolute;
	top:50%;
	right:15px;
	color:var(--color-beta);
	font-size:10px;
	font-family:"icomoon";
	content:"\e903";
	transform:translateY(-50%);
}
.c-custom-select__icon {
	position:absolute;
	top:50%;
	right:20px;
	transform:translateY(-50%);
}
.c-custom-select__fake-select-value {
	display:block;
	height:38px;
	overflow:hidden;
	line-height:38px;
	text-overflow:ellipsis;
	white-space:nowrap;
}
.c-custom-select__fake-select--active .c-custom-select__fake-select-inner {
	border-color:#a6a6a6;
	background:#fafafa;
}
.c-custom-select__fake-select--active .c-custom-select__icon path {
	fill:var(--color-alpha);
}
.c-custom-select__original-select:disabled + .c-custom-select__fake-select .c-custom-select__fake-select-inner {
	filter:brightness(75%);
	opacity:0.5;
	cursor:not-allowed;
}
