/* =============================================================================
 * Hidden
 *
 * Hidden visually, but screen readers will read it.
============================================================================= */
.t-hidden,
.js .t-hidden--js {
    display: none;
}

.t-hidden--keep-layout,
.js .t-hidden--keep-layout.t-hidden--js {
    visibility: hidden;
}
@media print {
	.t-hidden--print {
		display:none;
	}
}
