/* =============================================================================
 * Form control
============================================================================= */
.c-form-control {
	margin-bottom:30px;
}
.c-form-control--tight {
	margin-bottom:5px;
}
.c-form-control--inline {
	display:inline-block;
	margin-right:5px;
}
.c-form-control--inline-label {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	gap: 10px;
}
.c-form-control--inline-label label {
	margin-bottom:0;
}
.c-form-control--inline label {
	margin-bottom:0;
}
.c-form-control__label--secondary {
	display:inline-block;
	margin-left:10px;
}
.c-form-control__label-link {
	float:right;
	color:var(--color-link);
	font-weight:200;
}
.c-form-control__label-link:hover,
.c-form-control__label-link:focus {
	color:#000;
}
.c-form-control__sub-label {
	display:block;
	font-size:0.9em;
	line-height:1.1;
	margin-top:7px;
}
.c-form-control__file-input {
	line-height:1;
}

.c-form-control--inline-label input[type='checkbox'] {
	margin-bottom: 2px;
}

/* Error
 ---------------------------------------------------------------------------- */
.c-form-control--error .c-form-control__label {
	border-color:var(--color-error);
}
.c-form-control--error .c-form-control__form-element,
.c-form-control--error .c-form-control__form-element:focus {
	border-color:var(--color-error);
	background:var(--color-error2);
}
