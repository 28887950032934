/* =============================================================================
 * Loader
============================================================================= */
.c-loader {
    position:relative;
    width:24px;
    height:24px;
    -webkit-animation:loader-rotate 1.2s infinite linear;
            animation:loader-rotate 1.2s infinite linear;
}

span.c-loader {
    display:inline-block;
}

.c-loader::before,
.c-loader::after {
    content:"";
    position:absolute;
    top:0;
    width:60%;
    height:60%;
    background-color:var(--color-beta);
    border-radius: 100%;
    -webkit-animation:loader-bounce 1.2s infinite ease-in-out;
            animation:loader-bounce 1.2s infinite ease-in-out;
}

.c-loader::after {
    content:"";
    top:auto;
    bottom:0;
   -webkit-animation-delay: -0.6s;
           animation-delay: -0.6s;
}

@-webkit-keyframes loader-rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes loader-rotate {
    100% {
        transform:rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes loader-bounce {
    0%, 100% {
        -webkit-transform: scale(0.0);
    }
    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes loader-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}


/* Loader with parent
 *
 * When used to cover an area that are being loaded
----------------------------------------------------------------------------- */
[data-loader-parent] {
    position:relative;
}

[data-loader-parent]::before {
    content:"";
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background:rgba(0, 0, 0, 0.8);
}

[data-loader-parent] .c-loader {
    position:absolute;
    left:50%;
    top:50%;
    margin-left:-20px;
    margin-top:-20px;
}

[data-loader-parent] .c-loader::before,
[data-loader-parent] .c-loader::after {
    background-color:#fff;
}
