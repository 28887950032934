.c-pagination {
	margin-bottom: 50px;
}

.c-pagination:after {
	@mixin clearfix;
}

.c-pagination__link {
	color: var(--color-link);
}

.c-pagination__link::after,
.c-pagination__link::before {
	display: inline-block;
	font-family: 'icomoon';
	font-size: 10px;
	vertical-align: middle;
}

.c-pagination__link--next {
	float: right;
}

.c-pagination__link--next::after {
	content: '\e901';
	padding-left: 10px;
}

.c-pagination__link--prev {
	float: left;
}

.c-pagination__link--prev::before {
	content: '\e902';
	padding-right: 10px;
}