/* =============================================================================
 * Header select
============================================================================= */
.c-header-select {
	border-bottom: 1px solid var(--color-zeta);
	margin-bottom: 30px;
	margin-top: 30px;

	@media (--small) {
		margin-top: 0;
	}
}

.c-header-select__row {
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	@media (--small) {
		display: block;
	}
}

.c-header-select__select-container {
	flex-grow: 1;
}

.c-header-select__label {
	@mixin text-style-theta;
	text-align: right;
	margin-right: 15px;
	margin-bottom: 4px;

	@media (--small) {
		text-align: left;
		margin-bottom: 10px;
	}
}