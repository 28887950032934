/* =============================================================================
 * Links
============================================================================= */
a {
	text-decoration:none;
}
a:visited {
	/*color:#767676;*/
}
a:hover,
a:focus {
	text-decoration:none;
}

.e-styled-link {
	color: var(--color-link);
}