/* =============================================================================
 * Responsive iframe object
 *
 * 1. Aspect ratio 16:9
 * 2. Aspect ratio 4:3
============================================================================= */

.o-responsive-iframe-wrapper {
    overflow: hidden;
    position: relative;
}

.o-responsive-iframe-wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
 }

 
 /* Aspect ratios */
.o-responsive-iframe-wrapper-16-9 {
    padding-top: 56.25%; /* 1 */
}

.o-responsive-iframe-wrapper-4-3 {
    padding-top: 75%; /* 2 */
}

