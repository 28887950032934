.t-padding-0 {
	padding: 0 !important;
}
.t-padding-10 {
	padding: 10px !important;
}
.t-padding-30 {
	padding: 30px !important;
}

.t-padding-top-15 {
	padding-top: 15px !important;
}
.t-padding-top-25 {
	padding-top: 25px !important;
}
.t-padding-top-20 {
	padding-top: 20px !important;
}
.t-padding-top-22-large {
	@media (--lg) {
		padding-top: 22px !important;
	}
}
.t-padding-right-20 {
	padding-right: 20px !important;
}

.t-padding-bottom-100 {
	padding-bottom: 100px !important;
}
.t-padding-bottom-50 {
	padding-bottom: 50px !important;
}
.t-padding-bottom-30 {
	padding-bottom: 30px !important;
}
.t-padding-bottom-25 {
	padding-bottom: 25px !important;
}
.t-padding-bottom-15 {
	padding-bottom: 15px !important;
}
.t-padding-bottom-10 {
	padding-bottom: 10px !important;
}

.t-padding-vertical-spacing-10 {
	padding: var(--spacing--vertical-spacing-unit) 10px !important;
}

@media(--medium) {
	.t-padding-vertical-spacing-10 {
		padding: calc(var(--spacing--vertical-spacing-unit-mobile)) 10px !important;
	}
}


.t-padding-top-vertical-space {
	padding: calc(var(--spacing--vertical-spacing-unit) * 0.5) 0;

	@media (--medium) {
		padding: calc(var(--spacing--vertical-spacing-unit-mobile) * 0.5) 0;
	}
}

.t-padding-bottom-vertical-space {
	padding: calc(var(--spacing--vertical-spacing-unit) * 0.5) 0;

	@media (--medium) {
		padding: calc(var(--spacing--vertical-spacing-unit-mobile) * 0.5) 0;
	}
}
