/* =============================================================================
 * Exercise
 *
 * 1. Lower z-index than the toggler
============================================================================= */
.c-exercise {
	display:flex;
	margin-bottom:40px;
	justify-content:space-around;
}
.c-exercise__field {
	margin-left:20px;
	flex-shrink:0;
}
.c-exercise__content {
	position: relative;
	padding:20px;
	border-radius:4px;
	background:#fff;
	border:1px solid #dbdbdb;
	width: 100%;
	max-width:800px;
}

.c-exercise--fieldless .c-exercise__content {
	flex-shrink:0;
}

.c-exercise__name {
	@mixin text-style-epsilon;
	padding-left:30px;
	padding-right:30px;
}
@media(--medium-plus) {
	.c-exercise:not(.c-exercise--fieldless) {
		position:relative;
		display:block;
	}
	.c-exercise:not(.c-exercise--fieldless) .c-exercise__content {
		position:absolute;
		top:50px;
		left:50px;
		max-width:450px;
		z-index:1; /* 1 */
		box-shadow:0 0 4px rgba(0, 0, 0, 0.5);
		border:none;
	}
	.c-exercise__content[aria-hidden="true"] {
		display:none;
	}
	.c-exercise__field {
		margin:0;
	}
	.c-exercise__field-image {
		width:100%;
		max-width:none;
	}
}
@media(--small) {
	.c-exercise:not(.c-exercise--fieldless) .c-exercise__content {
		top:auto;
		left:auto;
		right:auto;
		max-width:none;
		position:relative;
	}
	.c-exercise__field--absolute {
		position:absolute;
		top:0;
	}
}

/* =============================================================================
 * Connect to author
 *
 * 1. Compensate for the parent padding
============================================================================= */
@keyframes slideDown {
	from {
		transform:translateY(-100%); 
	}

	to {
		transform:translateY(0);
	}
}

.c-exercise__connect {
	margin-top:25px;
}

.js .c-exercise__connect {
	position:absolute;
	top:-20px;/* 1 */
	right:-20px;/* 1 */
	left:-20px;/* 1 */
	padding-bottom:40px;
	margin-top:0;
	overflow:hidden;
}

.js .c-exercise__connect-animated-wrapper.isOpening  {
	animation:slideDown 0.2s ease-in-out forwards;
}

.js .c-exercise__connect-animated-wrapper.isClosing  {
	animation:slideDown 0.2s ease-in-out reverse;
}

.c-exercise__connect-content {
	position:relative;
	padding:25px 50px;
	background:var(--color-beta);
	z-index:2;
}

.js .c-exercise__connect-content {
	display:none;
}

.js .c-exercise__connect-content.isVisible {
	display:block;
}

.c-exercise__connect-btn {
	position:absolute;
	right:0;
	padding:2px 4px;
	color:#fff;
}

.c-exercise__connect-btn:after {
	content:"";
	position:absolute;
	top:0;
	right:0;
	width:40px;
	height:40px;
	background:linear-gradient(to bottom left, var(--color-beta) 50%, transparent 10%);
}

.c-exercise__connect-icon {
	position:relative;
	display:inline-block;
	transform:rotate(-90deg);
	z-index:1;
}

.c-exercise__connect-label {
	color:#fff;
}

/* Author logotype
---------------------------------------------------------------------------- */
.c-exercise__author-logo {
	position:absolute;
	top:0;
	right:0;
}
@media(--small) {
	.c-exercise__author-logo {
		transform:scale(0.75);
		transform-origin:top right;
	}
}

/* Scrollable content area
---------------------------------------------------------------------------- */
@media(--medium-plus) {
	.c-exercise__content--scrollable {
		bottom:50px;
	}
	.c-exercise__content--scrollable::after {
		content:"";
		position:absolute;
		bottom:0;
		left:0;
		right:0;
		height:120px;
		background:linear-gradient(rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 1) 100%);
	}
	.c-exercise__content--scrollable .c-exercise__info {
		overflow-y:scroll;
		padding-bottom:80px;
		-webkit-overflow-scrolling:touch;
	}

	.c-exercise__content--scrollable .c-exercise__info .c-teaser {
		margin-top: 0;
	}

	.c-exercise__content--scrollable .c-exercise__info::-webkit-scrollbar-track {
		background-color: transparent;
	}
	
	.c-exercise__content--scrollable .c-exercise__info::-webkit-scrollbar {
		width: 3px;
		background-color: transparent;
	}
	
	.c-exercise__content--scrollable .c-exercise__info::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #aaa;
	}

	.c-exercise__content--scrollable .c-exercise__text {
		margin-right:10px;
	}
}

/* Content toggler
 *
 * 1. Size of icon
 * 2. To vertically align the icon
 * 2. Higher z-index than the content
---------------------------------------------------------------------------- */
.c-exercise__content-toggler {
	position:absolute;
	top:23px;
	left:23px;
	width:54px;
	height:54px;
	box-shadow:0 2px 2px rgba(0, 0, 0, 0.5);
	border-radius:50%;
	color:#fff;
	background:var(--color-beta);
	font-size:20px; /* 1 */
	line-height:58px; /* 2 */
	z-index:3; /* 3 */
}
@media(--small) {
	.c-exercise__content-toggler {
		top:-27px;
		margin-left:-27px;
		left:50%;
	}
}

/* Content header and heading
---------------------------------------------------------------------------- */
.c-exercise__content-header {
	position:relative;
	z-index:1;
	padding:20px 0;
	border-bottom:1px solid #d9d9d9;
	margin-bottom: calc(var(--spacing--vertical-spacing-unit) * 0.5);
	text-align:center;
}
.c-exercise__name {
	margin-bottom:15px;
}
@media(--small) {
	.c-exercise__content-header {
		margin-bottom: calc(var(--spacing--vertical-spacing-unit-mobile) * 0.5);
	}
}

/* Print
---------------------------------------------------------------------------- */
@media print {
	.c-exercise {
		display:block;
	}
	.c-exercise__content-toggler {
		display:none;
	}
	.c-exercise__info {
		height:auto!important;
	}
	.c-exercise__content {
		display:block !important;
		position:static!important;
		padding:0!important;
		border:none!important;
		max-width:none!important;
		box-shadow:none!important;
	}
	.c-exercise__author-logo {
		display:none;
	}
	.c-exercise__content-header {
		border:none;
		text-align:left;
		padding:0;
	}
	.c-exercise__name {
		padding:0;
	}
	.c-exercise__field {
		max-width:50%;
		max-height:100%;
		float:right;
		margin-left:1cm;
		margin-bottom:1cm;
	}
}
