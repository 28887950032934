/* Resets
----------------------------------------------------------------------------- */
.flexslider .slides > li {
	display: none;
	-webkit-backface-visibility: hidden;
}

.flexslider .slides img {
	width: 100%;
	display: block;
}

.slides:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

html[xmlns] .slides { display: block; }
* html .slides { height: 1%; }

@media print {
	.flexslider .slides {
		transform:translate3d(0px, 0px, 0px) !important;
		width:100% !important;
	}
	.flexslider .slides > li {
		display:none !important;
		width:100% !important;
	}
	.flexslider .slides .flex-active-slide {
		display:block !important;
	}
}

/* Container
----------------------------------------------------------------------------- */
.flexslider {
	position: relative;
	zoom: 1;
}

/* Pagination
----------------------------------------------------------------------------- */
.flex-control-nav {
	text-align: center;
	margin-top: 19px;
}

.flex-control-nav li {
	display: inline-block;
	margin-right: 10px;
}

.flex-control-nav li:last-child {
	margin-right: 0;
}

.flex-control-paging li a {
	width: 20px;
	height: 20px;
	display: block;
	cursor: pointer;
	text-indent: -9999px;
	border: 1px solid var(--color-link);
	border-radius: 50%;
	position: relative;
	overflow: hidden;
}

.flex-control-paging li a.flex-active::after {
	content: '';
	width: 10px;
	height: 10px;
	display: block;
	border-radius: 50%;
	background: var(--color-accent);
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

@media print {
	.flex-control-nav {
		display:none;
	}
}

/* Arrows
----------------------------------------------------------------------------- */
.flex-direction-nav a {
	display: block;
	position: absolute;
	width: 68px;
	height: 68px;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	border: 1px solid var(--color-link);
	color: var(--color-link);
	cursor: pointer;
	font-size: 0;
}

@media(--medium) {
	.flex-direction-nav a {
		display: none;
	}
}

.flex-direction-nav a:before {
	font-family: 'icomoon';
	font-size: 15px;
	text-indent: initial;
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.flex-prev {
	left: -68px;
}

.flex-next {
	right: -134px;
}

.flex-prev:before {
	content: '\e902';
}

.flex-next:before {
	content: '\e901';
}
