/* =============================================================================
 * Combo box
 * 1. Left & right -1px makes the borders overlap.
============================================================================= */
.c-combo-box[class] {
	position:relative;
	border:1px solid #e3e3e3;
	background-color:#fff;
	position:relative;
	transition:all 0.16s ease;
	border-radius:3px;
}

.c-combo-box::after {
	position:absolute;
	top:50%;
	right:15px;
	color:var(--color-beta);
	font-size:10px;
	font-family:"icomoon";
	content:"\e903";
	transform:translateY(-50%);
	pointer-events:none;
}

.c-combo-box__inner {
	display:block;
	width:90%;
	color:#000;
	font-size:14px;
	line-height:1.2;
}

.c-combo-box__content {
	display:none;
	background-color:#fff;
	border:1px solid #e3e3e3;
	border-top-width:0;
	position:absolute;
	padding:12px;
	z-index:100;
	left:-1px; /* [1] */
	right:-1px; /* [1] */
	outline:0;
}

.c-combo-box__content--border-top {
	border-top-width:1px;
}

.c-combo-box__trigger[class] {
	width:100%;
	text-align:left;
	padding:12px 20px;
}

.c-combo-box__checkbox {
	display:flex;
}

.c-combobox__checkbox-input {
	margin-right:5px;
	margin-top:8px;
}

.c-combobox__checkbox-label {
	margin: 0;
	line-height: 1;
	width:100%;
	padding:7px 0;
	cursor:pointer;
	user-select:none;
}
