.c-create-exercise {
	position: relative;
	display: flex;
	justify-content: center;
	flex-grow: 1;
	background: #f4f4f4;
	border: 2px dashed #cacaca;
	padding: 100px 15px;
	margin: 0 0 calc(var(--spacing--vertical-spacing-unit) * 0.5);
	width:100%;
	transition: background-color 0.15s ease;
}

@media(--small) {
	.c-create-exercise {
		margin: 0 0 calc(var(--spacing--vertical-spacing-unit-mobile) * 0.5);
		padding: 20px 15px;
	}
}

.c-create-exercise--tight {
	margin:0;
	padding:20px 15px;
}

.c-create-exercise:hover,
.c-create-exercise:focus {
	border-color:#aaa;
	background: #fafafa;
}

.c-create-exercise__inner {
	align-self: center;
	text-align: center;
	margin:auto;
}

.c-create-exercise__heading {
	@mixin text-style-theta;
	color: var(--color-alpha);
	font-size: 20px;
}

@media (--medium) {
	.c-create-exercise__heading {
		font-size: 16px;
	}
}

.c-create-exercise__icon {
	display: inline-block;
	margin-bottom: 5px;
}

/* Print
 ---------------------------------------------------------------------------- */
@media print {
	.c-create-exercise {
		display:none;
	}
}
