/* =============================================================================
 * DOUGHNUT CHART
================================================================================ */
.c-doughnut-chart {
	position:relative;
}
.c-doughnut-chart--small {
	max-width:80px;
}
.c-doughnut-chart--regular {
	max-width:100px;
}
.c-doughnut-chart__text {
	position:absolute;
	top:0;
	display:flex;
	align-items:center;
	height:100%;
	width:100%;
	font-size: 150%;
	font-weight: 600;
}
.c-doughnut-chart--empty .c-doughnut-chart__text,
.c-doughnut-chart--full .c-doughnut-chart__text {
	font-size: 200%;
}
.c-doughnut-chart--empty .c-doughnut-chart__text {
	color:var(--new-color-blue);
}
.c-doughnut-chart--full .c-doughnut-chart__text {
	color:var(--new-color-forrest-green);
}
.c-doughnut-chart__text span {
	width:100%;
	text-align:center;
	font-weight:bold;
}
.c-doughnut-chart__circle {
	display: block;
	width: 100%;
}
.c-doughnut-chart__circle .circle-bottom {
	stroke: var(--new-color-grey);
	fill: none;
	stroke-width: 3;
}
.c-doughnut-chart__circle .circle {
	stroke: var(--new-color-forrest-green);
	fill: none;
	stroke-width: 3;
	stroke-linecap: round;
}
.progress {
	animation: progress 1s ease-out forwards;
}
@keyframes progress {
	0% {
		stroke-dasharray: 0 100;
	}
}