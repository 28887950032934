/* =============================================================================
 * Action header
============================================================================= */
.c-action-header {
	display:flex;
	align-items:center;
	margin-bottom:20px;
}

/* Columns
---------------------------------------------------------------------------- */
.c-action-header__col {
	flex-grow:1;
	flex-shrink:1;
}
.c-action-header__col--back,
.c-action-header__col--forward {
	width:220px;
	flex-grow:0;
	flex-shrink:0;
}
.c-action-header__col--forward {
	text-align:right;
}
@media(--small) {
	.c-action-header {
		flex-wrap:wrap;
	}
	.c-action-header__col--heading {
		order:1;
		width:100%;
		margin-bottom:20px;
	}
	.c-action-header__col--back {
		order:2;
		width:auto;
		flex-grow:1;
	}
	.c-action-header__col--forward {
		order:2;
		width:auto;
		text-align:left;
	}
}

/* Heading
---------------------------------------------------------------------------- */
.c-action-header__heading {
	text-align:center;
	@mixin text-style-epsilon;
}
