/* =============================================================================
 * Stripe
============================================================================= */
.c-stripe {
	display:flex;
	align-items: center;
	background:#fff;
	border:1px solid #dbdbdb;
	box-shadow:0 0 4px rgba(0, 0, 0, 0.07);
	margin-bottom:10px;
}
.c-stripe__block-link {
	display:flex;
	flex-grow:1;
}
.c-stripe__block-link:hover,
.c-stripe__block-link:focus {
	box-shadow:0 0 4px rgba(0, 0, 0, 0.1);
}
.c-stripe__symbol {
	margin:9px 35px 9px 20px;
	display:flex;
	align-items:center;
}
@media(--small) {
	.c-stripe__symbol {
		display:none;
	}
}

/* Info (the text)
---------------------------------------------------------------------------- */
.c-stripe__info {
	margin-top:15px;
	margin-right:auto;
	margin-bottom:15px;
}
.c-stripe__info:first-child {
	margin-left:20px;
}
.c-stripe__name {
	@mixin text-style-eta;
	color:var(--color-gamma);
}
.c-stripe__video {
	display:inline-block;
	font-size:17px;
	margin-left:5px;
	color:var(--color-epsilon);
}
.c-stripe__new-comment {
	font-size:14px;
	margin-left:4px;
	color:var(--color-epsilon);
}
.c-stripe__description {
	color:#000;
}
.c-stripe__messages {
	@mixin text-style-pi;
	font-weight: 600;
	color: #0068A6;
	margin-right: 30px;

	@media (--small) {
		margin: 0 10px;
	}
}

.c-stripe__message {
	margin-left: 15px;
	display: inline-block;
	min-width: 126px;

	@media (--medium) {
		display: block;
		margin: 5px 0;
		min-width: none;
		text-align: right;
	}
}

.c-stripe--levels .c-stripe__description {
	margin-bottom:3px;
}
.c-stripe--levels .c-stripe__name {
	margin-bottom:3px;
}
@media(--small) {
	.c-stripe__name {
		margin-bottom:5px;
	}
	.c-stripe__info,
	.c-stripe__info:first-child {
		margin:10px auto 10px 10px;
	}
	.c-stripe__video {
		font-size:10px;
	}
}


/* Button
---------------------------------------------------------------------------- */
.c-stripe__button {
	margin-right:20px;
	align-self:center;
	opacity:0;
	transition:all 0.2s ease;
}
.c-stripe__block-link:hover .c-stripe__button,
.c-stripe__block-link:focus .c-stripe__button {
	opacity:1;
}
@media(--medium) {
	.c-stripe__button {
		display:none;
	}
}


/* Right
 *
 * 1. Different value because custom checkbox has a padding greater than
 *    the width of the actual checkbox.
---------------------------------------------------------------------------- */
.c-stripe__right {
	border-left:1px solid #dbdbdb;
	align-self:stretch;
	padding:0 30px;
	display:flex;
	align-items:center;
	justify-content:center;
}
.c-stripe__right--crooked {
	padding:0 20px 0 30px; /* 1 */
}
@media(--small) {
	.c-stripe__right {
		padding-right:20px;
		padding-left:20px;
	}
	.c-stripe__right--crooked {
		padding-right:10px;
	}
}

/* Sorting
---------------------------------------------------------------------------- */
.c-stripe__sorting {
	margin-left:auto;
	display:flex;
	align-self:stretch;
	align-items:stretch;
	flex-shrink:0;
}
@media(--small) {
	.c-stripe__sorting {
		flex-direction:column;
	}
}
.c-stripe__sort-button {
	border-left:1px solid #dbdbdb;
	display:flex;
	align-items:center;
	padding:0 25px;
	text-align:center;
	font-size:16px;
	outline:none;
	background:linear-gradient(#fff, #e4e4e4);
}
@media(--small) {
	.c-stripe__sort-button {
		flex-grow:1;
		padding:10px;
	}
}
.c-stripe__sort-button--up {
	border-left-color:#c6c6c6;
}
.c-stripe__sort-button:not([disabled]):hover,
.c-stripe__sort-button:not([disabled]):focus {
	background:linear-gradient(#fff, #ddd);
}
.c-stripe__sort-button[disabled] {
	color:rgba(0, 0, 0, 0.25);
}

/* Compact variant
---------------------------------------------------------------------------- */
.c-stripe--compact {
	background-color: #F7F7F7;
	border: 0;
	box-shadow: none;
}

.c-stripe--compact .c-stripe__symbol {
	margin: 9px 15px;
	width: 19px;
}

.c-stripe--compact .c-stripe__info {
	padding: 5px 0;
}

.c-stripe--compact .c-stripe__name {
	font-size: 14px;
	text-transform: initial;
}

.c-stripe--compact .c-stripe__description {
	font-size: 12px;
	text-transform: uppercase;
	color: #505050;
	line-height: 1.1;
	margin: 5px 0;
}

.c-stripe--compact .c-stripe__description--label {
	margin: 0;
	font-size: 10px;
	color: #8C8C8C;
}


/* Print
---------------------------------------------------------------------------- */
@media print {
	.c-stripe__sorting {
		display: none;
	}
}
