/* SCROLLABLE TABLES
============================================================================================================================ */
.c-scroll-table {
	position:relative;
	overflow:hidden;
	width:100%;
	-webkit-overflow-scrolling:touch;
}
.c-scroll-table:after {
	content:'';
	position:absolute;
	top:0;
	left:100%;
	width:50px;
	height:100%;
	box-shadow:-6px 0 5px rgba(0, 0, 0, 0.32);
	border-radius:15px 0 0 15px / 50% 0 0 50%;
}
.c-scroll-table > .c-scroll-table__inner {
	width:100%;
	overflow:auto;
	overflow-x:auto;
	overflow-y:visible;
	margin:0 0 20px;
	padding:30px 0 20px;
}
.c-scroll-table > .c-scroll-table__inner > table {
	min-width:100%;
	width:auto;
	margin:0;
}