/* =============================================================================
 * File input
 *
 * Styled file input
============================================================================= */
.c-file-input {
	position:relative;
}
.js .c-file-input input[type="file"] {
	position:absolute;
	opacity:0;
	left:0;
	width:100%;
}
