.c-matrix-edit {
	padding: 0 20px;
}

/* Header
---------------------------------------------------------------------------- */
.c-matrix-edit__header {
	margin-bottom: 20px;
}

.c-matrix-edit__context-heading {
	@mixin text-style-iota;
	font-weight: 300;
	color: #757575;
	display: block;
}

.c-matrix-edit__heading {
	@mixin text-style-theta;
	margin-bottom: 15px;
}

.c-matrix-edit__header-list {
	display:flex;
	justify-content:space-between;
}

.c-matrix-edit__header-level {
	@mixin text-style-theta;
}

.c-matrix-edit__header-level--zero {
	color: #7d7bcc;
}

.c-matrix-edit__header-level--one {
	color: #00adee;
}

.c-matrix-edit__header-level--two {
	color: #0071bb;
}

.c-matrix-edit__header-level--three {
	color: #00a99d;
}

.c-matrix-edit__header-level--four {
	color: #00707e;
}

.c-matrix-edit__subheading-right {
	width: 150px;
	text-align: center;
	margin-right:8px;
}

@media (--small) {
	.c-matrix-edit__subheading-right {
		display: none;
	}
}

/* Form
---------------------------------------------------------------------------- */
.c-matrix-edit__input-wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

@media (--small) {
	.c-matrix-edit__input-wrapper {
		display: block;
	}
}

.c-matrix-edit__add-name {
	max-width: 510px;
}

.c-matrix-edit__select-level-wrapper {
	width: 160px;
	margin-left: 20px;
}

@media (--small) {
	.c-matrix-edit__select-level-wrapper {
		width: auto;
		margin-left: 0;
		margin-top: 20px;
	}
}

.c-matrix-edit__select-level-list {
	display: flex;
	justify-content: space-between;
}

@media (--small) {
	.c-matrix-edit__select-level-list {
		flex-direction: column;
	}
}

.c-matrix-edit__form-group {
	position: relative;
	padding: 15px 34px 19px;
	margin-bottom: 20px;
	background: #F2F2F2;
}

.c-matrix-edit__divider {
	width: 100%;
	height: 2px;
	background: #ccc;
}

.c-matrix-edit__divider--level-one {
	background: #00ADEE;
}

.c-matrix-edit__divider--level-two {
	background: #0071BB;
}

.c-matrix-edit__divider--level-three {
	background: #00A99D;
}

.c-matrix-edit__divider--level-four {
	background: #00707E;
}

.c-matrix-edit__video {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.c-matrix-edit__video-icon {
	float: left;
	width: 5%;
	align-self: center;
	color:var(--color-epsilon);
}

.c-matrix-edit__video-url-input {
	width: 95%;
	float: right;
}

@media (--small) {
	.c-matrix-edit__video-url-input {
		width: 90%;
	}
}

.c-matrix-edit__textarea {
	resize: none;
}

/* Footer
---------------------------------------------------------------------------- */
.c-matrix-edit__form-footer {
	margin-top: 18px;
	text-align: center;
}

.c-matrix-edit__save-button {
	color: #fff;
	background: #0096DC;
	border-radius: 30px;
	padding: 10px 20px;
	font-weight: 400;
	margin-right: 20px;
}

.c-matrix-edit__save-button-icon {
	margin-right: 10px;
}

.c-matrix-edit__cancel {
	color: #898989;
	font-weight: 400;
}
