/* =============================================================================
 * Message list
============================================================================= */
.c-message-list {
	margin-bottom:20px;
	border:1px solid #dadada;
	background:#fff;
}


/* Scrollable area
----------------------------------------------------------------------------- */
.c-message-list__body {
	position:relative;
	overflow:hidden;
}

/*
 * Shadow, displayed when the content can be scrolled
 */
.c-message-list__body--overflow-top::before,
.c-message-list__body--overflow-bottom::after {
	position:absolute;
	right:0;
	left:0;
	z-index:1;
	height:30px;
	box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
	content:"";
}
.c-message-list__body--overflow-top::before {
	top:-30px;
	border-radius:0 0 100px 100px / 0 0 100% 100%;
}
.c-message-list__body--overflow-bottom::after {
	bottom:-30px;
	border-radius:100px 100px 0 0 / 100% 100% 0 0;
}


/* Messages
----------------------------------------------------------------------------- */
.c-message-list__messages {
	position:relative;
	max-height:calc(100vh - 450px);
	min-height:300px;
	overflow:auto;
	padding:0 40px;
	-webkit-overflow-scrolling:touch;
}

@media only screen and (max-width:800px) {
	.c-message-list__messages {
		padding:0 20px;
	}
}

/*
 * Compact version
 */
.c-message-list--compact .c-message-list__messages {
	padding:0 20px;
}


/* Header
----------------------------------------------------------------------------- */
.c-message-list__header {
	position:relative;
	z-index:1;
	text-align:center;
}
.c-message-list__header-heading {
	position:relative;
	z-index:1;
	padding:15px;
	font-weight:400;
}
