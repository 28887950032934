/* =============================================================================
 * Overflow area
 *
 * Content becomes scrollable when it exceeds a certain height
============================================================================= */


/* Top level container
----------------------------------------------------------------------------- */
.c-overflow-area {
	position:relative;
	overflow:hidden;
	margin-bottom:40px;
}


/* Shadows, displayed when the content can be scrolled
----------------------------------------------------------------------------- */
.c-overflow-area--overflow-top::before,
.c-overflow-area--overflow-bottom::after {
	position:absolute;
	right:0;
	left:0;
	z-index:1;
	height:30px;
	box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
	content:"";
}
.c-overflow-area--overflow-top::before {
	top:-30px;
	border-radius:0 0 100px 100px / 0 0 100% 100%;
}
.c-overflow-area--overflow-bottom::after {
	bottom:-30px;
	border-radius:100px 100px 0 0 / 100% 100% 0 0;
}


/* Scrolling container
----------------------------------------------------------------------------- */
.c-overflow-area__scroll-area {
	max-height:270px;
	overflow-x:hidden;
	overflow-y:auto;
	-webkit-overflow-scrolling:touch;
}
.c-overflow-area--high .c-overflow-area__scroll-area {
	max-height:420px;
}
