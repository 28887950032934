.c-member-admin__filter-members {
	display:none;
}
.c-member-admin__footer {
	margin:20px 0 80px;
	text-align:center;
}
.c-member-admin__action {
	margin:0 5px 10px;
	min-width:200px;
}
.c-member-admin__invite {
	position:relative;
	padding-top:40px;
	text-align:center;
	margin-bottom:80px;
}
.c-member-admin__invite::before {
	content:"";
	position:absolute;
	top:0;
	left:calc(50% - 50px);
	width:100px;
	height:3px;
	background:var(--color-delta);
}
.c-member-admin__already-invited {
	margin-top:10px;
	text-align:left;
}
.c-member-admin__already-invited-item {
	padding:10px 0;
	border-bottom:1px solid #d3d3d3;
	display:flex;
	justify-content:space-between;
	align-items:center;
}
.c-member-admin__already-invited-item:first-child {
	border-top:1px solid #d3d3d3;
}

.c-member-admin__remove {
	display:inline-block;
	width:20px;
	height:20px;
	margin-bottom:2px;
	background:#d7d7d7;
	color:#fff;
	font-size:10px;
	border-radius:50%;
	line-height:22px;
	text-align:center;
	transition:background 0.2s ease;
}
.c-member-admin__remove:hover,
.c-member-admin__remove:focus {
	background:#000;
}