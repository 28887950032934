/* =============================================================================
 * Attachments
============================================================================= */
.c-attachments {
	line-height:1.4;
}
.c-attachments--centered {
	display:flex;
	justify-content:center;
	align-items:stretch;
	flex-wrap:wrap;
	text-align:center;
}

@media print {
	.c-attachments--centered {
		justify-content:flex-start;
	}
}

/* Item
---------------------------------------------------------------------------- */
.c-attachments__item {
	position:relative;
	padding:10px 20px 10px 70px;
	margin-bottom:20px;
	min-height:68px;
	background: #fff;
	border: 1px solid #dbdbdb;
	transition:all 0.2s ease;
}
.c-attachments--centered .c-attachments__item {
	margin:0 10px 20px;
	padding:70px 10px 20px;
	width:200px;
	flex:0 0 auto;
}
.c-attachments__item--disappear {
	opacity:0;
}
@media(--small) {
	.c-attachments__item {
		margin-bottom:10px;
	}
}


/* Icon
---------------------------------------------------------------------------- */
.c-attachments__icon {
	position:absolute;
	left:20px;
	top:12px;
	height:40px;
}
.c-attachments--centered .c-attachments__icon {
	left:50%;
	top:20px;
	transform:translateX(-50%);
}

/* Text
---------------------------------------------------------------------------- */
.c-attachments__name {
	display:block;
	margin:4px 0 0;
	overflow:hidden;
	white-space:nowrap;
	text-overflow:ellipsis;
	color:#000;
}
.c-attachments__name::before {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	content:"";
	transition:box-shadow 0.2s ease;
}
.c-attachments__name:hover::before,
.c-attachments__name:focus::before {
	box-shadow:0 0 4px rgba(0, 0, 0, 0.2);
}
.c-attachments__meta {
	@mixin text-style-nu;
	color:#767676;
	display:block;
}
.c-attachments__meta-text {
	margin-right:6px;
}
.c-attachments__remove,
.c-attachments__remove:visited {
	@mixin text-style-nu;
	position:relative;
	z-index:1;
	color:var(--color-gamma);
}
.c-attachments__remove:hover,
.c-attachments__remove:focus {
	color:var(--color-iota);
}
