/* =============================================================================
 * Toolbar
============================================================================= */
.c-toolbar {
	display:flex;
	justify-content:center;
	text-align:center;
}
.c-toolbar__item:not(:empty) {
	min-width:50px;
	margin:0 5px;
}
@media(--small) {
	.c-toolbar__item:not(:empty) {
		margin:0;
	}
}
.c-toolbar__tool {
	display:inline-block;
}
.c-toolbar__tool,
.c-toolbar__tool:visited {
	color:var(--color-beta);
}
.c-toolbar__tool:hover .c-toolbar__symbol,
.c-toolbar__tool:focus .c-toolbar__symbol {
	border-color:var(--color-link);
	color:var(--color-link);
}

.c-toolbar__symbol,
.c-toolbar__symbol {
	border-color:var(--color-beta);
	color:var(--color-beta);
}
.c-toolbar__symbol {
	display:inline-block;
	width:29px;
	height:29px;
	border:1px solid #909090;
	border-radius:50%;
	color:#909090;
	text-align:center;
	line-height:29px;
	transition:all 0.2s ease;
}
.c-toolbar__name {
	display:block;
	font-size:12px;
	font-weight:400;
}

@media print {
	.c-toolbar {
		display:none;
	}
}
