/* =============================================================================
 * Legend
============================================================================= */
.c-legend {
	text-align:center;
}
.c-legend--large {
	@mixin text-style-theta;
	margin: 0;
}
.c-legend__item {
	margin-left:15px;
	margin-right:15px;
}
.c-legend__icon {
	margin-right:10px;
	display: inline-block;
	vertical-align: -4px;
}
@media(--small) {
	.c-legend {
		text-align:left;
	}
	.c-legend__item {
		margin:0;
	}
}
