.t-items-stretch {
	align-items:stretch;
}
.t-items-end {
	align-items:flex-end;
}
.t-items-center {
	align-items:center;
}
.t-justify-end {
	justify-content:flex-end;
}
.t-justify-between {
	justify-content:space-between;
}
.t-justify-center {
	justify-content:center;
}
/* Align self */
.t-self-end {
	align-self:flex-end;
}
