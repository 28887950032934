/* =============================================================================
 * Exercise layout
============================================================================= */
.o-exercise-layout {
	display:flex;
	margin-bottom:20px;
}
.o-exercise-layout .o-exercise-layout__sidebar {
	width: calc(100% - 770px);
	display:flex;
	flex-shrink:1;
}
.o-exercise-layout .o-exercise-layout__preview {
	margin-left:auto;
}
@media(--medium) {
	.o-exercise-layout {
		display:block;
	}
	.o-exercise-layout .o-exercise-layout__sidebar {
		display:block;
		width:auto;
		margin-bottom:20px;
	}
	.o-exercise-layout .o-exercise-layout__preview {
		width:748px;
		margin:0 auto;
	}
}
