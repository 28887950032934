/* =============================================================================
 * Submitted task
============================================================================= */


/* Header
----------------------------------------------------------------------------- */
.c-submitted-task__header {
	margin-top:20px;
	margin-bottom:30px;
	text-align:center;
}
.c-submitted-task--minimal .c-submitted-task__header {
	text-align:left;
}


/* Heading with kicker
----------------------------------------------------------------------------- */
.c-submitted-task__heading-with-kicker {
	@mixin text-style-epsilon;
	margin-bottom:20px;
}
.c-submitted-task__heading-with-kicker > * {
	display:block;
}
.c-submitted-task__kicker {
	@mixin text-style-nu;
	margin-bottom:10px;
}
.c-submitted-task__task-name::before {
	display:inline-block;
	width:1px;
	height:1em;
	margin:0 0.5em 0 0.25em;
	background:currentColor;
	content:"";
	vertical-align:-2px;
}
