/* =============================================================================
 * Messages
 *
 * "Info" is the default message type. Colors are temporary.
============================================================================= */
.c-system-message {
	position:relative;
	margin-top:3px;
	margin-bottom:40px;
	border-width:1px;
	border-style:solid;
	background-color:#fff;
}
.c-system-message__header {
	padding:30px 40px 0;
	text-align:center;
}
.c-system-message__content {
	padding:0 40px 30px;
}
.c-system-message__content > :last-child {
	margin-bottom:0;
}
.c-system-message__list {
	text-align:left;
}
.c-system-message__heading {
	margin-bottom:0.5em;
	font-weight:500;
	font-size:1.1428571429em; /* From 14 to 16 (16/14) */
}
.c-system-message__symbol {
	display:inline-block;
	margin-bottom:15px;
	font-size:39px;
}
.c-system-message--small {
	margin-top:0;
}
.c-system-message--small .c-system-message__content {
	padding:10px;
	display:flex;
	justify-content: center;
	align-items:center;
}
.c-system-message--small .c-system-message__symbol {
	font-size:20px;
	margin:0 15px 0 0;
}
@media(--small) {
	.c-system-message__content {
		padding:20px;
	}
}
@media(--medium) {
	.c-system-message--small {
		margin-bottom:0;
	}
	.c-system-message--small .c-system-message__content {
		flex-direction:column;
		text-align:center;
		padding:15px;
	}
	.c-system-message--small .c-system-message__symbol {
		font-size:25px;
		margin:0 0 15px;
	}
}
/*
 * Info
 */
.c-system-message--info {
	border-color:rgb(0, 150, 220);
}
.c-system-message--info .c-system-message__symbol {
	color:rgb(0, 150, 220);
}
.c-system-message--small.c-system-message--info {
	background-color: rgb(0, 150, 220);
	color:#fff;
}
.c-system-message--small.c-system-message--info .c-system-message__symbol {
	color:#fff;
}
/*
 * Success
 */
.c-system-message--success {
	border-color:rgb(35, 160, 78);
}
.c-system-message--success .c-system-message__symbol {
	color:rgb(35, 160, 78);
}
.c-system-message--small.c-system-message--success {
	background-color: rgb(35, 160, 78);
	color:#fff;
}
.c-system-message--small.c-system-message--success .c-system-message__symbol {
	color:#fff;
}
/*
 * Warning
 */
.c-system-message--warning {
	border-color:rgb(255, 205, 0);
}
.c-system-message--warning .c-system-message__symbol {
	color:rgb(255, 205, 0);
}
.c-system-message--small.c-system-message--warning {
	background-color: rgb(255,205,0);
	color:var(--color-alpha);
}
.c-system-message--small.c-system-message--warning .c-system-message__symbol {
	color:var(--color-alpha);
}
/*
 * Error
 */
.c-system-message--error {
	border-color:rgb(184, 8, 8);
}
.c-system-message--error .c-system-message__symbol,
.c-system-message--error .c-system-message__heading {
	color:rgb(184, 8, 8);
}
.c-system-message--small.c-system-message--error {
	background-color: rgb(184, 8, 8);
	color:#fff;
}
.c-system-message--small.c-system-message--error .c-system-message__symbol {
	color:#fff;
}