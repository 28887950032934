.c-search-result__headline {
	@mixin text-style-eta;
	margin-bottom: 20px;
}

.c-search-result__headline strong {
	font-style: italic;
}

.c-search-result__item {
	margin-bottom: calc(var(--spacing--vertical-spacing-unit) / 2);
	padding-bottom: calc(var(--spacing--vertical-spacing-unit) / 2);
	border-bottom: 1px solid var(--color-zeta);
}

@media(--small) {
	.c-search-result__item {
		margin-bottom: calc(var(--spacing--vertical-spacing-unit-mobile) / 2);
		padding-bottom: calc(var(--spacing--vertical-spacing-unit-mobile) / 2);
	}
}

.c-search-result__item:last-child {
	border: 0;
}

.c-search-result__item-title {
	@mixin text-style-beta;
	font-size: 20px;
	margin-bottom:10px;
}

.c-search-result__meta {
	display:flex;
	justify-content:space-between;
	padding-top:10px;
	margin-top:10px;
	border-top:1px dotted #ccc;
	border-top: 1px solid var(--color-zeta);
}

.c-search-result__meta .c-breadcrumbs {
	padding: 0;
}

.c-search-result__updated {
	font-style:italic;
}

.c-search-result__item p:last-of-type {
	margin-bottom: 0;
}

.c-search-result__link {
	color: var(--color-link);
}
