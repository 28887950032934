/* =============================================================================
 * Grid
============================================================================= */

/* width limiter needs to counter negative margin with equal amount of padding */
.o-grid {
	display: flex;
	flex-wrap: wrap;
	margin:0 calc(var(--spacing--horizontal-gutter) * -0.5);
	font-size:0;
}
.o-grid--align-center {
	align-items: center;
}
.o-grid::after {
	display:table;
	clear:both;
	content:"";
}
.o-grid > .o-grid__column {
	display:inline-block;
	display: flex;
	flex-direction: column;
	padding:0 calc(var(--spacing--horizontal-gutter) * 0.5);
	font-size:var(--type-size-default);
	vertical-align:top;
}
.o-grid--collapsed {
	margin:0;
}
.o-grid--collapsed > .o-grid__column {
	padding:0;
}


/* Small
----------------------------------------------------------------------------- */
.o-grid > [data-size="1"],
.o-grid > [data-size~="sm:1"] {
	width:calc(100% * 1/24);
}
.o-grid > [data-size="2"],
.o-grid > [data-size~="sm:2"] {
	width:calc(100% * 2/24);
}
.o-grid > [data-size="3"],
.o-grid > [data-size~="sm:3"] {
	width:calc(100% * 3/24);
}
.o-grid > [data-size="4"],
.o-grid > [data-size~="sm:4"] {
	width:calc(100% * 4/24);
}
.o-grid > [data-size="5"],
.o-grid > [data-size~="sm:5"] {
	width:calc(100% * 5/24);
}
.o-grid > [data-size="6"],
.o-grid > [data-size~="sm:6"] {
	width:calc(100% * 6/24);
}
.o-grid > [data-size="7"],
.o-grid > [data-size~="sm:7"] {
	width:calc(100% * 7/24);
}
.o-grid > [data-size="8"],
.o-grid > [data-size~="sm:8"] {
	width:calc(100% * 8/24);
}
.o-grid > [data-size="9"],
.o-grid > [data-size~="sm:9"] {
	width:calc(100% * 9/24);
}
.o-grid > [data-size="10"],
.o-grid > [data-size~="sm:10"] {
	width:calc(100% * 10/24);
}
.o-grid > [data-size="11"],
.o-grid > [data-size~="sm:11"] {
	width:calc(100% * 11/24);
}
.o-grid > [data-size="12"],
.o-grid > [data-size~="sm:12"] {
	width:calc(100% * 12/24);
}
.o-grid > [data-size="13"],
.o-grid > [data-size~="sm:13"] {
	width:calc(100% * 13/24);
}
.o-grid > [data-size="14"],
.o-grid > [data-size~="sm:14"] {
	width:calc(100% * 14/24);
}
.o-grid > [data-size="15"],
.o-grid > [data-size~="sm:15"] {
	width:calc(100% * 15/24);
}
.o-grid > [data-size="16"],
.o-grid > [data-size~="sm:16"] {
	width:calc(100% * 16/24);
}
.o-grid > [data-size="17"],
.o-grid > [data-size~="sm:17"] {
	width:calc(100% * 17/24);
}
.o-grid > [data-size="18"],
.o-grid > [data-size~="sm:18"] {
	width:calc(100% * 18/24);
}
.o-grid > [data-size="19"],
.o-grid > [data-size~="sm:19"] {
	width:calc(100% * 19/24);
}
.o-grid > [data-size="20"],
.o-grid > [data-size~="sm:20"] {
	width:calc(100% * 20/24);
}
.o-grid > [data-size="21"],
.o-grid > [data-size~="sm:21"] {
	width:calc(100% * 21/24);
}
.o-grid > [data-size="22"],
.o-grid > [data-size~="sm:22"] {
	width:calc(100% * 22/24);
}
.o-grid > [data-size="23"],
.o-grid > [data-size~="sm:23"] {
	width:calc(100% * 23/24);
}
.o-grid > [data-size="24"],
.o-grid > [data-size~="sm:24"] {
	width:calc(100% * 24/24);
}

/* Medium
----------------------------------------------------------------------------- */
@media(--md) {
	.o-grid > [data-size~="md:1"] {
		width:calc(100% * 1/24);
	}
	.o-grid > [data-size~="md:2"] {
		width:calc(100% * 2/24);
	}
	.o-grid > [data-size~="md:3"] {
		width:calc(100% * 3/24);
	}
	.o-grid > [data-size~="md:4"] {
		width:calc(100% * 4/24);
	}
	.o-grid > [data-size~="md:5"] {
		width:calc(100% * 5/24);
	}
	.o-grid > [data-size~="md:6"] {
		width:calc(100% * 6/24);
	}
	.o-grid > [data-size~="md:7"] {
		width:calc(100% * 7/24);
	}
	.o-grid > [data-size~="md:8"] {
		width:calc(100% * 8/24);
	}
	.o-grid > [data-size~="md:9"] {
		width:calc(100% * 9/24);
	}
	.o-grid > [data-size~="md:10"] {
		width:calc(100% * 10/24);
	}
	.o-grid > [data-size~="md:11"] {
		width:calc(100% * 11/24);
	}
	.o-grid > [data-size~="md:12"] {
		width:calc(100% * 12/24);
	}
	.o-grid > [data-size~="md:13"] {
		width:calc(100% * 13/24);
	}
	.o-grid > [data-size~="md:14"] {
		width:calc(100% * 14/24);
	}
	.o-grid > [data-size~="md:15"] {
		width:calc(100% * 15/24);
	}
	.o-grid > [data-size~="md:16"] {
		width:calc(100% * 16/24);
	}
	.o-grid > [data-size~="md:17"] {
		width:calc(100% * 17/24);
	}
	.o-grid > [data-size~="md:18"] {
		width:calc(100% * 18/24);
	}
	.o-grid > [data-size~="md:19"] {
		width:calc(100% * 19/24);
	}
	.o-grid > [data-size~="md:20"] {
		width:calc(100% * 20/24);
	}
	.o-grid > [data-size~="md:21"] {
		width:calc(100% * 21/24);
	}
	.o-grid > [data-size~="md:22"] {
		width:calc(100% * 22/24);
	}
	.o-grid > [data-size~="md:23"] {
		width:calc(100% * 23/24);
	}
	.o-grid > [data-size~="md:24"] {
		width:calc(100% * 24/24);
	}
}

/* Large
----------------------------------------------------------------------------- */
@media(--lg) {
	.o-grid > [data-size~="lg:1"] {
		width:calc(100% * 1/24);
	}
	.o-grid > [data-size~="lg:2"] {
		width:calc(100% * 2/24);
	}
	.o-grid > [data-size~="lg:3"] {
		width:calc(100% * 3/24);
	}
	.o-grid > [data-size~="lg:4"] {
		width:calc(100% * 4/24);
	}
	.o-grid > [data-size~="lg:5"] {
		width:calc(100% * 5/24);
	}
	.o-grid > [data-size~="lg:6"] {
		width:calc(100% * 6/24);
	}
	.o-grid > [data-size~="lg:7"] {
		width:calc(100% * 7/24);
	}
	.o-grid > [data-size~="lg:8"] {
		width:calc(100% * 8/24);
	}
	.o-grid > [data-size~="lg:9"] {
		width:calc(100% * 9/24);
	}
	.o-grid > [data-size~="lg:10"] {
		width:calc(100% * 10/24);
	}
	.o-grid > [data-size~="lg:11"] {
		width:calc(100% * 11/24);
	}
	.o-grid > [data-size~="lg:12"] {
		width:calc(100% * 12/24);
	}
	.o-grid > [data-size~="lg:13"] {
		width:calc(100% * 13/24);
	}
	.o-grid > [data-size~="lg:14"] {
		width:calc(100% * 14/24);
	}
	.o-grid > [data-size~="lg:15"] {
		width:calc(100% * 15/24);
	}
	.o-grid > [data-size~="lg:16"] {
		width:calc(100% * 16/24);
	}
	.o-grid > [data-size~="lg:17"] {
		width:calc(100% * 17/24);
	}
	.o-grid > [data-size~="lg:18"] {
		width:calc(100% * 18/24);
	}
	.o-grid > [data-size~="lg:19"] {
		width:calc(100% * 19/24);
	}
	.o-grid > [data-size~="lg:20"] {
		width:calc(100% * 20/24);
	}
	.o-grid > [data-size~="lg:21"] {
		width:calc(100% * 21/24);
	}
	.o-grid > [data-size~="lg:22"] {
		width:calc(100% * 22/24);
	}
	.o-grid > [data-size~="lg:23"] {
		width:calc(100% * 23/24);
	}
	.o-grid > [data-size~="lg:24"] {
		width:calc(100% * 24/24);
	}
}

/* Extra large
----------------------------------------------------------------------------- */
@media(--xl) {
	.o-grid > [data-size~="xl:1"] {
		width:calc(100% * 1/24);
	}
	.o-grid > [data-size~="xl:2"] {
		width:calc(100% * 2/24);
	}
	.o-grid > [data-size~="xl:3"] {
		width:calc(100% * 3/24);
	}
	.o-grid > [data-size~="xl:4"] {
		width:calc(100% * 4/24);
	}
	.o-grid > [data-size~="xl:5"] {
		width:calc(100% * 5/24);
	}
	.o-grid > [data-size~="xl:6"] {
		width:calc(100% * 6/24);
	}
	.o-grid > [data-size~="xl:7"] {
		width:calc(100% * 7/24);
	}
	.o-grid > [data-size~="xl:8"] {
		width:calc(100% * 8/24);
	}
	.o-grid > [data-size~="xl:9"] {
		width:calc(100% * 9/24);
	}
	.o-grid > [data-size~="xl:10"] {
		width:calc(100% * 10/24);
	}
	.o-grid > [data-size~="xl:11"] {
		width:calc(100% * 11/24);
	}
	.o-grid > [data-size~="xl:12"] {
		width:calc(100% * 12/24);
	}
	.o-grid > [data-size~="xl:13"] {
		width:calc(100% * 13/24);
	}
	.o-grid > [data-size~="xl:14"] {
		width:calc(100% * 14/24);
	}
	.o-grid > [data-size~="xl:15"] {
		width:calc(100% * 15/24);
	}
	.o-grid > [data-size~="xl:16"] {
		width:calc(100% * 16/24);
	}
	.o-grid > [data-size~="xl:17"] {
		width:calc(100% * 17/24);
	}
	.o-grid > [data-size~="xl:18"] {
		width:calc(100% * 18/24);
	}
	.o-grid > [data-size~="xl:19"] {
		width:calc(100% * 19/24);
	}
	.o-grid > [data-size~="xl:20"] {
		width:calc(100% * 20/24);
	}
	.o-grid > [data-size~="xl:21"] {
		width:calc(100% * 21/24);
	}
	.o-grid > [data-size~="xl:22"] {
		width:calc(100% * 22/24);
	}
	.o-grid > [data-size~="xl:23"] {
		width:calc(100% * 23/24);
	}
	.o-grid > [data-size~="xl:24"] {
		width:calc(100% * 24/24);
	}
}
