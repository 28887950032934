.c-teaser {
	position: relative;
	overflow: hidden;
	background: #fff;
	margin: calc(var(--spacing--vertical-spacing-unit) * 0.5) 0;
	flex-grow: 1; /* ta upp 100% yta */
	outline: none;
	color: inherit;
	text-align:left;
}

.t-background-white .c-teaser:not(.c-teaser--color-alpha):not(.c-teaser--color-beta):not(.c-teaser--color-gamma):not(.c-teaser--video) {
	border: 1px solid #dedede;
}

a.c-teaser:visited,
a.c-teaser:hover,
a.c-teaser:focus,
button.c-teaser:visited,
button.c-teaser:hover,
button.c-teaser:focus {
	color: inherit;
}

@media(--small) {
	.c-teaser {
		margin: calc(var(--spacing--vertical-spacing-unit-mobile) * 0.5) 0;
	}
}

.c-teaser__media {
	display: block;
	position: relative;
	outline: none;
	overflow: hidden;
}

.c-teaser__media img {
	width: 100%;
	transition: all .2s ease-in-out;
}

a.c-teaser:hover .c-teaser__media img,
button.c-teaser:hover .c-teaser__media img {
	transform: scale(1.1);
}

.c-teaser__text-wrapper {
	max-width: 100%;
	padding: 30px;
	word-wrap: break-word;
	word-break: break-word;
}

.c-teaser__text-wrapper .c-button {
	margin-top: 46px;
}

.c-teaser__headline {
	@mixin text-style-zeta;
	margin-bottom: 10px;
}

@media(--medium) {
	.c-teaser__headline {
		font-size: 24px;
	}

	.c-teaser__text-wrapper {
		padding: 15px 20px;
	}
}

/* Overlay
 ---------------------------------------------------------------------------- */
.c-teaser--overlay .c-teaser__inner:before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,0.5);
}

/* Quote
 ---------------------------------------------------------------------------- */
.c-teaser--quote {
	padding: 70px 0;
	display:inline-block;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1; /* ta upp 100% yta */
}

.c-teaser--quote .c-teaser__text-wrapper {
	@mixin text-style-kappa;
	color: #fff;
	position: relative;
}

.c-teaser--quote .c-teaser__text-wrapper::before,
.c-teaser--quote .c-teaser__text-wrapper::after {
	content: '\e913';
	font-family: 'icomoon';
	font-size: 17px;
	line-height: inherit;
	display: block;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
}

.c-teaser--quote .c-teaser__text-wrapper::before {
	top: -40px;
}

.c-teaser--quote .c-teaser__text-wrapper::after {
	bottom: -40px;
}

.c-teaser--quote__name {
	@mixin text-style-beta;
	margin-top: 20px;
	font-size: 20px;
	color: #fff;
}

/* Only text
 ---------------------------------------------------------------------------- */
.c-teaser--text {
	display: flex;
	align-items: center;
	background: var(--color-gamma);
	color: #fff;
}

.c-teaser--text .c-teaser__text-wrapper {
	padding: 57px 30px 87px;
}

.c-teaser--text .c-teaser__headline {
	margin-bottom: 36px;
}

/* 50/50
 ---------------------------------------------------------------------------- */
.c-teaser--50-50 .c-teaser__text-holder {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: none;
}

.c-teaser--50-50 .c-teaser__text-wrapper {
	width: 100%;
}

.c-teaser--50-50 .c-teaser__headline {
	margin-bottom: 34px;
}

.c-teaser--50-50 img {
	width: 100%;
	min-height: 1px; /* to fix IE 11 bug */
}

/* Video
 ---------------------------------------------------------------------------- */
.c-teaser--video {
	position: relative;
	border: 0;
	display:block;
	width:100%;
}

/* This before selector is here only to please firefox when using flexbox/padding-top combo */
.c-teaser--video::before {
	content: '';
	display: block;
	padding-top: 44.0647%;
}

.c-teaser--video__background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transition: all .2s ease-in-out;
}

@media(--small) {
	.c-teaser--video::before {
		padding-top: 64.7887%;
	}
}

.c-teaser--video::after {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.71;
	background-image: linear-gradient(-180deg, rgba(255,255,255,0.00) 0%, #000000 83%);
}

a.c-teaser--video:hover .c-teaser--video__background,
button.c-teaser--video:hover .c-teaser--video__background {
	transform: scale(1.05);
}

.c-teaser--video .c-teaser__text-wrapper {
	z-index: 1;
	color: #fff;
	position: absolute;
	left: 0;
	bottom: 0;
	padding: 30px 5.9322%; /* 70/1180 */
}

@media(--small) {
	.c-teaser--video .c-teaser__text-wrapper {
		padding: 15px 20px;
	}
}

.c-teaser--video .c-teaser__text-wrapper [class^='o-width-limiter'] {
	padding: 0;
}

.c-teaser--video .c-teaser__headline--secondary {
	@mixin text-style-nu;
	display: block;
	margin-bottom: 5px;
}

.c-teaser--video .c-teaser__headline {
	@mixin text-style-epsilon;
	margin-bottom: 0;
}

.c-teaser--video .c-teaser__body {
	font-size: 16px;
	margin-top: 6px;
}

.c-teaser__text--medium .c-teaser__body {
	font-size: 14px;
}

.c-teaser__text--medium .c-teaser__headline {
	font-size: 30px;
	line-height: 1.2;
}

@media(--small) {
	.c-teaser--video .c-teaser__headline {
		font-size: 18px;
		line-height: normal;
	}
}

@media(--medium) {
	.c-teaser--video .c-teaser__description {
		display: none;
	}
}

/* Events
----------------------------------------------------------------------------- */
.c-teaser--events {
	padding:20px;
}
.c-teaser--events .c-teaser__headline {
	margin-bottom:20px;
}

/* Colors
 ---------------------------------------------------------------------------- */
.c-teaser--color-alpha {
	background: var(--color-gamma);
}

.c-teaser--color-beta {
	background: var(--color-kappa);
}

.c-teaser--color-gamma {
	background: var(--color-epsilon);
}

/* Variant - Education
 *
 * 1. Match the height of buttons used in .c-teaser__footer
---------------------------------------------------------------------------- */
.c-teaser--education {
	border: 0;
	background: none;
}

@media(--small) {
	.c-teaser--education {
		margin-left:auto;
		margin-right:auto;
		min-width:300px;
	}
}

.c-teaser--education .c-teaser__inner {
	position: relative;
	padding-top: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.c-teaser--education .c-teaser__block-link,
.c-teaser--education .c-teaser__block-link:visited {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	color:var(--color-beta);
}

.c-teaser__status-indicator {
	position: absolute;
	top: 0;
	left: 0;
	background: var(--color-delta);
	padding: 6px 10px;
	text-align: center;
}

.c-teaser__center-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.c-teaser__heading {
	@mixin text-style-zeta;
	color: #fff;
}

.c-teaser__subheading {
	display: block;
	margin-top: 10px;
}

.c-teaser__button-wrapper {
	position: absolute;
	left: 50%;
	bottom: 20px;
	transform: translateX(-50%);
	text-align: center;
}

.c-teaser__footer {
	padding:10px;
	text-align:center;
}

.c-teaser__registered {
	@mixin text-style-nu;
	display:inline-block;
	font-size:15px;
	font-weight:bold;
	line-height:48px; /* 1 */
	color:#636363;
	vertical-align:middle;
}
/* Print
 ---------------------------------------------------------------------------- */
@media print {
	.c-teaser:not(.c-teaser--education) {
		display:none;
	}
}
