.c-teaser-illustrated {
	color: #fff;
}

.c-teaser-illustrated__header {
	margin-bottom: 14px;
}

.c-teaser-illustrated__headline {
	@mixin text-style-zeta;
	margin-bottom: 12px;
}

@media (--medium) {
	.c-teaser-illustrated__headline {
		font-size: 16px;
		line-height: 1.7;
	}
}

.c-teaser-illustrated__headline-secondary {
	@mixin text-style-nu;
	font-weight: 700;
}

@media (--medium) {
	.c-teaser-illustrated__headline-secondary {
		font-size: 10px;
	}
}

.c-teaser-illustrated__headline-secondary--accent {
	color: var(--color-delta);
}

.c-teaser-illustrated__media {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color:var(--color-link);
	border-radius: 100%;
	padding: 16px;
	height: 77px;
	width: 77px;
	margin: 0 auto 14px;
}

.c-teaser-illustrated__description {
	@mixin text-style-xi;
	font-weight: 400;
	font-size: 14px;
}
