/* =============================================================================
 * Comment
============================================================================= */

/* Portrait
---------------------------------------------------------------------------- */
.c-comment__portrait {
	position:relative;
	display:block;
}
.c-comment__portrait-image {
	width:100px;
	border-radius:50%;
}
.c-comment__portrait-badge {
	position:absolute;
	bottom:0;
	right:0;
	box-shadow:0 0 0 2px #fff;
}
@media(--small) {
	.c-comment__portrait {
		float:right;
		width:25px;
		margin-left:10px;
	}
}


/* Heading
---------------------------------------------------------------------------- */
.c-comment__heading {
	margin-bottom:15px;
}
.c-comment__name {
	border-bottom:1px solid rgba(0, 0, 0, 0.3);
	color:#000;
	transition:border 0.2s ease;
	line-height:1.2;
	font-weight:600;
}
.c-comment__name:hover,
.c-comment__name:focus{
	border-bottom-color:inherit;
}
.c-comment__separator {
	display:inline-block;
	width:1px;
	overflow:hidden;
	margin:0 6px;
	background:#555;
	line-height:1;
	vertical-align:-2px;
	text-indent:200%;
	white-space:nowrap;
}


/* Toolbar
---------------------------------------------------------------------------- */
.c-comment__toolbar {
	display:flex;
	margin-top:30px;
}


/* Buttons
----------------------------------------------------------------------------- */
.c-comment__action {
	margin-right:30px;
}
.c-comment__action:last-child {
	margin-right:0;
}
.c-comment__action-icon {
	margin-right:8px;
	color:#979797;
	font-size:17px;
	vertical-align:-4px;
	transition:color 0.2s ease;
}
.c-comment__action:hover .c-comment__action-icon,
.c-comment__action:focus .c-comment__action-icon {
	color:#000;
}
.c-comment__action-text {
	border-bottom:1px solid rgba(0, 0, 0, 0.3);
	color:#000;
	transition:border 0.2s ease;
}
.c-comment__action:hover .c-comment__action-text,
.c-comment__action:focus .c-comment__action-text {
	border-bottom-color:inherit;
}
.c-comment__change {
	margin-left:auto;
}
