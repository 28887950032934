/* =============================================================================
 * Info box
============================================================================= */
.c-info-box {
	margin-bottom: 30px;
	padding: 35px 30px;
	background: #fff;
	border: 1px solid #DBDBDB;
}

.c-info-box__heading {
	@mixin text-style-theta;
}

.c-info-box__avatar {
	float:right;
	margin-top:-70px;
	width:120px;
	height:120px;
	border-radius:50%;
}

@media(--medium) {
	.c-info-box__avatar {
		width:100px;
		height:100px;
	}
}

.c-info-box__manage-list-icon {
	font-size: 14px;
	margin-right: 10px;
	vertical-align: middle;
}

.c-info-box__label {
	@mixin text-style-omicron;
	font-weight: bold;
	margin-bottom: 5px;
}

.c-info-box__list {
	margin-bottom: 22px;
}

.c-info-box__item {
	margin-bottom: 5px;
}

.c-info-box__wrapper {
	padding: 40px 20px 30px 20px;
	background-color: #F2F2F2;
}

/* Activity box
---------------------------------------------------------------------------- */
.c-info-box__activity-list {
	display: flex;
	flex-wrap: wrap;
	margin: -1%;
}

.c-info-box__activity-link {
	background-color:var(--color-link);
	box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
	width: 48%;
	min-height: 140px;
	padding: 24px 24px 18px;
	margin: 5px;
	text-align: center;
	word-wrap: break-word;
	color: #fff;
}

@media(--small) {
	.c-info-box__activity-link {
		width: 100%;
	}
}

.c-info-box__activity-heading {
	@mixin text-style-eta;
	font-size: 20px;
	margin-bottom: 6px;
}

.c-info-box__activity-number {
	display: block;
	font-size: 80px;
	font-weight: 100;
	line-height: 84px;
}

.c-info-box__icon {
	display: block;
	margin-bottom: 6px;
}

/* Table box
---------------------------------------------------------------------------- */
.c-info-box__header {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 30px;
}

.c-info-box__manage-list-link {
	@mixin text-style-omicron;
	font-weight: 600;
	color: var(--color-link);
}

.c-info-box__manage-list-link + .c-info-box__manage-list-link {
	margin-left: 15px;
}

.c-info-box__status-approved {
	background: #FFCD00;
	border-radius: 2px;
	font-size: 12px;
	padding: 3px 5px;
}

.c-info-box__calendar-wrapper[class] {
	width: 70px;
	padding-top:8px;
	padding-bottom:10px;
}

.c-info-box__external-link,
.s-text .c-info-box__external-link {
	font-size: 12px;
	color: #909090;
	border-bottom: 0;
}

/* Print
---------------------------------------------------------------------------- */
@media print {
	.c-info-box {
		padding:0;
		border:none;
	}
}
