/* =============================================================================
 * Slider
 *
 * 1. Rules will apply only in IE 10 and 11
 * 2. Rules will apply only in Edge
============================================================================= */
.c-slider__inner {
	display:flex;
}

.c-slider__range {
	flex-grow:1;
}

.c-slider__value-now {
	position:relative;
	bottom:6px;
	@mixin text-style-theta;
	min-width:2.25em;
	padding-left:0.25em;
	text-align:right;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { /* 1 */
	.c-slider__value-now {
		align-self:center;
	}
}

@supports (-ms-ime-align:auto) { /* 2 */
	.c-slider__value-now {
		align-self:center;
	}
}

.c-slider__label {
	@mixin text-style-beta;
	margin-bottom:10px;
}


/* Input
 *
 * 1. Padding instead of margin for larger click area
----------------------------------------------------------------------------- */
.c-slider__input {
	-webkit-appearance: none;
	padding: 8px 0; /* 1 */
	background:transparent;
	width: 100%;
	cursor: ew-resize;
}

.c-slider__input:disabled {
	opacity:0.5;
}

@supports (filter: grayscale(1)) {
	.c-slider__input:disabled {
		filter:grayscale(1);
		opacity:0.8;
	}
}

.c-slider__range-wrapper {
	position:relative;
}

.c-slider__input:focus {
	outline: none;
}


/* Thumb
 *
 * 1. Rules will apply only in Edge
----------------------------------------------------------------------------- */
.c-slider__input::-webkit-slider-thumb {
	position:relative;
	z-index:2;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	height: 24px;
	width: 24px;
	border-radius: 100px;
	background: var(--color-gamma) url(/project/components/slider/arrows.svg) 50% 50% no-repeat;
	-webkit-appearance: none;
	margin-top: -10px;
}

.c-slider__input::-moz-range-thumb {
	position:relative;
	z-index:2;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	height: 24px;
	width: 24px;
	border-radius: 100px;
	background: var(--color-gamma) url(/project/components/slider/arrows.svg) 50% 50% no-repeat;
	border:none;
}

.c-slider__input::-ms-thumb {
	position:relative;
	z-index:2;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	height: 24px;
	width: 24px;
	border-radius: 100px;
	background: var(--color-gamma) url(/project/components/slider/arrows.svg) 50% 50% no-repeat;
	border:none;
}

@supports (-ms-ime-align:auto) { /* 1 */
	.c-slider__input::-ms-thumb {
		margin-bottom:-7px;
	}
}

/* Track
----------------------------------------------------------------------------- */
.c-slider__input::-webkit-slider-runnable-track {
	width: 100%;
	height: 4px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25) inset;
	background: #e1e1e1;
	border-radius: 2px;
}

.c-slider__input:focus::-webkit-slider-runnable-track {
	background: #ccc;
}

.c-slider__input::-moz-range-track {
	width: 100%;
	height: 4px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25) inset;
	background: #e1e1e1;
	border-radius: 2px;
}

.c-slider__input:focus::-moz-range-track {
	background: #ccc;
}

.c-slider__input::-ms-track {
	width: 100%;
	height: 4px;
	background:transparent;
	border-color: transparent;
	border-width: 16px 0;
	border-radius:2px;
	color: transparent;
}

.c-slider__input::-ms-fill-lower {
	background: var(--color-epsilon);
}

.c-slider__input::-ms-fill-upper {
	background: #e1e1e1;
	border-radius: 0 2px 2px 0;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25) inset;
}

.c-slider__input:focus::-ms-fill-upper {
	background: #ccc;
}

/*
 * Different color to the left of the thumb
 *
 * 1. Rules will apply only in IE 10 and 11
 * 2. Rules will apply only in Edge
 */
.c-slider__range-fill {
	position:absolute;
	top:8px;
	background-color:var(--color-epsilon);
	height:4px;
	line-height:4px;
	z-index:1;
	border-radius:2px 0 0 2px;
	pointer-events:none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { /* 1 */
	.c-slider__range-fill {
		display:none;
	}
}

@supports (-ms-ime-align:auto) { /* 2 */
	.c-slider__range-fill {
		display:none;
	}
	.c-slider__value-now {
		align-self:center;
	}
}

.c-slider--disabled .c-slider__range-fill {
	background:#aaa;
}


/* Legend
----------------------------------------------------------------------------- */
.c-slider__legend {
	position:relative;
	z-index:1;
	margin-top:-12px;
	font-size:0.85rem;
	color:#767676;
}
.c-slider__value-min {
	float:left;
}
.c-slider__value-max {
	float:right;
}
