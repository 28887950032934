.c-video-listing {
	padding: 50px 0 70px;
}

.c-video-listing__list {
	display: flex;
	flex-wrap: wrap;
}

.c-video-listing__flexslider {
	position: relative;
}

.c-video-listing__flexslider .slides img,
.c-video-listing__static .slides img {
	width: 100%;
	display: block;
}

.c-video-listing__flexslider .flex-direction-nav a {
	top: 44px;
	transform: translateX(-50%);
	outline: none;
	border-color: var(--color-link);
	color: var(--color-link);
}

@media(max-width: 1400px) {
	.c-video-listing__flexslider .flex-direction-nav a {
		display: none;
	}
}

.c-video-listing__header {
	margin-bottom: 14px;
}

.c-video-listing__header::after {
	@mixin clearfix;
}

.c-video-listing__headline {
	float: left;
	@mixin text-style-theta;
}

.c-video-listing__toggler {
	float: right;
	background: #fff;
	border: 1px solid var(--color-gray);
	border-radius: 30px;
	padding: 5px 30px;
	outline: none;
	line-height: normal;
	color: var(--color-link);
}

@media(max-width: 500px) {
	.c-video-listing__toggler {
		margin: 20px 0;
	}
}

.c-video-listing__item {
	cursor: pointer;
	width: 23.09322034%; /* 272.5px/1180px */
	margin-right: 2.54237288%; /* 30px/1180px */
}

.no-js .c-video-listing__item {
	cursor: default;
}

.c-video-listing__item:nth-child(4n+4) {
	margin-right: 0;
}

@media(--medium){
	.c-video-listing__item {
		width: 48.72885%;
	}

	.c-video-listing__item:nth-child(2n+2) {
		margin-right: 0;
	}
}

@media(--small){
	.c-video-listing__item {
		width: 100%;
		margin-right: 0;
	}
}

.c-video-listing__link {
	display: block;
	@mixin text-style-omicron;
	color: var(--color-link);
	margin-bottom: 5px;
}

.c-video-listing__description {
	line-height: 19px;
	text-align: center;
	margin: 20px 0;
	cursor: default;
}

.c-video-listing__media {
	display: block;
	position: relative;
	outline: none;
	overflow: hidden;
}

.c-video-listing__media--video::after {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: .71;
	background-image: linear-gradient(-180deg,rgba(255,255,255,0) 0,#000 83%);
}

/* Hover effects
 ---------------------------------------------------------------------------- */
.c-video-listing__media img {
	transition: all .2s ease-in-out;
}

.c-video-listing__media:hover img {
	transform: scale(1.1);
}

/* Print
 ---------------------------------------------------------------------------- */
@media print {
	.c-video-listing {
		display:none;
	}
}
