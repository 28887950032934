/* =============================================================================
 * Time/status card
 *
 * [1]: Boundaries for .c-time-status-card__link::before
============================================================================= */
.c-time-status-card {
	display:flex;
	flex:1;
	-ms-flex-preferred-size:auto;
	flex-direction:column;
	position:relative; /* [1] */
	margin-bottom:25px;
	text-align:center;
	line-height:1.8;
}
.c-time-status-card__top {
	padding:30px;
	background: #fff;
	border: 1px solid #dbdbdb;
	border-bottom-width:0;
	flex-grow:1;
	line-height:1.4;
}
.c-time-status-card--admin .c-time-status-card__top {
	padding-bottom:20px;
}
.c-time-status-card__heading {
	@mixin text-style-zeta;
	margin-bottom:10px;
}
.c-time-status-card__heading--small {
	@mixin text-style-theta;
}
.c-time-status-card__new-comment {
	font-size:14px;
	margin-left:4px;
	transition:color 0.2s ease;
}


/* Link
 *
 * Covers the whole card area, but only the heading is linked
---------------------------------------------------------------------------- */
.c-time-status-card__link {
	color: var(--color-beta);
	transition:color 0.2s ease;
}
.c-time-status-card__link:hover,
.c-time-status-card__link:focus {
	color:var(--color-gamma);
}
.c-time-status-card__new-comment {
	color:var(--color-epsilon);
}
.c-time-status-card__link::before {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	content:"";
	z-index:1;
	transition:box-shadow 0.2s ease;
}
.c-time-status-card__link:hover::before,
.c-time-status-card__link:focus::before {
	box-shadow:0 0 4px rgba(0, 0, 0, 0.1);
}


/* Icon
---------------------------------------------------------------------------- */
.c-time-status-card__icon {
	display:block;
	margin:5px auto 15px;
	height:46px;
}


/* Instructor
---------------------------------------------------------------------------- */
.c-time-status-card__instructor-portrait {
	border-radius:50%;
	margin-bottom:8px;
	width:35px;
	box-shadow:0 0 0 2px #fff;
}
.c-time-status-card__instructor-portrait + .c-time-status-card__instructor-portrait {
	margin-left:-10px;
}
.c-time-status-card__instructor-title {
	@mixin text-style-nu;
	display:block;
	margin-bottom:2px;
}
.c-time-status-card__instructor-name {
	display:block;
}

/* Tasks
---------------------------------------------------------------------------- */
.c-time-status-card__tasks {
	@mixin text-style-nu;
	display:inline-block;
	border:1px solid #dbdbdb;
	border-radius:50px;
	margin-bottom:15px;
	padding:4px 9px;
}
.c-time-status-card--admin .c-time-status-card__tasks {
	margin-bottom:20px;
}


/* Traits
---------------------------------------------------------------------------- */
.c-time-status-card__traits {
	margin-bottom:15px;
	color:#787878;
}
.c-time-status-card__trait:not(:first-child) {
	position:relative;
}
.c-time-status-card__trait:not(:first-child)::before {
	display:inline-block;
	width:3px;
	height:3px;
	margin:0 4px;
	vertical-align:middle;
	border-radius:50%;
	content:"";
	background:#787878;
}


/* Footer
---------------------------------------------------------------------------- */
.c-time-status-card__footer {
	background:#fff;
	border:1px solid #dbdbdb;
	display:flex;
	align-items:center;
	justify-content:center;
	min-height:72px;
}

/*
 * Start and end dates
 */
.c-time-status-card__dates {
	position:relative;
	display:flex;
	align-self:stretch;
	align-items:center;
	flex-grow:1;
	line-height:1;
}
.c-time-status-card__dates::before,
.c-time-status-card__dates::after {
	position:absolute;
	left:50%;
	top:-1px;
	margin-left:-5px;
	width: 0;
	height: 0;
	border-top: 36px solid transparent;
	border-bottom: 36px solid transparent;
	border-left: 10px solid #dbdbdb;
	content:"";
}
.c-time-status-card__dates::after {
	margin-left:-6px;
	border-left-color:#fff;
}
.c-time-status-card__deadline,
.c-time-status-card__dates {
	@mixin text-style-nu;
}
.c-time-status-card__date {
	flex:1 1 50%;
}
.c-time-status-card__day {
	@mixin text-style-beta;
	display:block;
}
.c-time-status-card__month {
	color:#787878;
}

/*
 * Colors
 */
.c-time-status-card--ongoing .c-time-status-card__footer,
.c-time-status-card--approved .c-time-status-card__footer,
.c-time-status-card--rejected .c-time-status-card__footer,
.c-time-status-card--underreview .c-time-status-card__footer,
.c-time-status-card--overdue .c-time-status-card__footer {
	border:none;
	color:#fff;
}
.c-time-status-card--ongoing .c-time-status-card__footer {
	background:var(--color-ongoing);
}
.c-time-status-card--approved .c-time-status-card__footer {
	background:var(--color-approved);
}
.c-time-status-card--rejected .c-time-status-card__footer {
	background:var(--color-rejected);
}
.c-time-status-card--underreview .c-time-status-card__footer {
	background:var(--color-underreview);
}
.c-time-status-card--overdue .c-time-status-card__footer {
	background:var(--color-overdue);
}

/*
 * Status category
 */
.c-time-status-card__status-category {
	display:block;
}


/* Tools
---------------------------------------------------------------------------- */
.c-time-status-card__tools {
	position:absolute;
	bottom:1px;
	left:1px;
	right:1px;
	display:flex;
	align-items:center;
	justify-content:center;
	z-index:1;
	min-height:70px;
	background:rgba(255, 255, 255, 1);
	text-align:center;
	transform:scale(0.75);
	opacity:0;
	transition:transform 0.2s ease, opacity 0.2s ease;
}

.c-time-status-card--toolbar-visible .c-time-status-card__tools,
.c-time-status-card:hover .c-time-status-card__tools {
	transform:scale(1);
	opacity:1;
}
