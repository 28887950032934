.c-link-ending {
    border-top: 1px solid #e5e5e5;
    padding-top: 15px;
}

.c-link-ending__colored {
    border-color: var(--color-link);
}

.c-link-ending__colored .e-styled-link {
    color: var(--color-link);
}