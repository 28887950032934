/* =============================================================================
 * Button
 *
 * 1. Needed to reset any styles set on a:visited.
============================================================================= */
.c-button,
.c-button:visited /* [1] */ {
	@mixin text-style-xi;
	display:inline-block;
	width:auto;
	overflow:visible;
	cursor:pointer;
	outline:none;
	color:var(--color-link);
	background-color:transparent;
	text-decoration:none;
	text-align:center;
	position: relative;
	border: 0;
	transition:color 0.15s ease, background-color 0.15s ease, background-image 0.15s ease, border-color 0.15s ease, transform 0.15s ease;
}

/* Block
 ---------------------------------------------------------------------------- */
.c-button--block {
	width:100%;
}

.c-button--small .c-button--beta-symbol,
.c-button--small .c-button--beta-symbol:visited {
	font-size: 10px;
	margin-left: 25px;
}

/* Symbol
 ---------------------------------------------------------------------------- */
 .c-button__symbol {
	font-size:12px;
	margin-right:6px;
 }

/* Centered
 ---------------------------------------------------------------------------- */
.c-button--centered {
	z-index: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* alpha
 ---------------------------------------------------------------------------- */
.c-button--alpha,
.c-button--alpha:visited {
	background-color:var(--color-link);
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
	color:#fff;
	padding: 14px 30px;
	transform:translateY(0px);
}
.c-button--alpha:hover,
.c-button--alpha:focus {
	background: var(--color-iota);
	transform:translateY(-2px);
}
.c-button--alpha-symbol {
   margin-right:10px;
   font-size:24px;
   vertical-align:-5px;
}

/* beta
 ---------------------------------------------------------------------------- */
.c-button--beta,
.c-button--beta:visited {
	background-color:var(--color-link);
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
	color:#fff;
	padding: 14px 30px;
	transform:translateY(0px);
}
.c-button--beta:hover,
.c-button--beta:focus {
	background: var(--color-iota);
	transform:translateY(-2px);
}
.c-button--beta-symbol {
	margin-left: 15px;
	position: relative;
}

/* gamma
 ---------------------------------------------------------------------------- */
.c-button--gamma,
.c-button--gamma:visited {
	color: var(--color-link);
	padding: 12px 30px;
	border: 2px solid var(--color-link);
	transform:translateY(0px);
}
.c-button--gamma:hover,
.c-button--gamma:focus {
	background-color: var(--color-link);
	color: #fff;
	border-color: var(--color-link);
	transform:translateY(-2px);
}

/* delta
 ---------------------------------------------------------------------------- */
.c-button--delta,
.c-button--delta:visited {
	color: #fff;
	background: transparent;
	border: 2px solid #fff;
	padding: 14px 30px;
	transform:translateY(0px);
}
.c-button--delta:hover,
.c-button--delta:focus {
	color: #000;
	background: #fff;
	transform:translateY(-2px);
}

/* epsilon
 ---------------------------------------------------------------------------- */
 .c-button--epsilon,
 .c-button--epsilon:visited {
	background: var(--color-gamma);
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
	padding: 14px 30px;
	color: #fff;
	transform:translateY(0px);
 }

 .c-button--epsilon:hover,
 .c-button--epsilon:focus {
	 background: var(--color-iota);
	 transform:translateY(-2px);
 }

 .c-button--epsilon[disabled],
 .c-button--epsilon:disabled {
	 color:#fff;
	 opacity:0.6;
	 filter:grayscale(100%);
	 pointer-events:none;
 }

/* zeta
---------------------------------------------------------------------------- */
.c-button--zeta,
.c-button--zeta:visited {
	background-color:var(--color-link);
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
	color:#fff;
	padding: 12px 30px 12px 20px;
	transform:translateY(0px);
}

.c-button--zeta:hover,
.c-button--zeta:focus {
	background: var(--color-iota);
	transform:translateY(-2px);
}

/* eta
 ---------------------------------------------------------------------------- */
.c-button--eta,
.c-button--eta:visited {
	color: #fff;
	background: transparent;
	border: 2px solid #fff;
	padding: 14px 30px;
	transform:translateY(0px);
}

.c-button--eta:hover,
.c-button--eta:focus {
	color: #000;
	background: #fff;
	transform:translateY(-2px);
}

.c-button--eta__symbol {
	font-size: 10px;
	margin-right: 8px;
	color: #fff;
}

.c-button--eta__symbol--dark {
	color:#333;
}

.c-button--eta:hover .c-button--eta__symbol,
.c-button--eta:focus .c-button--eta__symbol{
   color: var(--color-beta);
}

.c-button--eta:hover .c-button--eta__symbol--dark,
.c-button--eta:focus .c-button--eta__symbol--dark{
   color: #fff;
}

/* theta
---------------------------------------------------------------------------- */
.c-button--theta,
.c-button--theta:visited {
	border: 1px solid #fff;
	border-radius: 40px;
	padding: 6px 20px;
	color: #fff;
}

.c-button--theta:hover,
.c-button--theta:focus {
	color: var(--color-link);
	background: #fff;
}

/* iota (Needs branding)
---------------------------------------------------------------------------- */
.c-button--iota,
.c-button--iota:visited {
	border: 2px solid var(--color-delta);
	/*border-radius: 40px;*/
	padding: 6px 20px;
	color: var(--color-delta);
	transform:translateY(0px);
}

.c-button--iota:hover,
.c-button--iota:focus {
	color: #fff;
	background: var(--color-delta);
	transform:translateY(-2px);
}

/* Kappa
---------------------------------------------------------------------------- */
.c-button--kappa,
.c-button--kappa:visited {
   color: var(--color-gamma);
   padding: 11px 30px;
   border: 2px solid var(--color-gamma);
   transform:translateY(0px);
}
.c-button--kappa:hover,
.c-button--kappa:focus {
   background-color: var(--color-gamma);
   color: #fff;
   border-color: var(--color-gamma);
   transform:translateY(-2px);
}
.c-button--kappa-symbol {
	margin-right: 5px;
	vertical-align:-1px;
}

/* lambda
---------------------------------------------------------------------------- */
 .c-button.c-button--lambda,
 .c-button.c-button--lambda:visited {
	background: linear-gradient(#fff, #f0f0f0);
	border: 1px solid #dfdfdf;
	border-radius: 30px;
	padding: 8px 20px;
	color: #000;
	font-weight:200;
 }

 .c-button--lambda:hover,
 .c-button--lambda:focus {
	background: linear-gradient(#fff, #e4e4e4);
	border-color:#c6c6c6;
 }

 /* Mu
  ---------------------------------------------------------------------------- */
 .c-button--mu,
 .c-button--mu:visited {
	color: var(--color-gamma);
	padding: 12px 30px;
	border: 2px solid var(--color-gamma);
	transform:translateY(0px);
 }
 .c-button--mu:hover,
 .c-button--mu:focus {
	background-color: var(--color-gamma);
	color: #fff;
	border-color: var(--color-gamma);
	transform:translateY(-2px);
 }
 .c-button--mu-symbol {
	margin-right:10px;
	font-size:24px;
	vertical-align:-5px;
}

/* Cancel
----------------------------------------------------------------------------- */
.c-button--cancel {
	font-weight:normal;
	text-decoration:underline;
}

/* Center horizontally
----------------------------------------------------------------------------- */
.c-button--center-horizontally {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

.c-button--center-horizontally:focus,
.c-button--center-horizontally:hover {
	transform: translateX(-50%);
}

/* Small variant
 ---------------------------------------------------------------------------- */
.c-button--small,
.c-button--small:visited {
	padding: 10px 20px;
	font-size: 14px;
}

/* Print
---------------------------------------------------------------------------- */
@media print {
	.c-button {
		display:none;
	}
}

/* Disabled
---------------------------------------------------------------------------- */
.c-button[disabled],
.c-button:disabled {
	color:#fff;
	opacity:0.35;
	filter:grayscale(100%);
	pointer-events: none;
	box-shadow: none;
}

.c-button[disabled]:hover,
.c-button:disabled:hover,
.c-button[disabled]:focus,
.c-button:disabled:focus, {
	transform: none;
}
