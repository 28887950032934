/* Tables
 ---------------------------------------------------------------------------- */
.s-table table,
table.s-table {
	margin-bottom: 30px;
	line-height: normal;
}

.s-table caption,
caption.s-table {
	@mixin text-style-eta;
	border-bottom: 1px solid #ccc;
	padding-bottom: 15px;
}

.s-table th,
th.s-table {
	@mixin text-style-iota;
	padding: 5px 10px;
	border-bottom: 1px solid #ccc;
}

.s-table thead tr:first-child th {
	@mixin text-style-beta;
	padding-bottom:10px;
}

.s-table thead tr:not(:only-child):first-child th {
	border-bottom-color:#eee;
	color:#000;
}

.s-table thead tr:not(:first-child) th {
	@mixin text-style-nu;
	padding-top:20px;
	color:#000;
}

.s-table td,
td.s-table,
.s-table tbody th,
tbody th.s-table {
	padding: 18px 10px;
	border-bottom: 1px solid #ccc;
}

.s-table tfoot,
tfoot.s-table {
	font-weight: bold;
}

.s-table tbody tr:nth-child(odd),
tbody.s-table tr:nth-child(odd) {
	background-color: #FDFDFD;
}

.s-table tbody td,
.s-table tbody th {
	transition: background-color 0.15s ease;
}

.s-table tbody tr:hover td,
.s-table tbody tr:hover th,
tbody.s-table tr:hover td,
tbody.s-table tr:hover th {
	background: #eee;
}

.s-table a,
a.s-table {
	border-bottom: 0 !important;
}
