/* =============================================================================
 * Text scope
 *
 * Adds styling to elements when inside the scope .s-text, e.g. margin
 * or another font-weight than 'normal'.
============================================================================= */

@media print {
	.s-text {
		font-size:0.85em;
		line-height:1.4;
	}
}

/* Margins
 * Weird syntax for clarity's sake.
 ---------------------------------------------------------------------------- */
.s-text h1,         h1.s-text,
.s-text h2,         h2.s-text,
.s-text h3,         h3.s-text,
.s-text h4,         h4.s-text,
.s-text h5,         h5.s-text,
.s-text h6,         h6.s-text,
.s-text ul,         ul.s-text,
.s-text ol,         ol.s-text,
.s-text dl,         dl.s-text,
.s-text blockquote, blockquote.s-text,
.s-text p,          p.s-text,
.s-text address,    address.s-text,
.s-text hr,         hr.s-text,
.s-text table,      table.s-text,
.s-text fieldset,   fieldset.s-text,
.s-text figure,     figure.s-text,
.s-text pre,        pre.s-text {
    /* generic margin bottom */
}

/* Paragraphs and links
 ---------------------------------------------------------------------------- */
.s-text p,
p.s-text {
	margin-bottom: 25px;
}
.s-text a,
a.s-text {
	color: var(--color-link);
	transition:color 0.15s ease;
	border-bottom: 1px solid var(--color-link);
}
.s-text a:hover,
a.s-text:hover {
	color: var(--color-link);
}
.s-text--inverted[class] a,
.s-text--inverted[class] a:visited,
a.s-text--inverted[class],
a.s-text--inverted[class]:visited {
	color:rgb(255, 255, 255);
	border-bottom:1px solid rgb(255, 255, 255);
}
.s-text--inverted[class] a:hover,
.s-text--inverted[class] a:focus,
a.s-text--inverted[class]:hover,
a.s-text--inverted[class]:focus {
	color:rgb(255, 255, 255);
	border-bottom-color:rgba(255, 255, 255, 0);
}

@media print {
	.s-text p,
	p.s-text {
		margin-bottom:13px;
	}
}

/* Dialog button
 ---------------------------------------------------------------------------- */
.js .s-text button[data-open-dialog="true"] {
	border-bottom:1px solid rgba(0, 0, 0, 0.3);
	line-height:1.2;
}
.js .s-text button[data-open-dialog="true"]:hover,
.js .s-text button[data-open-dialog="true"]:focus {
	border-bottom-color:inherit;
}

/* Headings
 ---------------------------------------------------------------------------- */
.s-text h1,
h1.s-text {
	@mixin text-style-epsilon;
	margin-bottom:5px;
}
.s-text h2,
h2.s-text {
	@mixin text-style-zeta;
	margin-bottom: 5px;
}

.s-text h3,
h3.s-text {
	@mixin text-style-eta;
	margin-bottom: 5px;
}

.s-text h4,
h4.s-text {
	@mixin text-style-theta;
	margin-bottom: 5px;
}

.s-text h5,
h5.s-text {
	@mixin text-style-iota;
	margin-bottom: 5px;
}

@media print {
	.s-text h3,
	h3.s-text {
		font-size:18px;
		margin-bottom:5px;
	}
}


/* Inline typography
 ---------------------------------------------------------------------------- */
.s-text i, i.s-text,
.s-text em, em.s-text {
	font-style:italic;
}

.s-text b, b.s-text,
.s-text strong, strong.s-text {
	font-weight:700;
}

/* Lists
 *
 * 1. Remove trailing margins from nested lists.
 ---------------------------------------------------------------------------- */
.s-text ul, ul.s-text {
	list-style:none;
	position: relative;
	margin-bottom: 16px;
}
.s-text ul, ul.s-text,
.s-text ol, ol.s-text,
.s-text dd, dd.s-text {
	margin-left:30px;
}
.s-text li,
li.s-text {
	list-style: none;
	margin-bottom:5px;
	position: relative;
}
.s-text ul li::before,
ul.s-text li::before {
	content: '';
	width: 8px;
	height: 8px;
	display: inline-block;
	position: absolute;
	top: 12px;
	left: -30px;
	background: var(--color-link);
	border-radius: 100%;
}
.s-text li > ul {
	margin-top:5px;
	margin-bottom:0; /* [1] */
}

/*
 * Ordered list
 */
.s-text ol,
ol.s-text {
	list-style:none;
	counter-reset:li;
	margin-bottom: 16px;
}
.s-text ul ol,
.s-text ol ol,
ul.s-text ol,
ol.s-text ol {
	margin:10px 0;
}
.s-text ol > li,
ol.s-text > li {
	position:relative;
	margin-bottom:10px;
	counter-increment:li; /* Increment the counter by 1 */
}
.s-text ol > li:before,
ol.s-text > li:before {
	position:absolute;
	left:-30px;
	width:30px;
	color:var(--color-link);
	content:counter(li) "."; /* Use the counter as content */
	font-weight:bold;
}

/*
 * Reposition list bullets and numbers in centered lists
 */
.t-text-align-center .s-text ul li::before,
.t-text-align-center ul.s-text li::before {
	position:static;
	margin-right:0.5em;
}
.t-text-align-center .s-text ol > li::before,
.t-text-align-center ol.s-text > li::before {
	position:static;
	content:counter(li) ". ";
}

/*
 * Definition list
 */
.s-text dt {
	font-weight:700;
}
.s-text dd {
	margin-bottom:10px;
}
