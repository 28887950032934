/* =============================================================================
 * Text align
============================================================================= */
@media screen {
	.t-text-align-left {
		text-align:left;
	}
	.t-text-align-center {
		text-align:center;
	}
	.t-text-align-right {
		text-align:right;
	}
}
