.t-mobile-only {
	display: none;
}

@media(--small) {
	.t-desktop-only.t-desktop-only {
		display: none;
	}

	.t-mobile-only.t-mobile-only {
		display: block;
	}
}

@media(--medium) {
	.t-hide-for-medium-down {
		display: none !important;
	}
}

@media(--small) {
	.t-hide-for-small {
		display: none !important;
	}
}