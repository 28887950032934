/* =============================================================================
 * Confirm dialog
 *
 * 1. Rules cannot be combined as older browsers might choke on the
 *    ::backdrop declaration and discard the whole rule.
============================================================================= */

/* Basic styles */
@import "vendor/dialog-polyfill-master/dialog-polyfill.css";

/* Override dialog-polyfill */

dialog {
	position: fixed !important;
	top: 0 !important;
}

/* Site styles */
body.dialog-open {
	height: 100vh;
	overflow-y: hidden;
	padding-right: 15px; /* To remove width reflow when we remove the scrollbar with overflow-y: hidden */
}

@media(--small) {
	body.dialog-open {
		padding-right: 0; /* Mobile doesn't need to adjust for scrollbar */
	}
}

.c-dialog {
	width:1045px;
	max-width: 95vw;
	background: #fff;
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.20);
	border: 0;
	padding: 100px 0 70px;
	min-height: 80px;
}


/* Can be removed after 2022-06-06 */
/* @media(--small) {
	.c-dialog {
		padding: 90px 0 20px;
	}
} */

/* .portal .c-dialog {
	width: 734px;
	padding: 100px 0 20px;
}
@media(--small) {
	.portal .c-dialog {
		padding-top:70px;
	}
} */

.c-dialog__inner {
	padding: 30px 70px;
}

@media(--small) {
	.c-dialog__inner {
		padding-top:0;
		padding-left:15px;
		padding-right:15px;
	}
}

.c-dialog__inner--gray {
	padding: 70px 70px 40px;
	/*max-height: 430px;*/
	overflow-y: auto;
	background: var(--color-eta);
}

@media(--small) {
	.c-dialog__inner--gray {
		padding: 10px;
	}
}

.c-dialog__header {
	text-align: center;
	padding: 23px 0 30px;
	position: relative;
}
.c-dialog__preamble {
	margin-top:15px;
}

.c-dialog__shadow-top {
	position: absolute;
	width: 100%;
	height: 20px;
	z-index: 2;
	box-shadow: 0 10px 10px -10px rgba(0,0,0,0.1) inset;
}

.c-dialog__shadow-bottom {
	position: absolute;
	top: -20px;
	left: 0;
	width: 100%;
	height: 20px;
	z-index: 2;
	box-shadow: 0 -10px 10px -10px rgba(0,0,0,.1) inset;
}

.c-dialog__footer {
	text-align: center;
	padding-top: 26px;
	position: relative;
}

.c-dialog__footer--tight {
	padding-top: 0;
	margin-top: -30px;
}

.c-dialog__footer-submit {
	display: block;
	margin: 0 auto;
}

.c-dialog__footer-link {
	display: inline-block;
	margin-top: 15px;
	color: var(--color-link);
}

.c-dialog__heading {
	@mixin text-style-zeta;
}

/* Loading styles
-------------------------------------------------------------- */
.c-dialog__close-button {
	position: absolute;
	top: 20px;
	right: 20px;
}
.c-dialog__close-button:focus {
	outline:1px dotted #000;
}

@media(--small) {
	.c-dialog__close-button {
		top: 20px;
	}
}

.c-dialog--loading.c-dialog {
	padding: 0;
}

.c-dialog--loading .c-dialog__close-button {
	top: 50%;
	left: auto;
	right: 20px;
	transform: translateY(-50%);
}

@media(--small) {
	.c-dialog--loading .c-dialog__close-button {
		top: 50%;
	}
}

.c-icon-button__icon {
	content: '\e905';
	font-family: 'icomoon';
	font-size: 30px;
	color: var(--color-iota);
}

.c-dialog__close-button-label {
	display: block;
	line-height: normal;
}

/* Animations
-------------------------------------------------------------- */
.c-dialog[open] {
	animation:c-dialog--open 0.1s ease-in-out;
}

@keyframes c-dialog--open {
	0% {
		opacity:0;
		transform: translateY(15px) scale(0.95);
	}
	100% {
		opacity: 1;
		transform: translateY(0) scale(1);
	}
}

/* Backdrop
 *
 * 1. Rules cannot be combined as older browsers might choke on
 *    the ::backdrop declaration and discard the whole rule.
-------------------------------------------------------------- */
.c-dialog::backdrop /*[1]*/ {
	background-color: rgba(0, 0, 0, 0.5);
	animation:c-dialog--backdrop 0.4s ease-in-out;
}

.c-dialog + .backdrop /*[1]*/ {
	background-color: rgba(0, 0, 0, 0.5);
	animation:c-dialog--backdrop 0.4s ease-in-out;
}

@keyframes c-dialog--backdrop {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* Inline dialogs
 * Hide elements with content that will potentially be displayed in a dialog.
----------------------------------------------------------------------------- */
.c-dialog__inline-content {
	display:none;
}
.c-dialog__content > .c-dialog__inline-content {
	display: block;
}