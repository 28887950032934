.c-collection-heading {
	@mixin text-style-beta;
	border-bottom: 1px solid #DBDBDB;
	margin-bottom: 30px;
	padding-bottom:10px;
}
.c-collection-heading__count {
	color: #707070;
	margin-left: 5px;
}
