/* =============================================================================
 * Event list
 *
 * [1] Context for the link's pseudo element
 * [2] Make the whole event clickable
============================================================================= */
.c-event-list__year-heading {
	@mixin text-style-zeta;
	margin-bottom:20px;
	margin-top:40px;
}
.c-event-list__month-heading {
	@mixin text-style-beta;
	margin-bottom:5px;
}
.c-event-list__list {
	margin-bottom:30px;
}
.c-event-list__event {
	position:relative; /* [1] */
	border:solid var(--color-zeta);
	border-width:1px 0;
	padding:20px 10px;
}
.c-event-list__event:nth-child(odd) {
	background-color:rgba(0, 0, 0, 0.015);
}
.c-event-list__event + .c-event-list__event {
	margin-top:-1px;
}
.c-event-list__link {
	@mixin text-style-beta;
	display:block;
	color:var(--color-link);
	margin-bottom:5px;
	transition:color 0.2s ease;
}
.c-event-list__link:hover,
.c-event-list__link:focus {
	color:#000;
}
.c-event-list__link::before {
	/* [2] */
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	content:"";
}
