/* =============================================================================
 * Loader
============================================================================= */
body #epi-quickNavigator {
	top: -10px;
	transition: top .2s ease;
}

body #epi-quickNavigator:hover {
	top: 0;
}