/* =============================================================================
 * Sub navigation
============================================================================= */
.c-sub-navigation {
	padding: 7px 0;
	border-bottom: 1px solid var(--color-gray);
	line-height:1rem;
}

.c-sub-navigation__item {
	margin-right: 3.0997%; /* 23px/742px */
}

.c-sub-navigation__item:last-child {
	margin-right: 0;
}

.c-sub-navigation__link,
.c-sub-navigation__link:visited {
	padding-bottom: 2px;
	@mixin text-style-gamma;
	color: var(--color-link);
}

.c-sub-navigation__link:hover {
	color: var(--color-link);
	border-bottom: 2px solid var(--color-delta);
}

.c-sub-navigation__link--selected,
.c-sub-navigation__link--selected:visited {
	font-weight: 400;
	color: var(--color-link);
	border-bottom: 2px solid var(--color-delta);
}

.c-sub-navigation__list {
	text-align: center;
}

.c-sub-navigation__list:not(.c-sub-navigation__list--level-1) {
	padding-left:20px;
}

@media print {
	.c-sub-navigation {
		display:none;
	}
}
