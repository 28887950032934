/* =============================================================================
 * Evaluation
============================================================================= */
.c-evaluation {
	margin-top:40px;
}
.c-evaluation__portrait {
	border-radius:50%;
	width:100px;
}
@media(--small) {
	.c-evaluation {
		text-align:center;
	}
	.c-evaluation__portrait {
		margin-bottom:10px;
	}
}


/* Meta
---------------------------------------------------------------------------- */
.c-evaluation__meta {
	margin-bottom:5px;
}
.c-evaluation__meta-separator {
	display:inline-block;
	width:0;
	overflow:hidden;
	border-right:1px solid #555;
	margin:0 10px;
	line-height:1;
	vertical-align:-2px;
}


/* Content
 *
 * [1] Try to align first letter with the row below
---------------------------------------------------------------------------- */
.c-evaluation__content {
	@mixin text-style-alpha;
}
.c-evaluation__content > :first-child::before {
	content:"“";
	margin-left:-0.25em; /* [1] */
}
.c-evaluation__content > :last-child::after {
	content:"“";
}


/* Seal
---------------------------------------------------------------------------- */
.c-evaluation__seal {
	@mixin text-style-nu;
	margin-top:-10px;
}
.c-evaluation__seal-icon {
	display:inline-flex;
	align-items:center;
	justify-content:center;
	width:30px;
	height:30px;
	border-radius:50%;
	color:#fff;
	font-size:11px;
	margin-right:10px;
	vertical-align:2px;
}
.c-evaluation--approved .c-evaluation__seal-icon {
	background-color:var(--color-approved);
}
