/* =============================================================================
 * Count
 * Counter to indicate the number of “things”, eg. unread comments
============================================================================= */
.c-count {
	display:inline-block;
	min-width:20px;
	padding:3px 5px;
	border-radius:10px;
	box-shadow:0 2px 4px rgba(0, 0, 0, 0.3);
	background:var(--color-epsilon);
	color:#fff;
	font:var(--type-defaults);
	line-height:1;
	text-align:center;
	opacity:1;
	transition:transform 0.5s ease-in-out;
}
.c-count--disappear {
	transform:scale(0);
}
