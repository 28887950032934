.t-flex {
	display:flex;
}
.t-inline-flex {
	display:inline-flex;
}
.t-flex-row {
	flex-direction:row;
}
.t-flex-row-reverse {
	flex-direction:row-reverse;
}
.t-flex-column {
	flex-direction:column;
}
.t-flex-no-grow {
	flex-grow:0;
}
.t-flex-grow {
	flex-grow:1;
}
.t-flex-no-shrink {
	flex-shrink:0;
}
.t-flex-shrink {
	flex-shrink:1;
}
.t-flex-no-wrap {
	flex-wrap:nowrap;
}
.t-flex-wrap {
	flex-wrap:wrap;
}
.t-flex-wrap-reverse {
	flex-wrap:wrap-reverse;
}


@media screen and (min-width:700px) {
	.t-flex\@md {
		display:flex;
	}
	.t-inline-flex\@md {
		display:inline-flex;
	}
	.t-flex-row-reverse\@md {
		flex-direction:row-reverse;
	}
}

@media screen and (min-width:900px) {
	.t-flex\@lg {
		display:flex;
	}
	.t-inline-flex\@lg {
		display:inline-flex;
	}
	.t-flex-row-reverse\@lg {
		flex-direction:row-reverse;
	}
}
