/* =============================================================================
 * Main navigation
 *
 * Hide for mobile + tablet
============================================================================= */
.c-main-navigation,
.c-sub-navigation,
.c-horistontal-links {
	display: block;
}

.hamburger-only .c-main-navigation {
	display: none;
}

.c-main-navigation {
	padding: 6px 0;
	border-top: 1px solid var(--color-zeta);
	border-bottom: 1px solid var(--color-zeta);
	line-height:1rem;
}

.c-main-navigation__list {
	text-align: center;
}

.c-main-navigation__item {
	margin-right: 4.4915%; /* 53px/1180px */
	padding:5px 0 6px 0;
}

.c-main-navigation__item:last-child {
	margin-right: 0;
}

.c-main-navigation__link,
.c-main-navigation__link:visited {
	@mixin text-style-beta;
	padding-bottom: 3px;
	color: var(--color-gamma);
	font-size:15px;
	border-bottom: 2px solid transparent;
	transition: border-color 0.2s ease;
}

.c-main-navigation__link:hover,
.c-main-navigation__link--selected,
.c-main-navigation__link:focus {
	border-color: var(--color-delta);
}

@media(--medium) {
	.c-main-navigation,
	.c-sub-navigation,
	.c-horistontal-links {
		display: none;
	}
}

.c-main-navigation__outer-limiter {
	position: relative;
}

@media print {
	.c-main-navigation {
		display:none;
	}
}
