.t-background-alpha {
	background: var(--color-eta);
	padding: 50px 0;
	border-top: 1px solid #E3E3E3;
	border-bottom: 1px solid #E3E3E3;
}

.t-background-alpha--nudged-up {
	margin-top:-21px;
}

.t-background-alert-danger {
	color: #842330;
    background-color: #f2dede;
    border-color: #ebccd1;
	padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.t-background-beta {
	background: var(--color-eta);
}

.t-background-gamma {
	background: #fff;
	padding: 50px 0;
}

.t-background-white {
	background: #fff;
}

@media(--medium) {
	.t-background-alpha,
	.t-background-gamma {
		padding: 25px 0;
	}
}

@media(--small) {
	.t-background-alpha,
	.t-background-gamma {
		padding: 15px 0;
	}
}

@media print {
	[class*="t-background-"] {
		page-break-inside:avoid;
	}
	.t-background-alpha {
		border:none;
	}
	[class*="t-background-"] {
		padding-top:0;
		padding-bottom:0;
		page-break-inside:avoid;
	}
}
