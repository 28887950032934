/* =============================================================================
 * Reset
============================================================================= */
* {
	margin:0;
	padding:0;
	box-sizing:border-box;
	font:inherit;
	font-weight:inherit;
	font-style:inherit;
}
