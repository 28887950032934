.c-card {
	position: relative;
  background: #fff;
  border: 1px solid #dbdbdb;
  margin: 0 0 calc(var(--spacing--vertical-spacing-unit) * 0.5);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.c-card:hover,
.c-card:focus {
	box-shadow:0 0 4px rgba(0, 0, 0, 0.1);
}

@media(--small) {
	.c-card {
		margin: 0 0 calc(var(--spacing--vertical-spacing-unit-mobile) * 0.5);
	}
}

.c-card__link {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	align-items: center;
	padding: 25px 25px 7px;
	border-bottom: 1px solid #dbdbdb;
	color: var(--color-beta);
	text-align: center;
}

.c-card__link--vertical-center {
	padding: 20px 25px 20px;
	justify-content: center;
}

.c-card__header {
	max-width: 100%;
}

/* Typography */
.c-card__heading {
	@mixin text-style-zeta;
	color: var(--color-gamma);
	margin-bottom: 13px;
}

.c-card__sub-heading {
	color: #505050;
	font-size: 12px;
	line-height: 1.4;
	margin-bottom: 13px;
	text-transform: uppercase;
}

@media (--medium) {
	.c-card__sub-heading {
		font-size: 12px;
	}
}

.c-card__content {
	margin-top:auto;
	margin-bottom:auto;
}

.c-card__description {
	margin-bottom: 26px;
}

.c-card__description--gray {
	margin-top: 12px;
	margin-bottom: 0;
	color: #898989;
}

@media (--medium) {
	.c-card__info {
		font-size: 12px;
	}
}

.c-card__long-desc {
	margin-bottom:10px;
}

.c-card__icon {
	display: inline-block;
	margin-bottom: 10px;
}

.c-card__icon--large {
	margin:25px 0 20px;
	height:60px;
}

/* Tools */
.c-card__tools {
	display: flex;
}

.c-card__tools-link {
	display:block; /* IE10 */
	flex-grow:1;
	border-right: 1px solid #dbdbdb;
	text-align: center;
	padding: 10px;
	transition: background-color 0.15s ease;
}

.c-card__tools-link:hover,
.c-card__tools-link:focus {
	background:var(--color-link);
	color:#fff;
	transition: color 0.15s ease;
}

.c-card__tools-link:hover .c-card__tools-link-icon,
.c-card__tools-link:focus .c-card__tools-link-icon {
	color:#fff;
	transition: color 0.15s ease;
}

.c-card__tools-link--read-more {
	display:block;
	color:#000;
	text-align:center;
	flex-grow:1;
	padding:5px 0;
}

.c-card__tools-link:last-child {
	border-right: 0;
}

.c-card__tools-link:only-child {
	width: 100%;
}

.c-card__tools-link-icon {
	font-size: 19px;
	color: var(--color-beta);
	vertical-align: middle;
}

.c-card__tools-link--read-more .c-card__tools-link-icon {
	font-size:10px;
}

/* Levels */
.c-card__levels {
	margin-bottom: 5px;
}

/* Video variant
 ---------------------------------------------------------------------------- */
.c-card--video {
	margin: 0;
	border-left: 0;
}

.c-card--video.c-card--block {
	margin: 0;
}

@media(--medium) {
	.c-card--video {
		border-left: 1px solid #dbdbdb;
	}
}

.c-card--video .c-card__link {
	align-items: flex-start;
	text-align: left;
	padding: 42px 100px 34px;
}

@media(--medium) {
	.c-card--video .c-card__link {
		align-items: center;
		text-align: center;
		padding: 42px 100px 34px;
	}
}

.c-card__logo {
	position: absolute;
	top: 0;
	right: 0;
	max-width: 42px;
	max-height: 42px;
}


/* Block variant
 ---------------------------------------------------------------------------- */
.c-card--block {
	margin: calc(var(--spacing--vertical-spacing-unit) * 0.5) 0;
}

@media(--small) {
	.c-card--block {
		margin: calc(var(--spacing--vertical-spacing-unit-mobile) * 0.5) 0;
	}
}

.c-card--block .c-card__logo {
	max-width: none;
	max-height: none;
}

.c-card__context-heading {
	display: inline-block;
	font-size: 13px;
	text-transform: uppercase;
	color: var(--color-beta);
	letter-spacing: 0px;
	margin-bottom: 5px;
}

.c-card--block .c-card__heading {
	font-size: 24px;
}

.c-card--block .c-card__description {
	font-size: 16px;
}

.c-card--block .c-card__info {
	font-size: 14px;
}

.c-card--block .c-levels__level {
	width: 15px;
	height: 15px;
}

.c-card--block .c-card__sub-heading {
	margin-bottom: 13px;
}

.c-card--block .c-card__icon {
	width: 47px;
	margin-bottom: 26px;
}

.c-card--block .c-levels {
	margin-bottom: 15px;
}

/* Print
 ---------------------------------------------------------------------------- */
@media print {
	.c-card {
		page-break-inside:avoid;
	}
	.c-card__tools {
		display:none;
	}
}
