.c-accordion__fold {
	border-bottom: 1px solid #eaeaea;
}

.c-accordion--in-nested-columns .c-accordion__fold {
	border-bottom: 0 none;
}

.c-accordion__fold:first-child {
	border-top: 1px solid #eaeaea;
}

.c-accordion__fold .c-accordion__fold {
	margin-top: 25px;
}

.c-accordion__label {
	@mixin text-style-alpha;
	display: flex;
	align-items: center;
	padding: 18px 0;
	cursor: pointer;
}

.c-accordion__title {
	@mixin text-style-xi;
	display: block; /* IE10 */
	margin-left: 20px;
	border-bottom: 1px solid var(--color-link);
	color: var(--color-link);
	user-select: none;
}

@media (--small) {
	.c-accordion__title {
		padding-bottom: 0;
		border-bottom: 0;
	}
}

.c-accordion__label-icon {
	margin-left: auto;
}
.c-accordion__label-icon [class^="icon-"] {
	font-size: 24px;
}

.c-accordion__done {
	margin-left: auto;
}
.c-accordion__done [class^="icon-"] {
	color: #00bdc5;
	font-size: 24px;
}
.c-accordion__in-progress {
	margin-left: auto;
}
.c-accordion__in-progress [class^="icon-"] {
	color: #a9a9a9;
	font-size: 24px;
}

.c-accordion__expander,
.c-accordion__expander--expanded {
	display: inline-block;
	border-radius: 2px;
	color: var(--color-link);
	font-size: 22px;
	vertical-align: middle;
}
.c-accordion__expander--expanded {
	transform: rotate(180deg);
}
.c-accordion__expander:focus {
	outline: none;
	color: #000;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.5);
}
.c-accordion__expander:focus + .c-accordion__title {
	color: #000;
	border-bottom-color: inherit;
}

.c-accordion__intro {
	padding: 0 0 25px 42px;
}

.c-accordion__content {
	padding: 0 0 25px 42px;
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
	.c-accordion-small h4 {
		font-size: 16px !important;
	}
}

.c-accordion-small .c-teaser__headline--secondary,
.c-accordion-small .c-teaser__description,
.c-accordion-medium .c-teaser__description {
	display: none;
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
	.c-accordion-medium h2 {
		font-size: 20px !important;
	}

	.c-accordion-medium .c-teaser__text-wrapper {
		padding: 20px 5.9322% !important;
	}
}

@media print {
	.c-accordion__fold {
		page-break-inside: avoid;
	}
	.c-accordion__title[class] {
		color: #000;
		margin-left: 0;
	}
	.c-accordion__expander {
		display: none;
	}
	.c-accordion__content {
		display: block !important;
		padding-left: 0;
	}
}

/* “Stripe” version of c-accordion__fold that looks similar to c-stripe
 *
 * 1. Double class selectors to increase specificity
----------------------------------------------------------------------------- */
.c-accordion__fold--stripe.c-accordion__fold--stripe {
	/* 1 */
	margin-top: 0;
}
.c-accordion__fold--stripe {
	margin-bottom: 10px;
	border: 1px solid #dbdbdb;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.07);
	background: #fff;
}
.c-accordion__fold--stripe .c-accordion__label {
	padding: 10px;
}
.accordion-hidden{
display: none;

}
