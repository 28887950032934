@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?7awfvlx');
  src:  url('icomoon.eot?7awfvlx#iefix') format('embedded-opentype'),
    url('icomoon.ttf?7awfvlx') format('truetype'),
    url('icomoon.woff?7awfvlx') format('woff'),
    url('icomoon.svg?7awfvlx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-pin:before {
  content: "\e93d";
}
.icon-copy:before {
  content: "\e93b";
}
.icon-crown:before {
  content: "\e93c";
}
.icon-dislike:before {
  content: "\e933";
}
.icon-draft:before {
  content: "\e934";
}
.icon-like:before {
  content: "\e935";
}
.icon-liked:before {
  content: "\e936";
}
.icon-rejected:before {
  content: "\e937";
}
.icon-reply:before {
  content: "\e938";
}
.icon-unlock:before {
  content: "\e939";
}
.icon-new-comment:before {
  content: "\e932";
}
.icon-clock:before {
  content: "\e930";
}
.icon-glasses:before {
  content: "\e931";
}
.icon-print:before {
  content: "\e92f";
}
.icon-horizontal-line-dashed:before {
  content: "\e92d";
}
.icon-horizontal-line:before {
  content: "\e92e";
}
.icon-arrow-up-left:before {
  content: "\e92b";
}
.icon-arrow-up-right:before {
  content: "\e92c";
}
.icon-arrow-rotated:before {
  content: "\e92a";
}
.icon-warning:before {
  content: "\e926";
}
.icon-success:before {
  content: "\e927";
}
.icon-info:before {
  content: "\e928";
}
.icon-error:before {
  content: "\e929";
}
.icon-list:before {
  content: "\e925";
}
.icon-remove:before {
  content: "\78";
}
.icon-arrow-up3:before {
  content: "\e914";
}
.icon-arrow-down3:before {
  content: "\e916";
}
.icon-arrow-up:before {
  content: "\e912";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-up2:before {
  content: "\e904";
}
.icon-arrow-down2:before {
  content: "\e903";
}
.icon-close:before {
  content: "\e905";
}
.icon-menu:before {
  content: "\e906";
}
.icon-user:before {
  content: "\e907";
}
.icon-book:before {
  content: "\e908";
}
.icon-login:before {
  content: "\e909";
}
.icon-play:before {
  content: "\e90a";
}
.icon-play-fat:before {
  content: "\e90b";
}
.icon-search:before {
  content: "\e90c";
}
.icon-facebook:before {
  content: "\e90f";
}
.icon-google-plus:before {
  content: "\e910";
}
.icon-twitter:before {
  content: "\e911";
}
.icon-quote:before {
  content: "\e913";
}
.icon-quote2:before {
  content: "\e90d";
}
.icon-arrow-down4:before {
  content: "\e90e";
}
.icon-arrow-right2:before {
  content: "\e915";
}
.icon-arrow-left2:before {
  content: "\e917";
}
.icon-plus:before {
  content: "\e918";
}
.icon-share:before {
  content: "\e919";
}
.icon-eye:before {
  content: "\e91a";
}
.icon-eye-crossed:before {
  content: "\e91b";
}
.icon-pen:before {
  content: "\e91c";
}
.icon-checked:before {
  content: "\e91d";
}
.icon-play-square:before {
  content: "\e91e";
}
.icon-plus-thin:before {
  content: "\e91f";
}
.icon-close-circle:before {
  content: "\e920";
}
.icon-hamburger-triangle:before {
  content: "\e921";
}
.icon-send:before {
  content: "\e922";
}
.icon-shopping-cart:before {
  content: "\e923";
}
.icon-external-link:before {
  content: "\e924";
}
.icon-paperclip:before {
  content: "\e942";
}
.icon-exercise:before {
  content: "\e93a";
}
.icon-not-approved:before {
  content: "\e94e";
}
.icon-8:before {
  content: "\e944";
}
.icon-10:before {
  content: "\e945";
}
.icon-15:before {
  content: "\e946";
}
.icon-20:before {
  content: "\e947";
}
.icon-arrow-swap:before {
  content: "\e948";
}
.icon-circle-hole:before {
  content: "\e949";
}
.icon-circle:before {
  content: "\e94a";
}
.icon-movebottom:before {
  content: "\e94b";
}
.icon-movetop:before {
  content: "\e94c";
}
.icon-arrow-uturn:before {
  content: "\e94d";
}
.icon-home:before {
  content: "\e93e";
}
.icon-graph-board:before {
  content: "\e93f";
}
.icon-check:before {
  content: "\e940";
}
.icon-download2:before {
  content: "\e943";
}
.icon-send2:before {
  content: "\e941";
}
.icon-third:before {
  content: "\e951";
}
.icon-fourth:before {
  content: "\e952";
}
.icon-fifth:before {
  content: "\e953";
}
.icon-star:before {
  content: "\e954";
}
.icon-star--coloured-stroke:before {
  color:var(--color-delta);
}
.icon-horizontal-line-solid-double:before {
  content: "\e94f";
}
.icon-horizontal-line-solid-dashed-double:before {
  content: "\e950";
}
.icon-link:before {
  content: "\e955";
}
.icon-checkmark-golf:before {
	content: "\e957";
}
.icon-arrow-right-golf:before {
	content: "\e956";
}
.icon-lock:before {
  content: "\e958";
}