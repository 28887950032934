.c-title-creator {
	position: relative;
	width: 100%;
	border-bottom: 1px solid #DEDEDE;
	margin-bottom: 30px;
}

.c-title-creator__input[class] {
	@mixin text-style-epsilon;
	line-height: 1.4;
	padding: 20px 70px 8px 0;
	border: 0;
	color:#000A32;
}

.c-title-creator__button {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	padding: 10px 20px;
	background: #0096DC;
	color: #fff;
	border-radius: 30px;
	opacity: 0;
	transition:opacity 0.2s ease;
	margin-top:2px;
}

.c-title-creator--changed .c-title-creator__button,
.c-title-creator__input:focus + .c-title-creator__button,
.c-title-creator__button:hover,
.c-title-creator__button:focus {
	opacity: 1;
	transform: translateY(-50%);
}
