/* =============================================================================
 * Time and place
============================================================================= */
.c-time-and-place__item[class] {
	@mixin text-style-lambda;
	margin-right:25px;
	margin-bottom:5px;
}
.c-time-and-place__item:last-child {
	margin-bottom:0;
	margin:0;
}
.c-time-and-place__symbol {
	font-size:18px;
	margin-right:10px;
	vertical-align:-4px;
}
.c-time-and-place__symbol--place {
	font-size:20px;
	margin-left:1px;
	margin-right:11px;
}
