/* =============================================================================
 * Global navigation
 *
 * Hide for mobile + tablet
============================================================================= */
.c-global-navigation {
	display:flex;
	justify-content: space-between;
	background-color: #EEEEEE;
	border-bottom: 1px solid #E3E3E3;
	height: 50px;
}

.c-global-navigation__list {
	flex-grow: 1;

	@media (--medium) {
		display: none;
	}
}

.c-global-navigation__mobile {
	display: none;

	@media (--medium) {
		display: block;
	}
}

.c-global-navigation__mobile > .c-global-navigation__link {
	@media(--small) {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
}

.c-global-navigation__mobile > .c-global-navigation__link .c-global-navigation__link-text {
	@media(--small) {
		max-width: 150px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
    	display: block;
	}
}

.c-global-navigation__mobile > .c-global-navigation__link .c-global-navigation__link-text + .c-global-navigation__icon{
	@media(--small) {
		margin: auto;
	}
}



.c-global-navigation__item {
	position: relative;
}

.c-global-navigation__link,
.c-global-navigation__link:visited {
	@mixin text-style-pi;
	display:block;
	position: relative;
	line-height: 48px;
	padding-left: 40px;
	padding-right: 44px;
}

@media (--medium) {
	.c-global-navigation__link,
	.c-global-navigation__link:visited {
		padding-left: 30px;
	}
}

@media (--small) {
	.c-global-navigation__link,
	.c-global-navigation__link:visited {
		font-size: 11px;
		line-height: 50px;
		padding-left: 15px;
		padding-right: 10px;
	}
}

.c-global-navigation__link::after {
	content: "";
	display: inline-block;
	width: 12px;
	height: 48px;
	background: url("../../../images/icons/arrow-right.svg") 0 50% no-repeat;
	position: absolute;
  top: 0;
  right: 0;

  @media (--medium) {
  	display: none;
  }
}

.c-global-navigation__icon {
	position: relative;
	left: 5px;
	top: 1px;
	font-weight: 700;
}

.c-global-navigation__sub-navigation {
	display:none;
	position: absolute;
	top: 48px;
	left: 12px;
	border: 1px solid #E3E3E3;
	padding: 11px 0;
	background-color: #fff;
	box-shadow: 0 5px 15px 0 rgba(0,0,0,0.10);
	z-index: 150;
}

.c-global-navigation__item:hover .c-global-navigation__sub-navigation,
.c-global-navigation__item:focus .c-global-navigation__sub-navigation {
	@media (min-width: 1000px) {
		display: block;
	}
}

.c-global-navigation__sub-navigation--is-expanded {
	display: block;
}

.c-global-navigation__sub-navigation--right {
	left: auto;
	right: 12px;
}

.c-global-navigation__sub-navigation-item {
	margin-left: -1px;
}

.c-global-navigation__sub-navigation-link,
.c-global-navigation__sub-navigation-link:visited {
	@mixin text-style-pi;
	font-weight: 600;
	display: block;
	padding: 19px 15px;
	border-left: 5px solid transparent;
	white-space: nowrap;
	min-width: 130px;

	@media (--small) {
		font-size: 11px;
		padding: 15px 12px;
	}
}

.c-global-navigation__sub-navigation-link:hover,
.c-global-navigation__sub-navigation-link:focus {
	border-color: #E55E13;
	background-color: #F4F4F4;
}

.c-global-navigation__avatar {
	display:flex;
	align-items: center;
	height: 50px;
	padding: 0 40px;

	@media (--small) {
		padding-right: 25px;
		padding-left: 0;
	}
}

.c-global-navigation__avatar-name {
	@mixin text-style-pi;
	display: block;
	font-size: 11px;
	margin-right: 10px;
	margin-bottom: 2px;

	@media (--medium) {
		display: none;
	}
}

.c-global-navigation__avatar-image {
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.c-global-navigation__avatar-icon {
	left: 10px;
	font-size: 12px;
}

.c-global-navigation-item__log-in {
	@mixin text-style-pi;
	color: var(--color-gamma);

	@media (--small) {
		font-size: 11px;
	}
}

.c-global-navigation-item__log-in-icon {
	margin-right: 5px;
	font-weight: 700;
}

.c-global-navigation__item .rsbtn {
	margin-bottom: 0;
}

@media(--medium) {
	.c-global-navigation__item .rsbtn {
		margin-right: 16px;
	}
}

@media(--small) {

	.c-global-navigation__item .rsbtn .rsbtn_left[class] {
		margin-right: 10px;
	}

	.c-global-navigation__item .rsbtn .rsplay {
		display: none;
	}
}

@media print {
	.c-global-navigation {
		display:none;
	}
}
