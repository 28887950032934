.c-filter-exercises {
	background: #fff;
	border: 1px solid #dbdbdb;
	overflow: hidden;
	margin-bottom:25px;
}

.c-filter-exercises__heading {
	@mixin text-style-eta;
}

.c-filter-exercises__label b {
	font-weight:400;
}

.c-filter-exercises__number {
	display: inline-block;
	border: 1px solid var(--color-beta);
	border-radius: 50%;
	text-align: center;
	width: 22px;
	height: 22px;
	line-height: 18px;
	margin-right: 10px;
	font-weight:400;
}

.c-filter-exercises__legend {
	text-transform: none;
	@mixin text-style-lambda;
}


/* List of checkboxes
 *
 * 1. Add some spacing for better vertical separation if the items wrap.
 * 2. Compensate for the extra margin added in [1].
----------------------------------------------------------------------------- */
.c-filter-exercises__list {
	display:flex;
	flex-wrap:wrap;
	max-width:75%;
	margin-bottom:-5px; /* 2 */
}

.c-filter-exercises__item {
	flex-shrink:0;
	float: left;
	margin-right:20px;
	margin-bottom:5px; /* 1 */
}

.c-filter-exercises__item:last-child {
	margin-right: 0;
}

@media(--medium) {
	.c-filter-exercises__list {
		max-width:none;
	}

	.c-filter-exercises__item {
		flex-grow:0;
	}
}

@media(--small) {
	.c-filter-exercises__item {
		width:50%;
	}
}

.c-filter-exercises__toggler-text {
	@mixin text-style-beta;
	font-size: 13px;
}

/* Submit
---------------------------------------------------------------------------- */
.c-filter-exercises__button {
	display: block;
	margin: 30px auto 0;
	min-width:280px;
}
@media(--small) {
	.c-filter-exercises__button {
		min-width:0;
		width:85%;
	}
}

/* Main
---------------------------------------------------------------------------- */
.c-filter-exercises__main {
	display: none;
	padding: 34px 26px 21px;
	border-bottom: 1px solid #DEDEDE;
}

.c-filter-exercises__main--expanded {
	display: block;
}

.c-filter-exercises__main-inner {
	background: #F2F2F2;
	padding: 20px 25px;
}

/* Bar
---------------------------------------------------------------------------- */
.c-filter-exercises__bar {
	line-height: 1.4;
}
.c-filter-exercises__bar-left {
	float: left;
	width: 75%;
	padding: 9px 20px 8px;
	border-right: 1px solid #DEDEDE;
}

.c-filter-exercises__bar-right {
	float: right;
	width: 25%;
}

@media (--medium) {
	.c-filter-exercises__bar-left {
		width: 60%;
	}

	.c-filter-exercises__bar-right {
		width: 40%;
	}
}

@media (--small) {
	.c-filter-exercises__bar-left {
		width: 50%;
	}

	.c-filter-exercises__bar-right {
		width: 50%;
	}
}

/* Toggler
---------------------------------------------------------------------------- */
.c-filter-exercises__toggler {
	display: inline-block;
	font-weight: 400;
	outline: none;
}

.c-filter-exercises__toggler-icon {
	margin-right: 5px;
	font-size:13px;
	vertical-align:-1px;
}

.c-filter-exercises__toggler:hover .c-filter-exercises__toggler-icon,
.c-filter-exercises__toggler:focus .c-filter-exercises__toggler-icon {
	color:var(--color-gamma);
}

/* Selected filters
---------------------------------------------------------------------------- */
.c-filter-exercises__selected-filters {
	float: right;
}

@media (--medium) {
	.c-filter-exercises__selected-filters {
		display: none;
	}
}

.c-filter-exercises__selected-filters-list {
	display: inline-block;
}

.c-filter-exercises__selected-filters-item {
	padding-right: 5px;
	margin-right: 5px;
	color: #787878;
}

.c-filter-exercises__selected-filters-item:last-child {
	padding-right: 0;
	margin-right: 0;
}

.c-filter-exercises__selected-filters-item::after {
	font-family: icomoon;
	font-size: 7px;
	display: inline-block;
	margin-left: 10px;
	vertical-align: middle;
	content: '\e915';
	font-size: 8px;
}

.c-filter-exercises__selected-filters-item:last-child::after {
	content: none;
}

.c-filter-exercises__clear-filters {
	font-size: 19px;
	line-height: 1;
	vertical-align: middle;
	margin-left: 17px;
	color:#d7d7d7;
	transition:color 0.2s ease;
}
.c-filter-exercises__clear-filters:hover,
.c-filter-exercises__clear-filters:focus {
	color:#000;
}

/* Select
---------------------------------------------------------------------------- */
.c-filter-exercises__bar-right .c-custom-select__fake-select-inner {
	border: 0;
}

/* Print
---------------------------------------------------------------------------- */
@media print {
	.c-filter-exercises {
		display:none;
	}
}
