/* =============================================================================
 * Print workout
============================================================================= */
.c-print {
	margin-top:40px;
}
.c-print__inner {
	display:none;
}
.c-print__icon {
	font-size:140px;
	width:140px;
	margin:160px auto 40px;
	color:#ddd;
	text-shadow:0 1px 1px #fff, 0 -1px 1px #888;
}
.c-print__intro {
	border-bottom:1px solid #000;
	margin-bottom:30px;
	padding-bottom:40px;
}
.c-print__main-heading {
	@mixin text-style-delta;
	margin-bottom:20px;
}
.c-print__short-desc {
	@mixin text-style-theta;
}
.c-print__exercises {
	margin-top:40px;
}
.c-print__exercises > div + div {
	margin-top:40px;
	page-break-before:always;
}
.c-print__video-icon {
	font-size:6px;
	vertical-align:2px;
}
.c-print__section {
	border-top:1px dotted #000;
	border-bottom:1px dotted #000;
	padding-top:20px;
	margin-top:20px;
	margin-bottom:40px;
}
.c-print__section + .c-print__section {
	margin-top:-41px;
}
.c-print__instructions {
	@mixin text-style-theta;
	margin:0 auto 100px;
	padding:0 20px;
	text-align:center;
	max-width:800px;
}
.c-print__instruction-button {
	position:relative;
	display:inline-block;
	bottom:2px;
	padding:3px 8px;
	background:#fff;
	border-bottom:2px solid #d3d3d3;
	border-radius:3px;
	font-family:Helvetica, Arial, sans-serif;;
	font-weight:normal;
	font-size:0.8em;
}
.c-print__workout-intro {
	page-break-after:always;
}
.c-print__toc {
	margin-top:20px;
}
.c-print__toc-item {
	margin-bottom:10px;
}
.c-print__levels {
	font-size:0.7em;
	color:#666;
	margin-left:8px;
}
@media print {
	.c-print__inner {
		display:block;
	}
	.c-print__icon {
		display:none;
	}
	.c-print__instructions {
		display:none;
	}
}
