/* =============================================================================
 * Progress bar

 1. Height of value devided by two
============================================================================= */
.c-progress-bar--js {
	display:none;
	position:relative;
	width:100%;
	height:18px;
	justify-content:flex-end;
	align-items:center;
	background-color:var(--new-color-grey);
	border-radius:18px;
	font-size:0;
	overflow: hidden;
}
.c-progress-bar--no-js {
	display:none;
	width:100%;
	position:relative;
	margin-top:40px;
}
.js .c-progress-bar--js {
	display:flex;
}
.no-js .c-progress-bar--no-js {
	display:block;
}
.c-progress-bar--no-js:before {
	content: attr(data-label);
	width:100%;
	text-align:center;
	top:-20px;
	position:absolute;
}
.c-progress-bar__target {
	display:none;
}
.c-progress-bar__value {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	height: 18px;
	width: 100%;
	border-radius:18px;
	background-color:var(--new-color-forrest-green);
	transform: translateX(-100%);
	transition:left 1s cubic-bezier(0.075, 0.820, 0.165, 1.000);
}

.c-progress-bar__value-label {
	position:relative;
	top:-30px;
	font-weight:bold;
	font-size:0;
	transition:font-size 1s ease-out;
	opacity:0;
}
.c-progress-bar__track {
	background-color:transparent;
}
.c-progress-bar__label {
	@mixin text-style-beta;
	margin-top:10px;
	text-align:center;
	color:var(--new-color-forrest-green);
}

.c-progress-bar__progress {
	@mixin text-style-theta;
}