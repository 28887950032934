/* =============================================================================
 * Toast message
============================================================================= */
.c-toast {
	color: #fff;
	position:fixed;
	top:calc(100% + 50px);
	left:0;
	right:0;
	text-align:center;
	z-index:1000;
}
.c-toast a,
.c-toast a:visited {
	color:#fff;
	border-bottom:1px solid #fff;
}
.c-toast__inner {
	@mixin text-style-theta;
	background:var(--color-gamma);
	color:#fff;
	display:inline-block;
	max-width:550px;
	min-width:300px;
	padding:20px 40px;
	border-radius:10px;
	box-shadow:0 0 4px rgba(0, 0, 0, 0.5);
	transition:transform 0.75s ease-in-out;
}
.c-toast--appear .c-toast__inner {
	transform:translateY(-200px);
}

/* Backend triggered toast
---------------------------------------------------------------------------- */
.js .su-toast {
	display:none;
}
