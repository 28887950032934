/* =============================================================================
 * Logo
 *
 * 1. For text fallback if image doesn't load
============================================================================= */
.c-logo {
	margin-left: -102px;
	transition: margin-left 0.3s ease;
	display: flex;
	align-items: center;
}

@media(--large) {
	.c-logo {
		margin-left: 0;
	}
}

@media(--small) {
	.c-logo {
		flex-direction: column;
	}
}

.c-logo__link,
.c-logo__link:visited {
	display:inline-block;
	margin-bottom: 0;
	max-height:90px;
}

@media(--small) {
	.c-header .c-logo__link,
	.c-header .c-logo__link:visited {
		display:block;
		margin: 0 auto;
		padding-right: 0;
	}
}

.c-logo__headline {
	@mixin text-style-nu;
	padding-left: 44px;
	display: inline-block;
	vertical-align: middle;
	line-height: 1.2;
	font-size: 26px;
	color: var(--color-beta);
	white-space: break-spaces;
}

@media(--medium) {
	.c-logo__headline {
		padding-left: 16px;
	}
}

@media(--small) {
	.c-logo__headline {
		margin-top: 13px;
		font-size: 14px;
		padding-left: 0;
	}
}

.c-logo__headline--secondary {
	display: block;
	color: var(--color-link);
}

.c-logo_special {
	max-width: 230px;
}