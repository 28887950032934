/* =============================================================================
 * Text fade
============================================================================= */
.c-text-fade {
	margin-bottom:30px;
}
.c-text-fade--limited {
	position:relative;
	height:100px;
	overflow:hidden;
}
.c-text-fade--limited::before {
	position:absolute;
	bottom:0;
	left:0;
	right:0;
	height:100px;
	background:linear-gradient(rgba(255, 255, 255, 0) 0, rgba(255, 255, 255) 75px, rgba(255, 255, 255) 100%);
	content:"";
}
.c-text-fade__link {
	position:absolute;
	bottom:5px;
	left:0;
	width:100%;
	text-align:center;
	text-decoration:underline;
	font-weight:400;
	color:var(--color-gamma);
}
.c-text-fade__link:hover,
.c-text-fade__link:focus {
	color:var(--color-iota);
}

@media print {
	.c-text-fade--limited {
		height:auto;
	}
	.c-text-fade__link {
		display:none;
	}
}
