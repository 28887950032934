/* =============================================================================
 * Width limiter
 *
 * Limits width and centers container on page.
============================================================================= */
@define-mixin width-limit-default {
	max-width: var(--width-limit-alpha);
	margin-left:auto;
	margin-right:auto;
	padding-left: 15px;  /* same amount as o-grid negative margin */
	padding-right: 15px; /* same amount as o-grid negative margin */
}

.o-width-limiter {
	@mixin width-limit-default;
}

.o-width-limiter--beta {
	@mixin width-limit-default;
	max-width: var(--width-limit-beta);
}

.o-width-limiter--gamma {
	@mixin width-limit-default;
	max-width: var(--width-limit-gamma);
}

.o-width-limiter--delta {
	@mixin width-limit-default;
	max-width: var(--width-limit-delta);
}

.o-width-limiter--epsilon {
	@mixin width-limit-default;
	max-width: var(--width-limit-epsilon);
}

.o-width-limiter--zeta {
	@mixin width-limit-default;
	max-width: var(--width-limit-zeta);
}

.o-width-limiter--eta {
	@mixin width-limit-default;
	max-width: var(--width-limit-eta);
}

@media print {
	[class*="o-width-limiter"] {
		max-width:none;
		padding-left:0;
		padding-right:0;
	}
}
