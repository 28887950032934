/* =============================================================================
 * Discussion
 *
 * Combines comments into a discussion thread.
============================================================================= */
.c-discussion + .c-discussion {
	margin-top:-40px;
}

/* Message
----------------------------------------------------------------------------- */
.c-discussion__message {
	margin-top:10px;
	padding:50px 70px;
	border:1px solid #dbdbdb;
	background:#fff;
}
.c-discussion--slim .c-discussion__message {
	padding:40px;
}

@media(--medium) {
	.c-discussion__message,
	.c-discussion--slim .c-discussion__message {
		padding:30px 40px 40px;
	}
}

@media(--small) {
	.c-discussion__message,
	.c-discussion--slim .c-discussion__message {
		padding:15px 20px 40px;
	}
}


/* Header
----------------------------------------------------------------------------- */
.c-discussion__message-header {
	display:flex;
	margin-bottom:45px;
	font-size:24px;
	line-height:1.2;
}
.c-discussion__comment-counter {
	margin-left:auto;
	flex-shrink:0;
}
.c-discussion__comment-counter [class^="icon-"] {
	margin-right:8px;
	color:#dfdfdf;
	vertical-align:-4px;
}

@media(--small) {
	.c-discussion__message-header {
		font-size:18px;
		margin-bottom:25px;
	}
}

/* Comment list and containers
 *
 * [1] Offset the element half of its width
----------------------------------------------------------------------------- */
.c-discussion__comments {
	position:relative;
	margin-bottom:75px;
}
.c-discussion__comment-list {
	position:relative;
}
.c-discussion__comment-list::before,
.c-discussion__comment-list::after {
	position:absolute;
	top:-11px;
	left:calc(50% - 10px); /* [1] */
	z-index:1;
	width:20px;
	height:20px;
	border:1px solid #dedede;
	background:#fcfcfc;
	content:"";
	transform:rotate(45deg);
}
.c-discussion__comment-list::after {
	top:-10px;
	left:calc(50% - 9px);
	z-index:3;
	border:none;
}

@media only screen and (max-width:800px) {
	.c-discussion__comment-list::before,
	.c-discussion__comment-list::after {
		display:none;
	}
}


/* Comment list with hidden comments
----------------------------------------------------------------------------- */
.js .c-discussion__comment-list--hide-comments:not(.is-togglable) .c-discussion__comment:not(:last-child) {
	display:none;
}

/*
 * Container for the button that toggles the visibility of comments
 */
.c-discussion__toggle-comments {
	position:absolute;
	top:-13px;
	right:0;
	left:0;
	z-index:10;
}
.c-discussion__toggle-comments--bottom {
	top:auto;
	bottom:-11px;
}

/*
 * The button
 */
.c-discussion__toggle-comments-button {
	padding:0 8px;
	margin-left:200px;
	border:1px solid #dedede;
	box-shadow:1px 2px 4px rgba(0, 0, 0, 0.1);
	background:#fff;
	transition:all 0.2s ease;
}
.c-discussion__toggle-comments-button:hover,
.c-discussion__toggle-comments-button:focus {
	box-shadow:1px 2px 4px rgba(0, 0, 0, 0.2);
}
.c-discussion--slim .c-discussion__toggle-comments-button {
	margin-left:170px;
}
.c-discussion__toggle-comments--bottom .c-discussion__toggle-comments-button {
	color:#000;
}

@media(--medium) {
	.c-discussion__toggle-comments-button {
		margin-left:170px;
	}
}

@media(--small) {
	.c-discussion__toggle-comments {
		top:-13px;
	}
	.c-discussion__toggle-comments-button,
	.c-discussion--slim .c-discussion__toggle-comments-button {
		margin-left:50%;
		transform:translateX(-50%);
		white-space:nowrap;
	}
	.c-discussion__toggle-comments--bottom {
		visibility:hidden;
	}
}

/* No comments
 * Generated back-end if no comments exist on page load. Inserted with JS if
 * all comments are removed from a discussion after page load.
----------------------------------------------------------------------------- */
.c-discussion__no-comments {
	padding:20px 30px 0 200px;
}


/* Comment
----------------------------------------------------------------------------- */
.c-discussion__comment {
	position:relative;
	z-index:2;
	padding:30px 30px 30px 200px;
	border:1px solid #dedede;
	border-top:none;
	background:#fcfcfc;
}
.c-discussion--slim .c-discussion__comment {
	padding-left:170px;
}
.c-discussion__comment:focus {
	outline:none;
}
.c-discussion__comment--reply {
	padding-bottom:0;
}

@media(--medium) {
	.c-discussion__comment {
		padding:30px 40px 30px 160px;
	}
}

@media(--small) {
	.c-discussion__comment,
	.c-discussion--slim .c-discussion__comment {
		padding:20px;
	}
}
